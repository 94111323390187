import { DocumentNode } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { apolloClient } from "../../apollo.client";
import InputSearch, {
	InputSearchProps,
	InputSearchResult,
} from "../../g3-components/inputSearch/InputSearch";

export interface ApolloInputSearchProps<TSearchResultValue, TQueryVariables>
	extends Omit<InputSearchProps<TSearchResultValue>, "searchResults"> {
	query: DocumentNode;
	searchParams: {
		shouldSearch: boolean; // false if you don't want it to search, eg. if you want your search string to have min length
		queryVariables: TQueryVariables;
	};
	onGetDisplayLabels: (
		value: TSearchResultValue,
	) => Pick<InputSearchResult<TSearchResultValue>, "resultLabel" | "selectedLabel">;
	shouldSearchOnMount?: boolean; // start first search onMount otherwise start/enable search onFocus
	onSearchError?: (err: any) => void;
	onGetAdditionalSearchResults?: (
		searchResults: InputSearchResult<TSearchResultValue | undefined>[],
	) => InputSearchResult<TSearchResultValue | undefined>[];
	noResultLabel?: JSX.Element | string;
}

// @TODO convert this to GsaInputSearch using axios. Don't forget headers and scalar resolvers
/**
 * Query return type must be { rows: TSearchResultValue[] }
 */
const ApolloInputSearch = <TSearchResultValue, TQueryVariables>({
	query,
	searchParams,
	onGetDisplayLabels,
	shouldSearchOnMount,
	onSearchError,
	onGetAdditionalSearchResults,
	noResultLabel,
	onFocus,
	onBlur,
	...inputProps
}: ApolloInputSearchProps<TSearchResultValue, TQueryVariables>) => {
	// state hooks
	const [searchResults, setSearchResults] = useState<
		InputSearchResult<TSearchResultValue | undefined>[]
	>([]);

	const hasFocusedOnce = useRef(!!shouldSearchOnMount);

	// recoils

	// custom hooks

	// local variables

	// functions
	const search = useCallback(async () => {
		try {
			if (!hasFocusedOnce.current) {
				return;
			}

			if (!searchParams.shouldSearch) {
				setSearchResults([]);
				return;
			}

			setSearchResults([
				{
					value: undefined,
					resultLabel: "Searching...",
					selectedLabel: "",
					isNotSelectable: true,
				},
			]);

			const res = await apolloClient.query({
				query,
				variables: searchParams.queryVariables as any, // @TODO
				fetchPolicy: "no-cache",
			});

			let searchResultsTemp = res.data[Object.keys(res.data)[0]].rows.map(
				(row: TSearchResultValue) => {
					return {
						value: row,
						...onGetDisplayLabels(row),
					};
				},
			) as InputSearchResult<TSearchResultValue | undefined>[];

			if (searchResultsTemp.length === 0) {
				searchResultsTemp.push({
					value: undefined,
					selectedLabel: "",
					resultLabel:
						noResultLabel !== undefined ? (
							noResultLabel
						) : (
							<div className="no-results">No Result Found</div>
						),
					isNotSelectable: true,
				});
			}

			if (onGetAdditionalSearchResults) {
				const additionalSearchResults = onGetAdditionalSearchResults(searchResultsTemp);
				searchResultsTemp = [...searchResultsTemp, ...additionalSearchResults];
			}

			setSearchResults(searchResultsTemp);
		} catch (err) {
			onSearchError?.(err);

			setSearchResults([]);
		}
	}, [
		searchParams.shouldSearch,
		searchParams.queryVariables,
		query,
		onGetAdditionalSearchResults,
		onGetDisplayLabels,
		noResultLabel,
		onSearchError,
	]);

	// React hooks
	useEffect(() => {
		void search();

		// note that this will only replace search function on the next searchParams change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	// jsx variables

	return (
		<InputSearch
			{...inputProps}
			searchResults={searchResults as InputSearchResult<TSearchResultValue>[]}
			onFocus={() => {
				hasFocusedOnce.current = true;
				void search();
			}}
			onBlur={(e) => {
				onBlur?.(e);

				// if (shouldClearValueOnBlur) {
				// 	setSearchResults([]);
				// }
			}}
		/>
	);
};

export default ApolloInputSearch;
