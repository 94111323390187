import React, { useState } from "react";
import useIsWindowMobile from "../../../hooks/reusable/useIsWindowMobile";
import {
	SettingsTabEnum,
	useGetSettingsTabSelected,
	useSetSettingsTabSelected,
} from "../settings.service";
import "./settingsTabs.scss";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import classNames from "classnames";

export const SettingsTabs: React.FC = () => {
	// incoming values

	// state hooks
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	// recoils
	const isWindowMobile = useIsWindowMobile();

	// custom hooks
	const { settingsTabSelected } = useGetSettingsTabSelected();
	const { setSettingsTabSelected } = useSetSettingsTabSelected();

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className="settingsTabs">
			<div className="left">Settings</div>
			<div
				className="right"
				tabIndex={0}
				onBlur={() => {
					setIsMenuOpen(false);
				}}
			>
				{isWindowMobile && (
					<div
						className="tab-selected"
						onClick={() => {
							setIsMenuOpen(!isMenuOpen);
						}}
					>
						{settingsTabSelected}
						{isMenuOpen ? <BsChevronUp /> : <BsChevronDown />}
					</div>
				)}
				<div className={classNames("tabs", isWindowMobile && !isMenuOpen ? "hidden" : "")}>
					{Object.values(SettingsTabEnum).map((tab, i) => {
						const tabStr = String(tab);
						return (
							<div
								key={tabStr + i}
								className={classNames("tab", tabStr === settingsTabSelected ? "active" : "")}
								onClick={() => {
									setSettingsTabSelected(tab as SettingsTabEnum);
									setIsMenuOpen(false);
								}}
							>
								{tabStr}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
