import React from "react";
import "./customerIntakeLogs.scss";
import { CustomerIntakeLog } from "./customerIntakeLog/CustomerIntakeLog";
import { CustomerIntakeLogList } from "./customerIntakeLogList/CustomerIntakeLogList";

export const CustomerIntakeLogs: React.FC = () => {
	return (
		<div className="customerIntakeLogs">
			<CustomerIntakeLog />
			<CustomerIntakeLogList />
		</div>
	);
};
