import React from "react";
import "./customerIntakeLogSaveButton.scss";
import { useSaveCustomerIntakeLog } from "../customerIntakeLogForm.service";
import Button from "../../../../../../../../g3-components/button/Button";
import { toast } from "react-toastify";
import { useReloadCustomerIntakeLogs } from "../../../customerIntakeLogList/customerIntakeLogList.service";

export const CustomerIntakeLogSaveButton: React.FC = () => {
	const { reloadCustomerIntakeLogs } = useReloadCustomerIntakeLogs();

	const { saveCustomerIntakeLog } = useSaveCustomerIntakeLog();

	return (
		<div className="customerIntakeLogSaveButton">
			<Button
				type="button"
				className="default-btn primary-button"
				onClick={() => {
					void saveCustomerIntakeLog()
						.then(() => {
							reloadCustomerIntakeLogs();
							toast.success("Added Successfully!");
						})
						.catch(() => {
							toast.error("Add Error!");
						});
				}}
			>
				Add
			</Button>
		</div>
	);
};
