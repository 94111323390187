import React from "react";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import "./App.css";
import { Main } from "./views/Main";
import "@szhsin/react-menu/dist/index.css";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo.client";

export const App: React.FC = () => {
	return (
		<div className="app">
			<RecoilRoot>
				<ApolloProvider client={apolloClient}>
					<Main />
					<ToastContainer
						position="top-center"
						autoClose={5000}
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover
						progressStyle={{ backgroundColor: "orange" }}
					/>
				</ApolloProvider>
			</RecoilRoot>
		</div>
	);
};
