import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import {
	CustomerIntakeListItemFragment,
	FilterOperators,
	GetCustomerIntakesFilter,
	GetCustomerIntakesSortBy,
	GetCustomerIntakesSortByNames,
	useGetCustomerIntakesCsvBase64LazyQuery,
	useGetCustomerIntakesLazyQuery,
} from "../../../generated/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { useGetCurrentUser, useIsAdmin } from "../../../state/user/currentUser.recoil";
import { stringify } from "csv-stringify/browser/esm/sync";

const _customerIntakesAtom = atom<CustomerIntakeListItemFragment[]>({
	key: "customerIntakes",
	default: [],
});

export interface CustomerIntakesRawFilters {
	customerNumber?: string;
	businessName?: string;
	repCodeUserId?: number;
	verifiedByUserId?: number;
	minTime?: string;
	maxTime?: string;
	minDate?: string;
	maxDate?: string;
	stationId?: number;
}

export const _customerIntakesRawFiltersAtom = atom<CustomerIntakesRawFilters>({
	key: "customerIntakesRawFilters",
	default: {},
});

const _useGetCustomerIntakesFilter = () => {
	const customerIntakesRawFilters = useRecoilValue(_customerIntakesRawFiltersAtom);
	const { currentUser } = useGetCurrentUser();
	const { isAdmin } = useIsAdmin();

	const customerIntakesFilter = useMemo(() => {
		const res: GetCustomerIntakesFilter = {
			and: [],
		};

		if (customerIntakesRawFilters.customerNumber !== undefined) {
			res.and?.push({
				fields: {
					customerNumber: {
						val: `%${customerIntakesRawFilters.customerNumber}%`,
						op: FilterOperators.ILike,
					},
				},
			});
		}

		if (customerIntakesRawFilters.businessName !== undefined) {
			res.and?.push({
				fields: {
					businessName: {
						val: `%${customerIntakesRawFilters.businessName}%`,
						op: FilterOperators.ILike,
					},
				},
			});
		}

		if (isAdmin) {
			if (customerIntakesRawFilters.repCodeUserId !== undefined) {
				res.and?.push({
					fields: {
						repCodeUserId: {
							val: customerIntakesRawFilters.repCodeUserId,
							op: FilterOperators.Equal,
						},
					},
				});
			}
		} else {
			// always filter by current user if not admin
			res.and?.push({
				fields: {
					repCodeUserId: {
						val: currentUser?.id,
						op: FilterOperators.Equal,
					},
				},
			});
		}

		if (customerIntakesRawFilters.verifiedByUserId !== undefined) {
			res.and?.push({
				fields: {
					verifiedByUserId: {
						val: customerIntakesRawFilters.verifiedByUserId,
						op: FilterOperators.Equal,
					},
				},
			});
		}

		if (customerIntakesRawFilters.minTime !== undefined) {
			res.and?.push({
				fields: {
					time: {
						val: customerIntakesRawFilters.minTime,
						op: FilterOperators.GreaterThanOrEqual,
					},
				},
			});
		}

		if (customerIntakesRawFilters.maxTime !== undefined) {
			res.and?.push({
				fields: {
					time: {
						val: customerIntakesRawFilters.maxTime,
						op: FilterOperators.LessThanOrEqual,
					},
				},
			});
		}

		if (customerIntakesRawFilters.minDate !== undefined) {
			res.and?.push({
				fields: {
					date: {
						val: customerIntakesRawFilters.minDate,
						op: FilterOperators.GreaterThanOrEqual,
					},
				},
			});
		}

		if (customerIntakesRawFilters.maxDate !== undefined) {
			res.and?.push({
				fields: {
					date: {
						val: customerIntakesRawFilters.maxDate,
						op: FilterOperators.LessThanOrEqual,
					},
				},
			});
		}

		if (customerIntakesRawFilters.stationId !== undefined) {
			res.and?.push({
				fields: {
					stationId: {
						val: customerIntakesRawFilters.stationId,
						op: FilterOperators.Equal,
					},
				},
			});
		}

		return res;
	}, [currentUser, customerIntakesRawFilters, isAdmin]);

	return {
		customerIntakesFilter,
	};
};

export const DEFAULT_SORT_BY_CUSTOMER_INTAKE: GetCustomerIntakesSortBy = {
	name: GetCustomerIntakesSortByNames.BusinessName,
};

type State = {
	sortBys: GetCustomerIntakesSortBy[];
};

export const useGetCustomerIntakeListCall = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [{ sortBys }, setSortByState] = useState<State>({
		sortBys: [DEFAULT_SORT_BY_CUSTOMER_INTAKE],
	});
	const PAGE_ITEM_COUNT = 50;
	const { customerIntakesFilter } = _useGetCustomerIntakesFilter();
	const setCustomerIntakes = useSetRecoilState(_customerIntakesAtom);
	const [getCustomerIntakes, getCustomerIntakesRes] = useGetCustomerIntakesLazyQuery();

	useEffect(() => {
		void getCustomerIntakes({
			variables: {
				input: {
					filter: customerIntakesFilter,
					sortBys: sortBys,
					pagination: {
						offset: (currentPage - 1) * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
				},
			},
		});
	}, [currentPage, customerIntakesFilter, getCustomerIntakes, sortBys]);

	useEffect(() => {
		if (getCustomerIntakesRes.called && !getCustomerIntakesRes.loading) {
			if (getCustomerIntakesRes.error || !getCustomerIntakesRes.data) {
				// Handle error
				return;
			}

			setCustomerIntakes(getCustomerIntakesRes.data.getCustomerIntakes.rows);
		}
	}, [getCustomerIntakesRes, setCustomerIntakes]);

	return {
		customerIntakes: getCustomerIntakesRes.data?.getCustomerIntakes.rows || [],
		totalPages: (getCustomerIntakesRes.data?.getCustomerIntakes.count ?? 0) / PAGE_ITEM_COUNT,
		currentPage,
		setCurrentPage,
		setSortByState,
	};
};

export const useCustomerIntakeListService = () => {
	const navigate = useNavigate();

	const [getCustomerIntakes, getCustomerIntakesRes] = useGetCustomerIntakesLazyQuery();
	const { customerIntakesFilter } = _useGetCustomerIntakesFilter();
	const setCustomerIntakes = useSetRecoilState(_customerIntakesAtom);

	useEffect(() => {
		void getCustomerIntakes({
			variables: {
				input: {
					filter: customerIntakesFilter,
				},
			},
		});
	}, [customerIntakesFilter, getCustomerIntakes]);

	useEffect(() => {
		if (getCustomerIntakesRes.called && !getCustomerIntakesRes.loading) {
			if (getCustomerIntakesRes.error || !getCustomerIntakesRes.data) {
				navigate(PathName.UNKNOWN_ERROR);
				return;
			}

			setCustomerIntakes(getCustomerIntakesRes.data.getCustomerIntakes.rows);
		}

		return () => {
			setCustomerIntakes([]);
		};
	}, [
		getCustomerIntakesRes.called,
		getCustomerIntakesRes.data,
		getCustomerIntakesRes.error,
		getCustomerIntakesRes.loading,
		navigate,
		setCustomerIntakes,
	]);
};

export const useGetCustomerIntakeList = () => {
	const customerIntakes = useRecoilValue(_customerIntakesAtom);

	return {
		customerIntakes,
	};
};

export const useGetCustomerIntakesRawFilters = () => {
	const customerIntakesRawFilters = useRecoilValue(_customerIntakesRawFiltersAtom);

	return {
		customerIntakesRawFilters,
	};
};

export const useGetCustomerIntakesCsv = () => {
	const customerIntakesRawFilters = useRecoilValue(_customerIntakesRawFiltersAtom);

	const [getCustomerIntakesCsvBase64, getCustomerIntakesCsvBase64Res] =
		useGetCustomerIntakesCsvBase64LazyQuery();

	const getCustomerIntakesCsv = useCallback(async () => {
		const res = await getCustomerIntakesCsvBase64({
			variables: {
				input: {
					businessName: customerIntakesRawFilters.businessName,
					customerNumber: customerIntakesRawFilters.customerNumber,
					maxDate: customerIntakesRawFilters.maxDate,
					maxTime: customerIntakesRawFilters.maxTime,
					minDate: customerIntakesRawFilters.minDate,
					minTime: customerIntakesRawFilters.minTime,
					repCodeUserId: customerIntakesRawFilters.repCodeUserId,
					stationId: customerIntakesRawFilters.stationId,
					verifiedByUserId: customerIntakesRawFilters.verifiedByUserId,
				},
			},
		});
		if (res.error || !res.data) {
			throw new Error("Failed to get customer intakes");
		}

		return atob(res.data.getCustomerIntakesCsvBase64);
	}, [
		customerIntakesRawFilters.businessName,
		customerIntakesRawFilters.customerNumber,
		customerIntakesRawFilters.maxDate,
		customerIntakesRawFilters.maxTime,
		customerIntakesRawFilters.minDate,
		customerIntakesRawFilters.minTime,
		customerIntakesRawFilters.repCodeUserId,
		customerIntakesRawFilters.stationId,
		customerIntakesRawFilters.verifiedByUserId,
		getCustomerIntakesCsvBase64,
	]);

	return {
		getCustomerIntakesCsv,
		getCustomerIntakesCsvBase64Res,
	};
};
