import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	RouterProvider,
} from "react-router-dom";
import "./Main.scss";
import "../styles/global.scss";
import { PathName } from "../constants/PathName";
import Settings from "./settings/Settings";
import Login from "./login/Login";
import Modals from "../g3-components/modal/Modals";
import PrivateComponent from "../components/route/PrivateComponent";
import { useMemo } from "react";
import { useMain } from "../data/Main.recoil";
import MainLayout from "./mainLayout/MainLayout";
import { CustomerIntakes } from "./customerIntakes/CustomerIntakes";
import { CustomerIntake } from "./customerIntakes/customerIntake/CustomerIntake";
import { CustomerIntakeList } from "./customerIntakes/customerIntakeList/CustomerIntakeList";
import { NotFound } from "./notFound/NotFound";

export const Main: React.FC = () => {
	useMain();

	const router = useMemo(
		() =>
			createBrowserRouter(
				createRoutesFromElements(
					<Route>
						<Route path={PathName.NOT_FOUND} element={<NotFound />} />
						<Route path={PathName.UNKNOWN_ERROR} element={<div>An Error Has Occurred</div>} />
						<Route path="/" element={<Navigate to={PathName.LOGIN} />} />
						<Route path={PathName.LOGIN} element={<Login />} />
						<Route
							element={
								<PrivateComponent>
									<MainLayout />
								</PrivateComponent>
							}
						>
							<Route path={PathName.CUSTOMER_INTAKES()} element={<CustomerIntakes />}>
								<Route index element={<CustomerIntakeList />} />
								<Route
									path={PathName.CUSTOMER_INTAKES(":customerIntakeId")}
									element={<CustomerIntake />}
								/>
							</Route>
							<Route path={PathName.SETTINGS} element={<Settings />} />
						</Route>
					</Route>,
				),
			),
		[],
	);

	return (
		<div className="main">
			<Modals />
			<RouterProvider router={router} />
		</div>
	);
};
