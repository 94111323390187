import env from "./env";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageKeys } from "./constants/LocalStorageKeys";
import { withScalars } from "apollo-link-scalars";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import introspectionSchema from "./generated/schema.json";
import { PathName } from "./constants/PathName";
import { getApiBaseUrl } from "./utils/GetApiBaseUrl";

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(LocalStorageKeys.TOKEN);
	return {
		headers: {
			...headers,
			Authorization: token !== null ? `Bearer ${token}` : "",
		},
	};
});

const typesMap = {
	Date: {
		serialize: (outputValue: unknown) => {
			const date = outputValue as Date;
			return date.toJSON();
		},
		parseValue: (inputValue: unknown): Date => {
			const dateStr = inputValue as string;
			return new Date(dateStr);
		},
	},
	NullableDate: {
		serialize: (outputValue: unknown) => {
			const date = outputValue as Date | null;
			if (date === null) {
				return null;
			}

			return date.toJSON();
		},
		parseValue: (inputValue: unknown): Date | null => {
			const dateStr = inputValue as string | null;
			if (dateStr === null) {
				return null;
			}

			return new Date(dateStr);
		},
	},
};

const schema = buildClientSchema(introspectionSchema as unknown as IntrospectionQuery);
const scalarTypeDefLink = withScalars({ schema, typesMap });

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (window.location.pathname !== PathName.LOGIN && graphQLErrors) {
		for (const graphQLError of graphQLErrors) {
			if (
				graphQLError.message === "Unauthorized, access expired" ||
				graphQLError.message === "Unauthorized, version inconsistency"
			) {
				window.location.replace(PathName.LOGIN);
			}
		}
	}
});

const httpLink = createHttpLink({
	uri: getApiBaseUrl() + "/api/graphql",
});

export const apolloClient = new ApolloClient({
	// link order matters
	link: ApolloLink.from([errorLink, authLink, scalarTypeDefLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
		},
		query: {
			fetchPolicy: "network-only",
		},
	},
});
