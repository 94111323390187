import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Button from "../../../../g3-components/button/Button";
import Input from "../../../../g3-components/input/Input";
import { UsersTableFilter, usersTableFilterAtom } from "../users.service";
import "./usersFilters.scss";
const UsersFilters: React.FC = () => {
	const [localUsersFilter, setLocalUsersFilter] = useState<UsersTableFilter>({});

	const [searchStr, setSearchStr] = useState("");

	// recoils
	const setUsersFilter = useSetRecoilState(usersTableFilterAtom);

	// functions
	const applyFilter = () => {
		setUsersFilter({
			...localUsersFilter,
		});
	};

	const clearFilter = useCallback(() => {
		setLocalUsersFilter({});
		setUsersFilter({});
	}, [setUsersFilter]);

	// React hooks
	useEffect(() => {
		return () => {
			clearFilter();
		};
	}, [clearFilter]);

	return (
		<div className="users-filter">
			<div className="users-filters-inputs">
				<div>
					<div className="font-medium text-gray-500">Username:</div>
					<Input
						value={localUsersFilter.username !== undefined ? localUsersFilter.username : ""}
						onChange={(e) =>
							setLocalUsersFilter((state) => {
								return {
									...state,
									username: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Name:</div>
					<Input
						value={localUsersFilter.name !== undefined ? localUsersFilter.name : ""}
						onChange={(e) =>
							setLocalUsersFilter((state) => {
								return {
									...state,
									name: e.target.value,
								};
							})
						}
					/>
				</div>

				<div>
					<div className="font-medium text-gray-500">Is Verifier:</div>
					<input
						type="checkbox"
						onChange={(e) =>
							setLocalUsersFilter((state) => {
								return {
									...state,
									isVerifier: e.target.checked,
								};
							})
						}
					/>
				</div>
				<div></div>
			</div>
			<div className="users-filters-buttons">
				<Button className="outline-btn" onClick={() => clearFilter()}>
					Clear
				</Button>
				<Button className="default-btn" onClick={() => applyFilter()}>
					Apply
				</Button>
			</div>
		</div>
	);
};

export default UsersFilters;
