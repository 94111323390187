import classNames from "classnames";
import { clampDecimal, formatCurrency, strToNumber } from "gate3-utils";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useEffect } from "react";
import Input, { InputProps } from "../../g3-components/input/Input";
import "./currencyInput.scss";

interface Props extends Omit<InputProps, "type" | "step" | "value" | "onChange"> {
	/**
	 * * null will turn to ""
	 * * null allows non-required input
	 * * undefined acts the same way as the normal input
	 */
	value?: number | null;

	/**
	 * @param value null if value is invalid number
	 */
	onChange?: (value: number | null, e: ChangeEvent<HTMLInputElement>) => void;
}

const clampNum = (valueNum: number, minNum?: number, maxNum?: number) => {
	if (minNum !== undefined) {
		valueNum = Math.max(minNum, valueNum);
	}

	if (maxNum !== undefined) {
		valueNum = Math.min(maxNum, valueNum);
	}

	return valueNum;
};

const formatCurrencyNoDecimal = (value: number) => {
	return formatCurrency(value).split(".")[0];
};

const formatCurrencyStripLeading0 = (value: number) => {
	const currencySplit = formatCurrency(value).split(".");
	const dummy = parseFloat(`0.${currencySplit[1]}`) + ""; // strips leading 0
	const dummySplit = dummy.split(".");
	if (dummySplit.length === 2) {
		return `${currencySplit[0]}.${dummySplit[1]}`;
	} else {
		return currencySplit[0];
	}
};

const CurrencyInput: React.FC<Props> = ({ value, onChange, min, max, ...inputProps }) => {
	// allows inputting just a negative sign
	const [isNegativeSign, setIsNegativeSign] = useState<boolean>(false);

	// holds decimal(.) if it's leading with . to allow inputting 1. otherwise 1. will turn to 1
	// also, holds decimal(.0) if it's leading with 0 to allow inputting 1.01 otherwise 1.0 will turn to 1
	const [tempDecimal, setTempDecimal] = useState<string>("");

	// with this, inputting 1.0 from 1.01 will result in 1
	// without this, setting the value outside will preserve tempDecimal, can end up with 1.01 even though the value is 1
	useEffect(() => {
		if (value != null) {
			// compare decimal of new value to tempDecimal
			// if the same then don't reset tempDecimal
			const tempDecimalDummy = parseFloat(`0${tempDecimal}`);
			if ((value % 1).toFixed(2) !== (tempDecimalDummy % 1).toFixed(2)) {
				setTempDecimal("");
			}
		} else {
			setTempDecimal("");
		}
	}, [value, tempDecimal]);

	useEffect(() => {
		setIsNegativeSign(false);
	}, [value]);

	const minNum = useMemo(() => {
		if (typeof min === "string") {
			return strToNumber(min);
		} else {
			return min;
		}
	}, [min]);

	const maxNum = useMemo(() => {
		if (typeof max === "string") {
			return strToNumber(max);
		} else {
			return max;
		}
	}, [max]);

	return (
		<div
			className={classNames({
				// @TODO remove when custom validation implemented. Just put $ in the input itself
				"dollar-icon-cont": value === null,
			})}
		>
			<Input
				{...inputProps}
				step={0.01}
				value={
					value !== undefined
						? !isNegativeSign
							? value !== null
								? tempDecimal !== ""
									? `${formatCurrencyNoDecimal(value)}${tempDecimal}`
									: formatCurrencyStripLeading0(value)
								: ""
							: undefined // uncontrolled input
						: "-"
				}
				onChange={(e) => {
					// turns $-1.aaa01 to -1.01
					const valueNumStr = e.target.value.replace(/[^\d.-]/g, "");

					if (valueNumStr === "-") {
						setIsNegativeSign(true);
					} else {
						setIsNegativeSign(false);

						// @TODO
						// check if there's a negative sign
						// strip all negative signs and put it in front. Because it could be in the back like 1-
					}

					const valueNum = strToNumber(valueNumStr);
					if (valueNum !== undefined) {
						const valueSplit = valueNumStr.split(".");

						if (minNum !== undefined || maxNum !== undefined) {
							const clampedNum = clampNum(valueNum, minNum, maxNum);
							const wasClamped = clampedNum !== valueNum;
							if (!wasClamped && valueSplit.length === 2) {
								const valueSplit1 = valueSplit[1];
								if (valueSplit1 === "") {
									setTempDecimal(".");
								} else if (valueSplit1[valueSplit1.length - 1] === "0") {
									setTempDecimal(`.${valueSplit1}`);
								} else {
									setTempDecimal("");
								}
							} else {
								setTempDecimal("");
							}

							onChange?.(clampDecimal(clampedNum, 2), e);
						} else {
							if (valueSplit.length === 2) {
								const valueSplit1 = valueSplit[1];
								if (valueSplit1 === "") {
									setTempDecimal(".");
								} else if (valueSplit1[valueSplit1.length - 1] === "0") {
									setTempDecimal(`.${valueSplit1}`);
								} else {
									setTempDecimal("");
								}
							} else {
								setTempDecimal("");
							}

							onChange?.(clampDecimal(valueNum, 2), e);
						}
					} else {
						onChange?.(null, e);
					}
				}}
			/>
		</div>
	);
};

export default CurrencyInput;
