import React from "react";
import Button from "../../../../g3-components/button/Button";
import "./customerIntakeListActions.scss";
import { useNavigate } from "react-router-dom";
import { PathName } from "../../../../constants/PathName";
import { CustomerIntakeListExportButton } from "./customerIntakeListExportButton/CustomerIntakeListExportButton";

export const CustomerIntakeListActions: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="customerIntakeListActions">
			<Button
				type="button"
				className="default-btn primary-button"
				onClick={() => {
					navigate(PathName.CUSTOMER_INTAKES("add"));
				}}
			>
				Create
			</Button>
			<CustomerIntakeListExportButton />
		</div>
	);
};
