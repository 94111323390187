import React from "react";
import Input from "../../../../g3-components/input/Input";
import "./customerIntakeForm.scss";
import {
	useCustomerIntakeFormService,
	useGetCustomerIntakeFormData,
	usePartialSetCustomerIntake,
} from "./customerIntakeForm.service";
import { RepCodeUserInputSearch } from "./repCodeUsersInputSearch/RepCodeUserInputSearch";
import { StationInputSearch } from "./stationInputSearch/StationInputSearch";
import { StateInputSearch } from "./stateInputSearch/StateInputSearch";
import { VerifiedByUserInputSearch } from "./verifiedByUserInputSearch/VerifiedByUserInputSearch";
import CurrencyInput from "../../../../components/currencyInput/CurrencyInput";

export const CustomerIntakeForm: React.FC = () => {
	useCustomerIntakeFormService();
	const { customerIntake } = useGetCustomerIntakeFormData();
	const { partialSetCustomerIntake } = usePartialSetCustomerIntake();

	return (
		<div className="customerIntakeForm">
			<div className="form-fields">
				<div className="top-row">
					<div className="form-item">
						<label htmlFor="invoiceNumber">Invoice Number:</label>
						<Input
							id="invoiceNumber"
							type="number"
							name="invoiceNumber"
							required={true}
							value={customerIntake.invoiceNumber ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									invoiceNumber: e.target.value,
								});
							}}
						/>
					</div>

					<div className="form-item">
						<label htmlFor="repCode">Rep Code:</label>
						<RepCodeUserInputSearch />
					</div>
				</div>

				<div className="second-section">
					<div className="form-item">
						<label htmlFor="customerNumber">Customer #:</label>
						<Input
							id="customerNumber"
							type="number"
							value={customerIntake.customerNumber ?? ""}
							required={true}
							onChange={(e) => {
								partialSetCustomerIntake({
									customerNumber: e.target.value,
								});
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="verifiedBy">Verified by:</label>
						<VerifiedByUserInputSearch />
					</div>
					<div className="form-item">
						<label htmlFor="station">Station:</label>
						<StationInputSearch />
					</div>
					<div className="form-item">
						<label htmlFor="time">Time:</label>
						<Input
							id="time"
							name="time"
							required={true}
							type="time"
							value={customerIntake.time ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									time: e.target.value,
								});
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="businessName">Business Name:</label>
						<Input
							id="businessName"
							name="businessName"
							required={true}
							value={customerIntake.businessName ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									businessName: e.target.value,
								});
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="date">Date:</label>
						<Input
							id="date"
							name="date"
							required={true}
							type="date"
							value={customerIntake.date ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									date: e.target.value,
								});
							}}
						/>
					</div>
				</div>

				<div className="bottom-section">
					<div className="form-item">
						<label htmlFor="address">Address:</label>
						<Input
							id="address"
							name="address"
							required={true}
							value={customerIntake.address ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									address: e.target.value,
								});
							}}
						/>
					</div>
					<div className="address-detail">
						<div className="form-item">
							<label htmlFor="city">City:</label>
							<Input
								id="city"
								name="city"
								required={true}
								value={customerIntake.city ?? ""}
								onChange={(e) => {
									partialSetCustomerIntake({
										city: e.target.value,
									});
								}}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="zip">Zip:</label>
							<Input
								id="zip"
								name="zip"
								type="number"
								required={true}
								value={customerIntake.zip ?? ""}
								onChange={(e) => {
									partialSetCustomerIntake({
										zip: e.target.value,
									});
								}}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="state">State:</label>
							<StateInputSearch />
						</div>
					</div>

					<div className="phone-detail">
						<div className="form-item">
							<label htmlFor="phoneOne">Phone 1:</label>
							<Input
								id="phoneOne"
								name="phoneOne"
								type="tel"
								required={true}
								value={customerIntake.phone ?? ""}
								onChange={(e) => {
									let value = e.target.value;
									value = value.replace(/\D/g, "");

									if (value.length > 3 && value.length <= 6) {
										value = value.slice(0, 3) + "-" + value.slice(3);
									} else if (value.length > 6) {
										value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10);
									}

									partialSetCustomerIntake({
										phone: value,
									});
								}}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="phoneTwo">Phone 2:</label>
							<Input
								id="phoneTwo"
								name="phoneTwo"
								type="tel"
								required={true}
								value={customerIntake.phone2 ?? ""}
								onChange={(e) => {
									let value = e.target.value;
									value = value.replace(/\D/g, "");

									if (value.length > 3 && value.length <= 6) {
										value = value.slice(0, 3) + "-" + value.slice(3);
									} else if (value.length > 6) {
										value = value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10);
									}

									partialSetCustomerIntake({ phone2: value });
								}}
							/>
						</div>

						<div className="form-item">
							<label htmlFor="price">Price:</label>
							<CurrencyInput
								id="price"
								name="price"
								required={false}
								value={customerIntake.price != null ? parseInt(customerIntake.price) : null}
								onChange={(value) => {
									partialSetCustomerIntake({
										price: value?.toString(),
									});
								}}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="web">Web:</label>
							<Input
								id="web"
								name="web"
								required={true}
								value={customerIntake.web ?? ""}
								onChange={(e) => {
									partialSetCustomerIntake({
										web: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<div className="form-item">
						<label htmlFor="customerName">Customer Name:</label>
						<Input
							id="customerName"
							name="customerName"
							required={true}
							value={customerIntake.campaignRepresentative ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									campaignRepresentative: e.target.value,
								});
							}}
						/>
					</div>
					<div className="form-item">
						<label htmlFor="email">Email:</label>
						<Input
							id="email"
							name="email"
							required={true}
							value={customerIntake.email ?? ""}
							onChange={(e) => {
								partialSetCustomerIntake({
									email: e.target.value,
								});
							}}
						/>
					</div>

					<div className="form-item">
						<label htmlFor="pricePerSpot">Price Per Spot:</label>
						<CurrencyInput
							id="pricePerSpot"
							name="pricePerSpot"
							required={false}
							value={
								customerIntake.pricePerSpot != null ? parseInt(customerIntake.pricePerSpot) : null
							}
							onChange={(value) => {
								partialSetCustomerIntake({
									pricePerSpot: value?.toString(),
								});
							}}
						/>
					</div>
					<div className="bottom-details">
						<div className="form-item">
							<label htmlFor="announcements">Campaign:</label>
							<Input
								id="announcements"
								name="announcements"
								required={true}
								value={customerIntake.announcements ?? ""}
								type="number"
								step={1}
								onChange={(e) => {
									partialSetCustomerIntake({
										announcements: parseInt(e.target.value),
									});
								}}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="numberOfPayments">Last Purchase Date:</label>
							<Input
								id="lastPurchaseDate"
								name="lastPurchaseDate"
								required={true}
								type="date"
								step={1}
								value={customerIntake.lastPurchaseDate ?? ""}
								onChange={(e) => {
									partialSetCustomerIntake({
										lastPurchaseDate: e.target.value,
									});
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
