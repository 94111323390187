import { strToNumber } from "gate3-utils";
import React, { InputHTMLAttributes, Ref, useMemo } from "react";
import "./input.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	inputRef?: Ref<HTMLInputElement>;
}

const clampNum = (valueNum: number, minNum?: number, maxNum?: number) => {
	if (minNum !== undefined) {
		valueNum = Math.max(minNum, valueNum);
	}

	if (maxNum !== undefined) {
		valueNum = Math.min(maxNum, valueNum);
	}

	return valueNum;
};

const Input: React.FC<InputProps> = ({
	inputRef,
	className,
	min,
	max,
	onChange,
	...inputProps
}) => {
	const minNum = useMemo(() => {
		if (typeof min === "string") {
			return strToNumber(min);
		} else {
			return min;
		}
	}, [min]);

	const maxNum = useMemo(() => {
		if (typeof max === "string") {
			return strToNumber(max);
		} else {
			return max;
		}
	}, [max]);

	return (
		<input
			{...inputProps}
			ref={inputRef}
			className={className !== undefined ? "input " + className : "input"}
			onChange={(e) => {
				if (inputProps.type === "number") {
					let valueNum = strToNumber(e.target.value);
					// Can still be undefined if value is "". Possible if not required
					if (valueNum !== undefined && (minNum !== undefined || maxNum !== undefined)) {
						// @TODO preserve leading 0, you can't type 1.01 right now
						e.target.value = clampNum(valueNum, minNum, maxNum) + "";
					}
				}

				onChange?.(e);
			}}
		/>
	);
};

export default Input;
