import axios from "axios";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import env from "../../env";
import RequestState from "../../types/RequestState";

export interface ApiVersions {
	apiVersion: string;
	nonCodeFilesVersion: string;
}

export const apiVersionsAtom = atom<RequestState<ApiVersions>>({
	key: "apiVersions",
	default: {
		wasCalled: false,
		isLoading: false,
		error: undefined,
		data: undefined,
	},
});

// @todo uncomment until we have a backend

// const getApiVersions = async (): Promise<ApiVersions> => {
// 	const apiVersionRes = await axios.get<string>(env.REACT_APP_API_BASE_URL + "/api/v1/v");
// 	const nonCodeFilesVersionRes = await axios.get<string>(
// 		env.REACT_APP_API_BASE_URL + "/api/v1/ncfv",
// 	);

// 	return {
// 		apiVersion: apiVersionRes.data,
// 		nonCodeFilesVersion: nonCodeFilesVersionRes.data,
// 	};
// };

export const useInitApiVersions = () => {
	const [apiVersionsRequestState, setApiVersionsRequestState] = useRecoilState(apiVersionsAtom);

	useEffect(() => {
		setApiVersionsRequestState((state) => {
			return {
				...state,
				wasCalled: true,
				isLoading: true,
			};
		});

		// getApiVersions()
		// 	.then((apiVersions) => {
		// 		setApiVersionsRequestState((state) => {
		// 			return {
		// 				...state,
		// 				isLoading: false,
		// 				data: apiVersions,
		// 			};
		// 		});
		// 	})
		// 	.catch(() => {
		// 		setApiVersionsRequestState((state) => {
		// 			return {
		// 				...state,
		// 				isLoading: false,
		// 				error: "Failed to get api versions",
		// 			};
		// 		});
		// 	});
	}, [setApiVersionsRequestState]);
	return {
		apiVersionsRequestState,
	};
};
