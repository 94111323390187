import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import ApolloInputSearch from "../../../../components/customInputSearch/ApolloInputSearch";
import Button from "../../../../g3-components/button/Button";
import Input from "../../../../g3-components/input/Input";
import {
	FilterOperators,
	GetStationsDocument,
	GetStationsQueryVariables,
	GetStationsSortByNames,
	GetUsersDocument,
	GetUsersQueryVariables,
	GetUsersSortByNames,
	StationListItemFragment,
	UserListItemFragment,
} from "../../../../generated/graphql";
import useIsWindowMobile from "../../../../hooks/reusable/useIsWindowMobile";
import {
	useGetCustomerIntakeFilterData,
	usePartialSetCustomerIntakeFilter,
} from "../../customerIntake/customerIntakeForm/customerIntakeForm.service";
import { RepCodeUserInputSearch } from "../../customerIntake/customerIntakeForm/repCodeUsersInputSearch/RepCodeUserInputSearch";
import {
	CustomerIntakesRawFilters,
	_customerIntakesRawFiltersAtom,
} from "../customerIntakeList.service";
import "./customerIntakeListFilter.scss";

export const CustomerIntakeListFilter: React.FC = () => {
	const [localCustomerIntakeListFilter, setLocalCustomerIntakeListFilter] =
		useState<CustomerIntakesRawFilters>({});

	const [searchStrRepCode, setSearchStrRepCode] = useState("");
	const [searchStrStation, setSearchStrStation] = useState("");
	const [searchStrVerifiedBy, setSearchStrVerifiedBy] = useState("");

	// recoils
	const setCustomerIntakeListFilter = useSetRecoilState(_customerIntakesRawFiltersAtom);
	const { customerIntake } = useGetCustomerIntakeFilterData();
	const { partialSetCustomerIntake } = usePartialSetCustomerIntakeFilter();

	// functions
	const applyFilter = () => {
		setCustomerIntakeListFilter({
			...localCustomerIntakeListFilter,
		});
	};

	const clearFilter = useCallback(() => {
		setLocalCustomerIntakeListFilter({});
		setCustomerIntakeListFilter({});
		partialSetCustomerIntake({
			station: undefined,
			verifiedByUser: undefined,
			repCodeUser: undefined,
		});
		setSearchStrRepCode("");
		setSearchStrStation("");
		setSearchStrVerifiedBy("");
	}, [setCustomerIntakeListFilter]);

	// React hooks
	useEffect(() => {
		return () => {
			clearFilter();
		};
	}, [clearFilter]);

	// jsx variables

	return (
		<div className="costumer-intake-filters">
			<div className="filters-inputs">
				<div>
					<div className="font-medium text-gray-500">Customer:</div>
					<Input
						value={
							localCustomerIntakeListFilter.customerNumber !== undefined
								? localCustomerIntakeListFilter.customerNumber
								: ""
						}
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									customerNumber: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Business Name:</div>
					<Input
						value={
							localCustomerIntakeListFilter.businessName !== undefined
								? localCustomerIntakeListFilter.businessName
								: ""
						}
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									businessName: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Rep Code:</div>
					{/* <Input
						value={
							localCustomerIntakeListFilter.repCodeUserId !== undefined
								? localCustomerIntakeListFilter.repCodeUserId
								: ""
						}
						type="number"
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									repCodeUserId: parseInt(e.target.value, 10),
								};
							})
						}
					/> */}
					<ApolloInputSearch<UserListItemFragment, GetUsersQueryVariables>
						query={GetUsersDocument}
						searchParams={{
							shouldSearch: true,
							queryVariables: {
								input: {
									filter: {
										or: [
											{
												fields: {
													firstName: {
														val: "%" + searchStrRepCode + "%",
														op: FilterOperators.ILike,
													},
												},
											},
											{
												fields: {
													lastName: {
														val: "%" + searchStrRepCode + "%",
														op: FilterOperators.ILike,
													},
												},
											},
											{
												fields: {
													username: {
														val: "%" + searchStrRepCode + "%",
														op: FilterOperators.ILike,
													},
												},
											},
										],
										and: [
											{
												fields: {
													deletedBy: {
														val: null,
														op: FilterOperators.Equal,
													},
												},
											},
										],
									},
									sortBys: [
										{
											name: GetUsersSortByNames.FirstName,
											isDesc: false,
										},
										{
											name: GetUsersSortByNames.LastName,
											isDesc: false,
										},
										{
											name: GetUsersSortByNames.Username,
											isDesc: false,
										},
									],
									pagination: {
										limit: 10,
									},
								},
							},
						}}
						onGetDisplayLabels={(value) => {
							return {
								resultLabel:
									value.firstName || value.lastName
										? [value.firstName, value.lastName].filter(Boolean).join(" ")
										: value.username || "",
								selectedLabel:
									value.firstName || value.lastName
										? [value.firstName, value.lastName].filter(Boolean).join(" ")
										: value.username || "",
							};
						}}
						placeholder="Search User"
						value={
							customerIntake.repCodeUser
								? customerIntake.repCodeUser.firstName || customerIntake.repCodeUser.lastName
									? [customerIntake.repCodeUser.firstName, customerIntake.repCodeUser.lastName]
											.filter(Boolean)
											.join(" ")
									: customerIntake.repCodeUser.username
								: searchStrRepCode
						}
						onSelectSearchResult={(searchResult) => {
							setSearchStrRepCode(searchResult.selectedLabel);
							partialSetCustomerIntake({
								repCodeUser: {
									id: searchResult.value.id,
									username: searchResult.value.username,
									firstName: searchResult.value.firstName,
									lastName: searchResult.value.lastName,
								},
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									repCodeUserId: searchResult.value.id,
								};
							});
						}}
						onChange={(e) => {
							setSearchStrRepCode(e.target.value);
							partialSetCustomerIntake({
								repCodeUser: undefined,
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									repCodeUserId: customerIntake.repCodeUser?.id,
								};
							});
						}}
						onBlur={() => {
							if (customerIntake.repCodeUser === undefined) {
								setSearchStrRepCode("");
							}
						}}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Verified By:</div>
					{/* <Input
						value={
							localCustomerIntakeListFilter.verifiedByUserId !== undefined
								? localCustomerIntakeListFilter.verifiedByUserId
								: ""
						}
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									verifiedByUserId: parseInt(e.target.value, 10),
								};
							})
						}
					/> */}
					<ApolloInputSearch<UserListItemFragment, GetUsersQueryVariables>
						query={GetUsersDocument}
						searchParams={{
							shouldSearch: true,
							queryVariables: {
								input: {
									filter: {
										and: [
											{
												fields: {
													isVerifier: {
														val: true,
														op: FilterOperators.Equal,
													},
												},
											},
											{
												or: [
													{
														fields: {
															firstName: {
																val: "%" + searchStrVerifiedBy + "%",
																op: FilterOperators.ILike,
															},
														},
													},
													{
														fields: {
															lastName: {
																val: "%" + searchStrVerifiedBy + "%",
																op: FilterOperators.ILike,
															},
														},
													},
												],
												and: [
													{
														fields: {
															deletedBy: {
																val: null,
																op: FilterOperators.Equal,
															},
														},
													},
												],
											},
										],
									},
									sortBys: [
										{
											name: GetUsersSortByNames.FirstName,
											isDesc: false,
										},
										{
											name: GetUsersSortByNames.LastName,
											isDesc: false,
										},
									],
									pagination: {
										limit: 10,
									},
								},
							},
						}}
						onGetDisplayLabels={(value) => {
							return {
								resultLabel: value.firstName + " " + value.lastName,
								selectedLabel: value.firstName + " " + value.lastName,
							};
						}}
						placeholder="Search User"
						value={
							customerIntake.verifiedByUser
								? customerIntake.verifiedByUser.firstName +
								  " " +
								  customerIntake.verifiedByUser.lastName
								: searchStrVerifiedBy
						}
						onSelectSearchResult={(searchResult) => {
							setSearchStrVerifiedBy(searchResult.selectedLabel);
							partialSetCustomerIntake({
								verifiedByUser: {
									id: searchResult.value.id,
									username: searchResult.value.username,
									firstName: searchResult.value.firstName,
									lastName: searchResult.value.lastName,
								},
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									verifiedByUserId: searchResult.value.id,
								};
							});
						}}
						onChange={(e) => {
							setSearchStrVerifiedBy(e.target.value);
							partialSetCustomerIntake({
								verifiedByUser: undefined,
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									verifiedByUserId: parseInt(e.target.value, 10),
								};
							});
						}}
						onBlur={() => {
							if (customerIntake.verifiedByUser === undefined) {
								setSearchStrVerifiedBy("");
							}
						}}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Station:</div>
					{/* <Input
						value={
							localCustomerIntakeListFilter.stationId !== undefined
								? localCustomerIntakeListFilter.stationId
								: ""
						}
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									stationId: parseInt(e.target.value, 10),
								};
							})
						}
					/> */}
					<ApolloInputSearch<StationListItemFragment, GetStationsQueryVariables>
						query={GetStationsDocument}
						searchParams={{
							shouldSearch: true,
							queryVariables: {
								input: {
									filter: {
										fields: {
											name: {
												val: "%" + searchStrStation + "%",
												op: FilterOperators.ILike,
											},
										},
									},
									sortBys: [
										{
											name: GetStationsSortByNames.Name,
											isDesc: false,
										},
									],
									pagination: {
										limit: 10,
									},
								},
							},
						}}
						onGetDisplayLabels={(value) => {
							return {
								resultLabel: value.name,
								selectedLabel: value.name,
							};
						}}
						placeholder="Search Station"
						value={customerIntake.station ? customerIntake.station.name : searchStrStation}
						onSelectSearchResult={(searchResult) => {
							setSearchStrStation(searchResult.selectedLabel);
							partialSetCustomerIntake({
								station: {
									id: searchResult.value.id,
									name: searchResult.value.name,
								},
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									stationId: searchResult.value.id,
								};
							});
						}}
						onChange={(e) => {
							setSearchStrStation(e.target.value);
							partialSetCustomerIntake({
								station: undefined,
							});
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									stationId: parseInt(e.target.value, 10),
								};
							});
						}}
						onBlur={() => {
							if (customerIntake.station === undefined) {
								setSearchStrStation("");
							}
						}}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Time From:</div>
					<Input
						value={
							localCustomerIntakeListFilter.minTime !== undefined
								? localCustomerIntakeListFilter.minTime
								: ""
						}
						type="time"
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									minTime: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Time To:</div>
					<Input
						value={
							localCustomerIntakeListFilter.maxTime !== undefined
								? localCustomerIntakeListFilter.maxTime
								: ""
						}
						type="time"
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									maxTime: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Date From:</div>
					<Input
						value={
							localCustomerIntakeListFilter.minDate !== undefined
								? localCustomerIntakeListFilter.minDate
								: ""
						}
						type="date"
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									minDate: e.target.value,
								};
							})
						}
					/>
				</div>
				<div>
					<div className="font-medium text-gray-500">Date To:</div>
					<Input
						value={
							localCustomerIntakeListFilter.maxDate !== undefined
								? localCustomerIntakeListFilter.maxDate
								: ""
						}
						type="date"
						onChange={(e) =>
							setLocalCustomerIntakeListFilter((state) => {
								return {
									...state,
									maxDate: e.target.value,
								};
							})
						}
					/>
				</div>
			</div>
			<div className="filters-buttons">
				<Button className="outline-btn" onClick={() => clearFilter()}>
					Clear
				</Button>
				<Button className="default-btn" onClick={() => applyFilter()}>
					Apply
				</Button>
			</div>
		</div>
	);
};
