import React from "react";
import UsersActions from "./usersActions/UsersActions";
import UsersFilters from "./usersFilters/UsersFilters";
import UsersTable from "./usersTable/UsersTable";
import "./users.scss";
const Users: React.FC = () => {
	// incoming values

	// state hooks

	// recoils

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className="users">
			<div className="users-filter-actions">
				<UsersFilters />
				<UsersActions />
			</div>

			<UsersTable />
		</div>
	);
};

export default Users;
