import { AndOr, checkAndOr } from "gate3-utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import {
	CurrentUserFragment,
	LoginInput,
	PermissionCodes,
	useGetCurrentUserLazyQuery,
	useLoginMutation,
} from "../../generated/graphql";
import RequestState from "../../types/RequestState";
import { handleApolloError } from "../../utils/ApolloUtils";
import { useNavigate } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import { RoleCodes } from "../../constants/RoleCodes";

const _getCurrentUserRequestStateAtom = atom<RequestState<CurrentUserFragment>>({
	key: "getCurrentUserRequestState",
	default: {
		wasCalled: false,
		isLoading: false,
		data: undefined,
		error: undefined,
	},
});

export const useCurrentUserGetter = () => {
	const [getCurrentUserRequestState, setGetCurrentUserRequestState] = useRecoilState(
		_getCurrentUserRequestStateAtom,
	);

	const [_getCurrentUser, getCurrentUserRes] = useGetCurrentUserLazyQuery();

	const getCurrentUser = useCallback(async () => {
		const token = localStorage.getItem(LocalStorageKeys.TOKEN);
		if (token !== null) {
			setGetCurrentUserRequestState({
				wasCalled: true,
				isLoading: true,
				data: undefined,
				error: undefined,
			});

			return _getCurrentUser();
		} else {
			setGetCurrentUserRequestState({
				wasCalled: true,
				isLoading: false,
				data: undefined,
				error: Error("No token"),
			});

			throw Error("No token");
		}
	}, [_getCurrentUser, setGetCurrentUserRequestState]);

	useEffect(() => {
		if (getCurrentUserRes.called && !getCurrentUserRes.loading) {
			if (getCurrentUserRes.data) {
				const currentUser = getCurrentUserRes.data.getCurrentUsers.rows[0];
				setGetCurrentUserRequestState((state) => {
					return {
						...state,
						isLoading: false,
						data: currentUser,
					};
				});
			} else if (getCurrentUserRes.error) {
				setGetCurrentUserRequestState((state) => {
					return {
						...state,
						isLoading: false,
						error: Error(handleApolloError(getCurrentUserRes.error!)),
					};
				});
			}
		}
	}, [
		getCurrentUserRes.called,
		getCurrentUserRes.data,
		getCurrentUserRes.error,
		getCurrentUserRes.loading,
		setGetCurrentUserRequestState,
	]);

	return {
		getCurrentUser,
		getCurrentUserRequestState,
	};
};

export const useGetCurrentUser = () => {
	const getCurrentUserRequestState = useRecoilValue(_getCurrentUserRequestStateAtom);
	return {
		currentUser: getCurrentUserRequestState.data,
	};
};

export const useLogin = () => {
	const navigate = useNavigate();

	const [loginRequestState, setLoginRequestState] = useState<
		RequestState<CurrentUserFragment> & {
			isWithToken: boolean;
			redirectTo?: string;
		}
	>({
		wasCalled: false,
		isLoading: false,
		data: undefined,
		error: undefined,
		isWithToken: false,
	});

	const [_login, loginRes] = useLoginMutation();
	const { getCurrentUser, getCurrentUserRequestState } = useCurrentUserGetter();
	const redirectToRef = useRef<string | undefined>(undefined);

	const login = useCallback(
		async (
			input: LoginInput,
			options?: {
				redirectTo?: string;
			},
		) => {
			redirectToRef.current = options?.redirectTo;

			setLoginRequestState({
				wasCalled: true,
				isLoading: true,
				data: undefined,
				error: undefined,
				isWithToken: false,
			});

			return _login({
				variables: {
					input,
				},
			});
		},
		[_login],
	);

	const loginWithToken = useCallback(
		async (
			token: string,
			options?: {
				redirectTo?: string;
			},
		) => {
			redirectToRef.current = options?.redirectTo;

			localStorage.setItem(LocalStorageKeys.TOKEN, token);

			setLoginRequestState({
				wasCalled: true,
				isLoading: true,
				data: undefined,
				error: undefined,
				isWithToken: true,
			});

			return getCurrentUser();
		},
		[getCurrentUser],
	);

	useEffect(() => {
		if (loginRes.called && !loginRes.loading) {
			if (loginRes.data) {
				loginWithToken(loginRes.data!.login.token, {
					redirectTo: redirectToRef.current,
				}).catch(() => {
					setLoginRequestState((state) => {
						return {
							...state,
							isLoading: false,
							error: Error("Failed to login with token"),
						};
					});
				});
			} else if (loginRes.error) {
				setLoginRequestState((state) => {
					return {
						...state,
						isLoading: false,
						error: Error(handleApolloError(loginRes.error!)),
					};
				});
			}
		}
	}, [loginRes.called, loginRes.data, loginRes.error, loginRes.loading, loginWithToken]);

	useEffect(() => {
		if (getCurrentUserRequestState.wasCalled && !getCurrentUserRequestState.isLoading) {
			if (getCurrentUserRequestState.data) {
				navigate(redirectToRef.current ?? PathName.DEFAULT_LOGIN_REDIRECT);

				setLoginRequestState((state) => {
					return {
						...state,
						isLoading: false,
						data: getCurrentUserRequestState.data,
					};
				});
			} else {
				setLoginRequestState((state) => {
					return {
						...state,
						isLoading: false,
						error: getCurrentUserRequestState.error,
					};
				});
			}
		}
	}, [
		getCurrentUserRequestState.wasCalled,
		getCurrentUserRequestState.isLoading,
		getCurrentUserRequestState.error,
		getCurrentUserRequestState.data,
		navigate,
	]);

	return {
		login,
		loginWithToken,
		loginRequestState,
	};
};

export const useHasPermission = () => {
	const { currentUser } = useGetCurrentUser();

	const hasPermission = useCallback(
		(permissionCode: AndOr<PermissionCodes>) => {
			return checkAndOr(
				permissionCode,
				(permissionCode) =>
					!!currentUser &&
					currentUser.userUserRoles.some((userUserRole) =>
						userUserRole.role.rolePermissions.some(
							(rolePermission) => rolePermission.permission.code === permissionCode,
						),
					),
			);
		},
		[currentUser],
	);

	return {
		hasPermission,
	};
};

export const useHasRole = () => {
	const { currentUser } = useGetCurrentUser();

	const hasRole = useCallback(
		(roleCode: AndOr<string>) => {
			return checkAndOr(
				roleCode,
				(roleCode) =>
					!!currentUser &&
					currentUser.userUserRoles.some((userUserRole) => userUserRole.role.code === roleCode),
			);
		},
		[currentUser],
	);

	return {
		hasRole,
	};
};

export const useIsAdmin = () => {
	const { hasRole } = useHasRole();

	const isAdmin = useMemo(() => {
		return hasRole({
			value: RoleCodes.ADMIN,
		});
	}, [hasRole]);

	return {
		isAdmin,
	};
};
