import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PathName } from "../../../../../constants/PathName";
import Button from "../../../../../g3-components/button/Button";
import { useModals } from "../../../../../g3-components/modal/modals.recoil";
import { CustomerIntakeFragment } from "../../../../../generated/graphql";
import { useSaveCustomerIntake } from "../customerIntakeForm.service";
import "./customerIntakeDeleteButton.scss";

interface Props {
	customerIntake: CustomerIntakeFragment;
}

export const CustomerIntakeDeleteButton: React.FC<Props> = ({ customerIntake }) => {
	const { removeCustomerIntakes, removeCustomerIntakesRes } = useSaveCustomerIntake();
	const { pushModal, popModal } = useModals();
	const navigate = useNavigate();

	const _deleteStation = () => {
		removeCustomerIntakes({
			variables: {
				input: {
					primaryKeysList: [
						{
							id: customerIntake.id,
						},
					],
				},
			},
		})
			.then(() => {
				toast.success("Deleted Successfully!");
				navigate(PathName.CUSTOMER_INTAKES(""));
			})
			.catch(() => {
				toast.error("Delete Error!");
			})
			.finally(() => {
				popModal();
			});
	};

	const _deleteUserPrompt = () => {
		return (
			<div className="alarm-model">
				{`Delete Customer Intake #: ${customerIntake.customerNumber}?`}

				<div className="row mt-8 flex justify-center gap-3">
					<Button
						className="default-btn "
						type="button"
						onClick={() => {
							_deleteStation();
						}}
					>
						Yes
					</Button>
					<Button
						className="outline-btn"
						type="button"
						onClick={() => {
							popModal();
						}}
					>
						No
					</Button>
				</div>
			</div>
		);
	};
	return (
		<Button
			className="default-btn secondary-button"
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				pushModal({
					render: _deleteUserPrompt(),
				});
			}}
		>
			Delete
		</Button>
	);
};
