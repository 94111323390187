import React, { useRef } from "react";
import { useGetUserForm, usePartialSetUser, useSaveUser, useUserForm } from "./userForm.service";
// import UserRoles from "./userRoles/UserRoles";
import Button from "../../../../g3-components/button/Button";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { usersTableReloaderAtom } from "../users.service";
import Input from "../../../../g3-components/input/Input";
// import Input from "src/g3-components/input/Input";
interface Props {
	userId?: number;
	onCancel: () => void;
	onSaveSuccess: () => void;
}

const UserForm: React.FC<Props> = ({ userId, onCancel, onSaveSuccess }) => {
	// state hooks
	const formRef = useRef<HTMLFormElement | null>(null);

	// recoils
	useUserForm(formRef, userId);
	const {
		userForm: { user },
	} = useGetUserForm();
	const { saveUser, addUsersRes, editUsersRes } = useSaveUser();
	const reloadUsersTable = useSetRecoilState(usersTableReloaderAtom);
	const { partialSetUser } = usePartialSetUser();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables
	const isLoading = addUsersRes.loading || editUsersRes.loading;

	return (
		<form className="modal-form">
			<div className="h-[90vh] overflow-y-auto">
				<div>
					<label htmlFor="email">Email</label>
					<Input
						id="email"
						type={"email"}
						value={user.username ?? ""}
						onChange={(e) => {
							partialSetUser({
								username: e.target.value,
							});
						}}
					/>
				</div>
				{user.id === undefined && (
					<div>
						<label htmlFor="password">Password</label>
						<Input
							id="password"
							value={user.password ?? ""}
							onChange={(e) => {
								partialSetUser({
									password: e.target.value,
								});
							}}
						/>
					</div>
				)}

				<div>
					<label htmlFor="firstName">First Name</label>
					<Input
						id="firstName"
						value={user.firstName ?? ""}
						onChange={(e) => {
							partialSetUser({
								firstName: e.target.value,
							});
						}}
					/>
				</div>
				<div>
					<label htmlFor="lastName">Last Name</label>
					<Input
						id="lastName"
						value={user.lastName ?? ""}
						onChange={(e) => {
							partialSetUser({
								lastName: e.target.value,
							});
						}}
					/>
				</div>
				<div>
					<label htmlFor="isVerifier">Is Verifier</label>
					<br />
					<input
						id="isVerifier"
						type="checkbox"
						checked={user.isVerifier ?? false}
						onChange={(e) => {
							partialSetUser({
								isVerifier: e.target.checked,
							});
						}}
					/>
				</div>

				{/* <UserRoles /> */}
			</div>
			<div className="form-sticky-btn flex justify-end gap-2">
				<Button
					className="outline-btn"
					type="button"
					onClick={() => {
						onCancel();
					}}
				>
					Cancel
				</Button>
				<Button
					className="default-btn"
					type="button"
					disabled={isLoading}
					onClick={() => {
						saveUser()
							.then(() => {
								onSaveSuccess();
								reloadUsersTable((v) => !v);
								toast.success("Saved Successfully!");
							})
							.catch(() => {
								toast.error("Save Error!");
							});
					}}
				>
					{user.id !== undefined ? "Update" : "Add"}
				</Button>
			</div>
		</form>
	);
};

export default UserForm;
