import React, { useEffect, useRef } from "react";
import Button from "../../../../g3-components/button/Button";
import Input from "../../../../g3-components/input/Input";
import {
	useGetResetUserPasswordInput,
	usePartialSetResetUserPasswordInput,
} from "./resetUserPasswordForm.service";
import { UserListItemFragment } from "../../../../generated/graphql";
import { ResetUserPasswordResetButton } from "./resetUserPasswordResetButton/ResetUserPasswordResetButton";

interface Props {
	user: UserListItemFragment;
	onCancel: () => void;
	onSuccess: () => void;
}

export const ResetUserPasswordForm: React.FC<Props> = ({ user, onCancel, onSuccess }) => {
	// state hooks
	const formRef = useRef<HTMLFormElement | null>(null);

	// recoils

	// custom hooks
	const { resetUserPasswordInput } = useGetResetUserPasswordInput();
	const { partialSetResetUserPasswordInput } = usePartialSetResetUserPasswordInput();

	// local variables

	// functions
	useEffect(() => {
		partialSetResetUserPasswordInput({
			userId: user.id,
		});
	}, [partialSetResetUserPasswordInput, user]);

	// React hooks

	// jsx variables

	return (
		<form className="modal-form" ref={formRef}>
			<div className="h-[90vh] overflow-y-auto">
				Reset Password For {user.firstName + " " + user.lastName}
				<div>
					<label htmlFor="password">New Password</label>
					<Input
						id="password"
						value={resetUserPasswordInput.password ?? ""}
						onChange={(e) => {
							partialSetResetUserPasswordInput({
								password: e.target.value,
							});
						}}
					/>
				</div>
				<div>
					<label htmlFor="confirmPassword">Confirm New Password</label>
					<Input
						id="confirmPassword"
						value={resetUserPasswordInput.confirmPassword ?? ""}
						onChange={(e) => {
							partialSetResetUserPasswordInput({
								confirmPassword: e.target.value,
							});
						}}
					/>
				</div>
			</div>
			<div className="form-sticky-btn flex justify-end gap-2">
				<Button
					className="outline-btn"
					type="button"
					onClick={() => {
						onCancel();
					}}
				>
					Cancel
				</Button>
				<ResetUserPasswordResetButton onSuccess={onSuccess} />
			</div>
		</form>
	);
};
