import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { PartialDeep } from "type-fest";
import {
	ResetUserPasswordInput,
	useResetUserPasswordMutation,
} from "../../../../generated/graphql";
import { useCallback } from "react";
import produce from "immer";

export type ResetUserPasswordInputFormData = PartialDeep<
	ResetUserPasswordInput,
	{ recurseIntoArrays: true }
>;

export interface ResetUserPasswordInputForm {
	form: HTMLFormElement | null;
	isDirty: boolean;
	resetUserPasswordInput: ResetUserPasswordInputFormData;
}

const _resetUserPasswordInputFormAtom = atom<ResetUserPasswordInputForm>({
	key: "resetUserPasswordInputForm",
	default: {
		form: null,
		isDirty: false,
		resetUserPasswordInput: {},
	},
});

export const useGetResetUserPasswordInput = () => {
	const { resetUserPasswordInput } = useRecoilValue(_resetUserPasswordInputFormAtom);

	return {
		resetUserPasswordInput,
	};
};

export const usePartialSetResetUserPasswordInput = () => {
	const setResetUserPasswordInputForm = useSetRecoilState(_resetUserPasswordInputFormAtom);

	const partialSetResetUserPasswordInput = useCallback(
		(input: ResetUserPasswordInputFormData) => {
			setResetUserPasswordInputForm((prev) => {
				return produce(prev, (draft) => {
					draft.isDirty = true;
					draft.resetUserPasswordInput = {
						...draft.resetUserPasswordInput,
						...input,
					};
				});
			});
		},
		[setResetUserPasswordInputForm],
	);

	return {
		partialSetResetUserPasswordInput,
	};
};

export const useResetUserPassword = () => {
	const { resetUserPasswordInput } = useRecoilValue(_resetUserPasswordInputFormAtom);

	const [_resetUserPassword, resetUserPasswordRes] = useResetUserPasswordMutation();

	const resetUserPassword = useCallback(async () => {
		if (
			resetUserPasswordInput.userId === undefined ||
			resetUserPasswordInput.password === undefined ||
			resetUserPasswordInput.confirmPassword === undefined
		) {
			throw new Error("A field is missing");
		}

		return _resetUserPassword({
			variables: {
				input: {
					userId: resetUserPasswordInput.userId,
					password: resetUserPasswordInput.password,
					confirmPassword: resetUserPasswordInput.confirmPassword,
				},
			},
		});
	}, [_resetUserPassword, resetUserPasswordInput]);

	return {
		resetUserPassword,
		resetUserPasswordRes,
	};
};
