import { InputHTMLAttributes, Ref, useMemo, useState } from "react";
import Input from "../input/Input";
import "./input-search.scss";

export interface InputSearchResult<TValue> {
	value: TValue;
	resultLabel: string | JSX.Element; // label for each result
	selectedLabel: string; // when you click the result, the input becomes this value
	isNotSelectable?: boolean;
}

export interface InputSearchProps<TSearchResultValue>
	extends InputHTMLAttributes<HTMLInputElement> {
	dataTestId?: string;
	inputRef?: Ref<HTMLInputElement>;
	searchResults: InputSearchResult<TSearchResultValue>[];
	onSelectSearchResult?: (searchResult: InputSearchResult<TSearchResultValue>) => void;
	maxResults?: number;
}

const InputSearch = <TValue extends any>({
	dataTestId,
	inputRef,
	searchResults,
	onSelectSearchResult,
	maxResults,
	onFocus,
	onBlur,
	disabled,
	...props
}: InputSearchProps<TValue>) => {
	const [isFocused, setIsFocused] = useState(false);

	const shouldShowSearchOptions = useMemo(
		() => !disabled && searchResults.length !== 0 && isFocused,
		[disabled, isFocused, searchResults.length],
	);

	const resultDivs = useMemo(() => {
		const resultDivs: JSX.Element[] = [];

		const resultLen =
			maxResults !== undefined ? Math.min(searchResults.length, maxResults) : searchResults.length;

		for (let i = 0; i < resultLen; ++i) {
			const row = searchResults[i];
			resultDivs.push(
				<div
					key={i}
					data-testid={`${dataTestId}.${row.value}`}
					className={"option " + (typeof row.resultLabel === "string" ? "label" : "")}
					onMouseDown={
						row.isNotSelectable
							? undefined
							: () => {
									onSelectSearchResult?.(row);
							  }
					}
				>
					{row.resultLabel}
				</div>,
			);
		}

		return resultDivs;
	}, [dataTestId, maxResults, onSelectSearchResult, searchResults]);

	return (
		<>
			<Input
				{...props}
				inputRef={inputRef}
				disabled={disabled}
				onFocus={(e) => {
					setIsFocused(true);
					onFocus?.(e);
				}}
				onBlur={(e) => {
					setIsFocused(false);
					onBlur?.(e);
				}}
			/>
			{shouldShowSearchOptions && (
				<div className="items-cont">
					<div className={"items-sub-cont has-content"}>{resultDivs}</div>
				</div>
			)}
		</>
	);
};

export default InputSearch;
