import React from "react";
import "./customerIntakeSaveButton.scss";
import { useSaveCustomerIntake } from "../customerIntakeForm.service";
import { toast } from "react-toastify";
import Button from "../../../../../g3-components/button/Button";

export const CustomerIntakeSaveButton: React.FC = () => {
	const { saveCustomerIntake, addCustomerIntakesRes, editCustomerIntakesRes } =
		useSaveCustomerIntake();

	return (
		<div className="customerIntakeSaveButton">
			<Button
				type="button"
				className="default-btn primary-button"
				onClick={() => {
					saveCustomerIntake()
						.then(() => {
							toast.success("Saved Successfully!");
						})
						.catch(() => {
							toast.error("Save Error!");
						});
				}}
				disabled={addCustomerIntakesRes.loading || editCustomerIntakesRes.loading}
			>
				Save
			</Button>
		</div>
	);
};
