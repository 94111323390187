import React from "react";
import "./settings.scss";
import { SettingsTabs } from "./settingsTabs/SettingsTabs";
import { SettingsTabEnum, useGetSettingsTabSelected } from "./settings.service";
import Users from "./users/Users";
import { useHasPermission } from "../../state/user/currentUser.recoil";
import { PermissionCodes } from "../../generated/graphql";
import { Navigate } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import { Stations } from "./station/Stations";

const Settings: React.FC = () => {
	// incoming values

	// state hooks

	// recoils

	// custom hooks
	const { hasPermission } = useHasPermission();
	const { settingsTabSelected } = useGetSettingsTabSelected();

	// local variables

	// functions

	// React hooks

	// jsx variables

	if (
		!hasPermission({
			value: PermissionCodes.Settings,
		})
	) {
		return <Navigate to={PathName.NOT_FOUND} />;
	}

	return (
		<div className="settings">
			<SettingsTabs />
			<div className="settings-content">
				{settingsTabSelected === SettingsTabEnum.Users && <Users />}
				{settingsTabSelected === SettingsTabEnum.Station && <Stations />}
			</div>
		</div>
	);
};

export default Settings;
