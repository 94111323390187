import React from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import Button from "../../../../../g3-components/button/Button";
import { useModals } from "../../../../../g3-components/modal/modals.recoil";
import {
	StationListItemFragment,
	useEditStationsMutation,
	useEditUsersMutation,
	useRemoveStationsMutation,
} from "../../../../../generated/graphql";
import { stationsTableReloaderAtom } from "../../stations.service";
import "./deleteTableButton.scss";

interface Props {
	children?: React.ReactNode;
	station: StationListItemFragment;
}

export const DeleteTableButton: React.FC<Props> = ({ children, station }) => {
	// state hooks

	// recoils
	const { pushModal, popModal } = useModals();
	const reloadStationsTable = useSetRecoilState(stationsTableReloaderAtom);

	// // custom hooks
	const [removeStations] = useRemoveStationsMutation();

	// local variables

	// functions
	const _deleteStation = () => {
		removeStations({
			variables: {
				input: {
					primaryKeysList: [
						{
							id: station.id,
						},
					],
				},
			},
		})
			.then(() => {
				toast.success("Deleted Successfully!");
				reloadStationsTable((v) => !v);
			})
			.catch(() => {
				toast.error("Delete Error!");
			})
			.finally(() => {
				popModal();
			});
	};

	const _deleteUserPrompt = () => {
		return (
			<div className="alarm-model">
				{`Delete station ${station.name}?`}

				<div className="row mt-8 flex justify-center gap-3">
					<Button
						className="default-btn"
						type="button"
						onClick={() => {
							_deleteStation();
						}}
					>
						Yes
					</Button>
					<Button
						className="outline-btn"
						type="button"
						onClick={() => {
							popModal();
						}}
					>
						No
					</Button>
				</div>
			</div>
		);
	};

	// React hooks

	// jsx variables

	return (
		<Button
			className="flex w-full justify-center"
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				pushModal({
					render: _deleteUserPrompt(),
				});
			}}
		>
			{children}
		</Button>
	);
};
