import React, { useCallback, useEffect, useState } from "react";
import { FiTrash2, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { MdOutlineEditNote } from "react-icons/md";
import { useRecoilValue } from "recoil";
import { useModals } from "../../../../g3-components/modal/modals.recoil";
import Table from "../../../../g3-components/table/Table";
import {
	GetStationsSortBy,
	GetStationsSortByNames,
	useGetStationsLazyQuery,
} from "../../../../generated/graphql";
import { pushToSortBys } from "../../../../utils/GraphQLUtils";
import { StationForm } from "../stationForm/StationForm";
import { stationsTableReloaderAtom, getStationsFilterSelector } from "../stations.service";
import { DeleteTableButton } from "./deleteTableButton/DeleteTableButton";
import "./stationsTable.scss";

const DEFAULT_SORT_BY: GetStationsSortBy = {
	name: GetStationsSortByNames.Name,
};
const PAGE_ITEM_COUNT = 50;
type State = {
	currentPage: number;
	sortBys: GetStationsSortBy[];
};

export const StationsTable: React.FC = () => {
	const [{ currentPage, sortBys }, setState] = useState<State>({
		currentPage: 1,
		sortBys: [DEFAULT_SORT_BY],
	});

	// recoils
	const { pushModal, popModal } = useModals();
	const stationsTableReloader = useRecoilValue(stationsTableReloaderAtom);
	const getStationsFilter = useRecoilValue(getStationsFilterSelector);

	// custom hooks
	const [getStations, getStationsRes] = useGetStationsLazyQuery();

	// local variables

	// functions
	const _getStationsByPage = useCallback(
		(page: number) => {
			void getStations({
				variables: {
					input: {
						filter: getStationsFilter,
						sortBys,
						pagination: {
							offset: (page - 1) * PAGE_ITEM_COUNT,
							limit: PAGE_ITEM_COUNT,
						},
					},
				},
			});
		},
		[getStations, getStationsFilter, sortBys],
	);

	// React hooks
	useEffect(() => {
		_getStationsByPage(currentPage);
	}, [currentPage, _getStationsByPage]);

	useEffect(
		function resetStationsTable() {
			if (currentPage === 1) {
				_getStationsByPage(1);
			} else {
				setState((state) => {
					return {
						...state,
						currentPage: 1,
					};
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[stationsTableReloader, getStationsFilter],
	);

	// jsx variables
	const stations = getStationsRes.data?.getStations.rows || [];

	const pages =
		(getStationsRes.data !== undefined ? getStationsRes.data.getStations.count : 0) /
		PAGE_ITEM_COUNT;

	return (
		<div className="stationsTable">
			<Table
				columns={[
					{
						name: "ID",
						field: "id",
						isSortable: true,
						sortField: GetStationsSortByNames.Id,
						formattedField: (row) => {
							return <>{row.data.id}</>;
						},
					},

					{
						name: "STATION",
						isSortable: true,
						sortField: GetStationsSortByNames.Name,
						formattedField: (row) => {
							return <div>{row.data.name}</div>;
						},
					},

					{
						name: <div className="w-full text-center">DELETE</div>,
						formattedField: (row) => {
							return (
								<DeleteTableButton station={row.data}>
									<FiTrash2 className="h-6 w-6 text-fsOrange" aria-hidden="true" />
								</DeleteTableButton>
							);
						},
					},
				]}
				rows={stations.map((data) => {
					return {
						data,
						className: "clickable-row",
					};
				})}
				onRowClick={(row) => {
					pushModal({
						render: (
							<StationForm
								stationId={row.data.id}
								onCancel={() => {
									popModal();
								}}
								onSaveSuccess={() => {
									popModal();
								}}
							/>
						),
						title: "Update Station",
					});
				}}
				className="settings-global-general-stations-table"
				pagination={{
					pages,
					currentPage,
					onPageClick: (page: number) => {
						setState((state) => {
							return {
								...state,
								currentPage: page,
							};
						});
					},
				}}
				onSort={(sortField, isDesc) => {
					setState((state) => {
						return {
							...state,
							sortBys: pushToSortBys(state.sortBys, sortField as GetStationsSortByNames, isDesc),
						};
					});
				}}
				defaultSortField={DEFAULT_SORT_BY.name}
				defaultIsSortDesc={DEFAULT_SORT_BY.isDesc ?? false}
				notSortedIcon={
					<div className="flex flex-col">
						<FiChevronUp className="-mb-2 h-4 w-4 text-slate-400" />
						<FiChevronDown className="h-4 w-4 text-slate-400" />
					</div>
				}
				ascIcon={
					<div className="flex flex-col items-center">
						<FiChevronUp className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
				descIcon={
					<div className="flex flex-col items-center">
						<FiChevronDown className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
			/>
		</div>
	);
};
