import { useCallback, useEffect } from "react";
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import produce, { Draft } from "immer";
import {
	RoleListItemFragment,
	UserFragment,
	useAddUsersMutation,
	useEditUsersMutation,
	useGetUserLazyQuery,
} from "../../../../generated/graphql";
import { PartialDeep } from "type-fest";

export type UserFragmentFormData = PartialDeep<UserFragment, { recurseIntoArrays: true }> & {
	password?: string;
	confirmPassword?: string;
};
export type RoleListItemFragmentFormData = PartialDeep<
	RoleListItemFragment,
	{ recurseIntoArrays: true }
>;

export interface UserForm {
	form: HTMLFormElement | null;
	isDirty: boolean;
	user: UserFragmentFormData;
}

const _userFormAtom = atom<UserForm>({
	key: "userForm",
	default: {
		form: null,
		isDirty: false,
		user: {
			isVerifier: false,
			userUserRoles: [],
		},
	},
});

export const useGetUserForm = () => {
	const userForm = useRecoilValue(_userFormAtom);

	return {
		userForm,
	};
};

export const useUserForm = (formRef: React.RefObject<HTMLFormElement | null>, userId?: number) => {
	const setUserForm = useSetRecoilState(_userFormAtom);
	const resetUserForm = useResetRecoilState(_userFormAtom);

	const [getUser, getUserRes] = useGetUserLazyQuery();

	useEffect(() => {
		return () => {
			resetUserForm();
		};
	}, [resetUserForm]);

	useEffect(() => {
		setUserForm((state) => {
			return {
				...state,
				form: formRef.current,
			};
		});
	}, [formRef, setUserForm]);

	useEffect(() => {
		if (userId !== undefined) {
			void getUser({
				variables: {
					id: userId,
				},
			});
		}
	}, [getUser, userId]);

	useEffect(() => {
		if (getUserRes.called && !getUserRes.loading) {
			if (getUserRes.data && getUserRes.data.getUsers.rows.length !== 0) {
				const user = getUserRes.data.getUsers.rows[0];
				setUserForm((state) => {
					return {
						...state,
						user,
					};
				});
			}
		}
	}, [getUserRes.called, getUserRes.data, getUserRes.loading, setUserForm]);
};

export const useSetUserForm = () => {
	const _setUserForm = useSetRecoilState(_userFormAtom);

	const setUserForm = useCallback(
		(callback: (draft: Draft<UserForm>) => void) => {
			_setUserForm((state) => {
				return produce(state, (draft) => {
					callback(draft);
				});
			});
		},
		[_setUserForm],
	);

	return {
		setUserForm,
	};
};

export const usePartialSetUser = () => {
	const { setUserForm } = useSetUserForm();

	const partialSetUser = useCallback(
		(input: UserFragmentFormData) => {
			setUserForm((draft) => {
				draft.isDirty = true;
				draft.user = {
					...draft.user,
					...input,
				};
			});
		},
		[setUserForm],
	);

	const insertUserUserRole = useCallback(
		(input: RoleListItemFragmentFormData) => {
			setUserForm((draft) => {
				draft.isDirty = true;
				draft.user.userUserRoles?.push({
					role: input,
				});
			});
		},
		[setUserForm],
	);

	const destroyUserUserRole = useCallback(
		(index: number) => {
			setUserForm((draft) => {
				draft.isDirty = true;
				draft.user.userUserRoles?.splice(index, 1);
			});
		},
		[setUserForm],
	);

	return {
		partialSetUser,
		insertUserUserRole,
		destroyUserUserRole,
	};
};

export const useSaveUser = () => {
	const { user } = useRecoilValue(_userFormAtom);

	const [addUsers, addUsersRes] = useAddUsersMutation();
	const [editUsers, editUsersRes] = useEditUsersMutation();

	const saveUser = useCallback(async () => {
		// @TODO here verify required fields from form

		if (user.id === undefined) {
			await addUsers({
				variables: {
					input: {
						inputs: [
							{
								associatedFields: {
									userUserRoles:
										user.userUserRoles?.map((userUserRole) => {
											return {
												primaryFields: {
													roleId: userUserRole!.role!.id!,
												},
											};
										}) ?? [],
								},
								primaryFields: {
									username: user.username!,
									password: user.password!,
									isVerifier: user.isVerifier!,
									firstName: user.firstName ?? "",
									lastName: user.lastName ?? "",
								},
							},
						],
					},
				},
			});
		} else {
			await editUsers({
				variables: {
					input: {
						inputs: [
							{
								primaryKeys: {
									id: user.id!,
								},
								associatedFields: {
									userUserRoles:
										user.userUserRoles?.map((userUserRole) => {
											return {
												primaryFields: {
													roleId: userUserRole!.role!.id!,
												},
											};
										}) ?? [],
								},
								primaryFields: {
									username: user.username!,
									isVerifier: user.isVerifier!,
									firstName: user.firstName ?? "",
									lastName: user.lastName ?? "",
								},
							},
						],
					},
				},
			});
		}
	}, [
		user.id,
		user.userUserRoles,
		user.username,
		user.password,
		user.isVerifier,
		user.firstName,
		user.lastName,
		addUsers,
		editUsers,
	]);

	return {
		saveUser,
		addUsersRes,
		editUsersRes,
	};
};
