export interface SortBy<T> {
	name: T;
	isDesc?: boolean | null;
}

export const pushToSortBys = <T>(sortBys: SortBy<T>[], name: T, isDesc: boolean): SortBy<T>[] => {
	const i = sortBys.findIndex((sortBy) => sortBy.name === name);
	const outOrderBys = [
		{
			name,
			isDesc,
		},
		...sortBys,
	];
	if (i !== -1) {
		outOrderBys.splice(i + 1, 1);
	}

	return outOrderBys;
};
