import React, { useState } from "react";

import {
	useGetCustomerIntakeFormData,
	usePartialSetCustomerIntake,
} from "../customerIntakeForm.service";
import ApolloInputSearch from "../../../../../components/customInputSearch/ApolloInputSearch";
import {
	FilterOperators,
	GetUsersDocument,
	GetUsersQueryVariables,
	GetUsersSortByNames,
	UserListItemFragment,
} from "../../../../../generated/graphql";

export const VerifiedByUserInputSearch: React.FC = () => {
	// state hooks
	const [searchStr, setSearchStr] = useState("");

	// recoils
	const { customerIntake } = useGetCustomerIntakeFormData();
	const { partialSetCustomerIntake } = usePartialSetCustomerIntake();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// set current user as default rep code user if not admin

	// jsx variables

	return (
		<ApolloInputSearch<UserListItemFragment, GetUsersQueryVariables>
			query={GetUsersDocument}
			searchParams={{
				shouldSearch: true,
				queryVariables: {
					input: {
						filter: {
							and: [
								{
									fields: {
										isVerifier: {
											val: true,
											op: FilterOperators.Equal,
										},
									},
								},
								{
									fields: {
										deletedBy: {
											val: null,
											op: FilterOperators.Equal,
										},
									},
								},
								{
									or: [
										{
											fields: {
												firstName: {
													val: "%" + searchStr + "%",
													op: FilterOperators.ILike,
												},
											},
										},
										{
											fields: {
												lastName: {
													val: "%" + searchStr + "%",
													op: FilterOperators.ILike,
												},
											},
										},
									],
								},
							],
						},
						sortBys: [
							{
								name: GetUsersSortByNames.FirstName,
								isDesc: false,
							},
							{
								name: GetUsersSortByNames.LastName,
								isDesc: false,
							},
						],
						pagination: {
							limit: 10,
						},
					},
				},
			}}
			onGetDisplayLabels={(value) => {
				return {
					resultLabel: value.firstName + " " + value.lastName,
					selectedLabel: value.firstName + " " + value.lastName,
				};
			}}
			placeholder="Search User"
			value={
				customerIntake.verifiedByUser
					? customerIntake.verifiedByUser.firstName + " " + customerIntake.verifiedByUser.lastName
					: searchStr
			}
			onSelectSearchResult={(searchResult) => {
				setSearchStr(searchResult.selectedLabel);
				partialSetCustomerIntake({
					verifiedByUser: {
						id: searchResult.value.id,
						username: searchResult.value.username,
						firstName: searchResult.value.firstName,
						lastName: searchResult.value.lastName,
					},
				});
			}}
			onChange={(e) => {
				setSearchStr(e.target.value);
				partialSetCustomerIntake({
					verifiedByUser: undefined,
				});
			}}
			onBlur={() => {
				if (customerIntake.verifiedByUser === undefined) {
					setSearchStr("");
				}
			}}
		/>
	);
};
