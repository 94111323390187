import React, { useCallback } from "react";
import Button from "../../../../../g3-components/button/Button";
import { useGetCustomerIntakesCsv } from "../../customerIntakeList.service";
import { toast } from "react-toastify";
import { format } from "date-fns";

export const CustomerIntakeListExportButton: React.FC = () => {
	const { getCustomerIntakesCsv, getCustomerIntakesCsvBase64Res } = useGetCustomerIntakesCsv();

	const downloadCustomerIntakesCsv = useCallback(async () => {
		const customerIntakesCsv = await getCustomerIntakesCsv();

		const blob = new Blob([customerIntakesCsv], { type: "text/csv" });

		const url = window.URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = `Customer Intakes ${format(new Date(), "MM-dd-yyyy")}.csv`;
		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(url);
	}, [getCustomerIntakesCsv]);

	return (
		<Button
			type="button"
			className="default-btn primary-button"
			disabled={getCustomerIntakesCsvBase64Res.loading}
			onClick={() => {
				downloadCustomerIntakesCsv().catch(() => {
					toast.error("Export Error!");
				});
			}}
		>
			Export
		</Button>
	);
};
