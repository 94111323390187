import React from "react";
import { Outlet } from "react-router-dom";
import "./customerIntakes.scss";

export const CustomerIntakes: React.FC = () => {
	return (
		<div className="customerIntakes">
			<Outlet />
		</div>
	);
};
