import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import {
	CustomerIntakeLogListItemFragment,
	FilterOperators,
	GetCustomerIntakeLogsSortByNames,
	useGetCustomerIntakeLogsLazyQuery,
} from "../../../../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { PathName } from "../../../../../../constants/PathName";
import { useGetCustomerIntakeFormData } from "../../customerIntakeForm.service";

const _customerIntakeLogsAtom = atom<CustomerIntakeLogListItemFragment[]>({
	key: "customerIntakeLogs",
	default: [],
});

const _customerIntakeLogsReloaderAtom = atom<boolean>({
	key: "customerIntakeLogsReloader",
	default: false,
});

export const useCustomerIntakeLogListService = () => {
	const navigate = useNavigate();

	const { customerIntake } = useGetCustomerIntakeFormData();
	const [getCustomerIntakeLogs, getCustomerIntakeLogsRes] = useGetCustomerIntakeLogsLazyQuery();
	const setCustomerIntakeLogs = useSetRecoilState(_customerIntakeLogsAtom);
	const customerIntakeLogsReloader = useRecoilValue(_customerIntakeLogsReloaderAtom);

	useEffect(() => {
		if (customerIntake.id === undefined) {
			return;
		}

		void getCustomerIntakeLogs({
			variables: {
				input: {
					filter: {
						fields: {
							customerIntakeId: {
								val: customerIntake.id,
								op: FilterOperators.Equal,
							},
						},
					},
					sortBys: [
						{
							name: GetCustomerIntakeLogsSortByNames.CreatedAt,
							isDesc: true,
						},
					],
				},
			},
		});
	}, [customerIntake.id, getCustomerIntakeLogs, customerIntakeLogsReloader]);

	useEffect(() => {
		if (getCustomerIntakeLogsRes.called && !getCustomerIntakeLogsRes.loading) {
			if (getCustomerIntakeLogsRes.error || !getCustomerIntakeLogsRes.data) {
				navigate(PathName.UNKNOWN_ERROR);
				return;
			}

			setCustomerIntakeLogs(getCustomerIntakeLogsRes.data.getCustomerIntakeLogs.rows);
		}

		return () => {
			setCustomerIntakeLogs([]);
		};
	}, [
		getCustomerIntakeLogsRes.called,
		getCustomerIntakeLogsRes.data,
		getCustomerIntakeLogsRes.error,
		getCustomerIntakeLogsRes.loading,
		navigate,
		setCustomerIntakeLogs,
	]);
};

export const useGetCustomerIntakeLogs = () => {
	const customerIntakeLogs = useRecoilValue(_customerIntakeLogsAtom);

	return {
		customerIntakeLogs,
	};
};

export const useReloadCustomerIntakeLogs = () => {
	const setCustomerIntakeLogsReloader = useSetRecoilState(_customerIntakeLogsReloaderAtom);

	const reloadCustomerIntakeLogs = useCallback(() => {
		setCustomerIntakeLogsReloader((prev) => {
			return !prev;
		});
	}, [setCustomerIntakeLogsReloader]);

	return {
		reloadCustomerIntakeLogs,
	};
};
