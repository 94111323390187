import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Version } from "../../../version";
import { apiVersionsAtom } from "../../../state/apiVersions/apiVersions.recoil";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { BiUserCircle } from "react-icons/bi";
import useLogout from "../../../hooks/reusable/useLogout";
import { headerStateAtom, HeaderStates } from "../MainLayout.recoil";
import useIsWindowMobile from "../../../hooks/reusable/useIsWindowMobile";
import classNames from "classnames";
import { useGetCurrentUser } from "../../../state/user/currentUser.recoil";

const UserNavigation = () => {
	// state hooks

	// recoils
	const { currentUser } = useGetCurrentUser();
	const apiVersion = useRecoilValue(apiVersionsAtom);
	const [headerState, setHeaderState] = useRecoilState(headerStateAtom);

	// custom hooks
	const isWindowMobile = useIsWindowMobile();
	const logout = useLogout();

	// local variables

	// React hooks

	// jsx variables
	return (
		<div className="flex h-full items-center">
			{!isWindowMobile && (
				<span className="name-user mr-4">
					Welcome, {currentUser?.firstName + " " + currentUser?.lastName}
				</span>
			)}
			<Menu
				onMenuChange={(e) =>
					e.open ? setHeaderState(HeaderStates.UserMenu) : setHeaderState(HeaderStates.Default)
				}
				className="relative z-50 ml-3"
				menuButton={
					<MenuButton className="h-full w-14">
						<div className="flex h-full w-full flex-row items-center justify-center tablet:flex-col">
							<BiUserCircle
								className={classNames(
									"h-6 w-6",
									headerState === HeaderStates.UserMenu ? "text-gray-500" : "",
								)}
							/>
						</div>
					</MenuButton>
				}
				direction="bottom"
				align="center"
				position="anchor"
				viewScroll="close"
			>
				<MenuItem disabled>
					<span className="text-slate-500">
						{currentUser?.firstName + " " + currentUser?.lastName}
					</span>
				</MenuItem>
				<MenuItem onClick={() => logout()}>Sign out</MenuItem>
				<MenuItem disabled>
					<div className="desktop-version">
						<div className="hidden">{Version.GIT_LAST_COMMIT()}</div>
						<div className="hidden">{Version.GIT_LAST_COMMIT_DATE()}</div>

						<div className="block text-xs text-gray-400">
							{
								"v." + Version.GIT_REV_COUNT() + "." + "0.0"
								// (apiVersion.data ? apiVersion.data.apiVersion : "av error") +
								// "." +
								// (apiVersion.data ? apiVersion.data.nonCodeFilesVersion : "nv error")
							}
						</div>
					</div>
				</MenuItem>
			</Menu>
		</div>
	);
};

export default UserNavigation;
