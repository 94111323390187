import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

export enum SettingsTabEnum {
	Users = "Users",
	Station = "Station",
}

const _settingsTabSelectedAtom = atom<SettingsTabEnum>({
	key: "settingsTabSelected",
	default: SettingsTabEnum.Users,
});

export const useGetSettingsTabSelected = () => {
	const settingsTabSelected = useRecoilValue(_settingsTabSelectedAtom);

	return {
		settingsTabSelected,
	};
};

export const useSetSettingsTabSelected = () => {
	const setSettingsTabSelected = useSetRecoilState(_settingsTabSelectedAtom);

	return {
		setSettingsTabSelected,
	};
};

export const useResetSettingsTabSelected = () => {
	const resetSettingsTabSelected = useResetRecoilState(_settingsTabSelectedAtom);

	return {
		resetSettingsTabSelected,
	};
};
