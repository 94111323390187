import React, { useEffect, useState } from "react";

import {
	useGetCustomerIntakeFormData,
	usePartialSetCustomerIntake,
} from "../customerIntakeForm.service";
import ApolloInputSearch from "../../../../../components/customInputSearch/ApolloInputSearch";
import {
	FilterOperators,
	GetUsersDocument,
	GetUsersQueryVariables,
	GetUsersSortByNames,
	UserListItemFragment,
} from "../../../../../generated/graphql";
import { useGetCurrentUser, useIsAdmin } from "../../../../../state/user/currentUser.recoil";

export const RepCodeUserInputSearch: React.FC = () => {
	// state hooks
	const [searchStr, setSearchStr] = useState("");

	// recoils
	const { customerIntake } = useGetCustomerIntakeFormData();
	const { partialSetCustomerIntake } = usePartialSetCustomerIntake();

	const { currentUser } = useGetCurrentUser();
	const { isAdmin } = useIsAdmin();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// set current user as default rep code user if not admin
	useEffect(() => {
		if (!isAdmin) {
			partialSetCustomerIntake({
				repCodeUser: {
					id: currentUser?.id,
					username: currentUser?.username,
					firstName: currentUser?.firstName,
					lastName: currentUser?.lastName,
				},
			});
		}
	}, [currentUser, isAdmin, partialSetCustomerIntake]);

	// jsx variables

	return (
		<ApolloInputSearch<UserListItemFragment, GetUsersQueryVariables>
			query={GetUsersDocument}
			searchParams={{
				shouldSearch: true,
				queryVariables: {
					input: {
						filter: {
							or: [
								{
									fields: {
										firstName: {
											val: "%" + searchStr + "%",
											op: FilterOperators.ILike,
										},
									},
								},
								{
									fields: {
										lastName: {
											val: "%" + searchStr + "%",
											op: FilterOperators.ILike,
										},
									},
								},
								{
									fields: {
										username: {
											val: "%" + searchStr + "%",
											op: FilterOperators.ILike,
										},
									},
								},
								{
									fields: {
										deletedBy: {
											val: null,
											op: FilterOperators.Equal,
										},
									},
								},
							],
							and: [
								{
									fields: {
										deletedBy: {
											val: null,
											op: FilterOperators.Equal,
										},
									},
								},
							],
						},
						sortBys: [
							{
								name: GetUsersSortByNames.FirstName,
								isDesc: false,
							},
							{
								name: GetUsersSortByNames.LastName,
								isDesc: false,
							},
							{
								name: GetUsersSortByNames.Username,
								isDesc: false,
							},
						],
						pagination: {
							limit: 10,
						},
					},
				},
			}}
			onGetDisplayLabels={(value) => {
				return {
					resultLabel:
						value.firstName || value.lastName
							? [value.firstName, value.lastName].filter(Boolean).join(" ")
							: value.username || "",
					selectedLabel:
						value.firstName || value.lastName
							? [value.firstName, value.lastName].filter(Boolean).join(" ")
							: value.username || "",
				};
			}}
			placeholder="Search User"
			value={
				customerIntake.repCodeUser
					? customerIntake.repCodeUser.firstName || customerIntake.repCodeUser.lastName
						? [customerIntake.repCodeUser.firstName, customerIntake.repCodeUser.lastName]
								.filter(Boolean)
								.join(" ")
						: customerIntake.repCodeUser.username
					: searchStr
			}
			onSelectSearchResult={(searchResult) => {
				setSearchStr(searchResult.selectedLabel);
				partialSetCustomerIntake({
					repCodeUser: {
						id: searchResult.value.id,
						username: searchResult.value.username,
						firstName: searchResult.value.firstName,
						lastName: searchResult.value.lastName,
					},
				});
			}}
			onChange={(e) => {
				setSearchStr(e.target.value);
				partialSetCustomerIntake({
					repCodeUser: undefined,
				});
			}}
			onBlur={() => {
				if (customerIntake.repCodeUser === undefined) {
					setSearchStr("");
				}
			}}
			disabled={!isAdmin}
		/>
	);
};
