import React from "react";
import useIsWindowMobile from "../../../hooks/reusable/useIsWindowMobile";
import "./header.scss";
import NavigationMenuButton from "./NavigationMenuButton";
import SearchMenuButton from "./SearchMenuButton";
import UserNavigation from "./UserNavigation";

const Header: React.FC = () => {
	// incoming values

	// state hooks

	// recoils

	// custom hooks
	const isWindowMobile = useIsWindowMobile();
	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className="header">
			{isWindowMobile ? (
				<>
					<div className="flex h-full">
						<NavigationMenuButton />
						<div className="flex h-full items-center">
							<img className="h-full" src="\img\logo.png" alt="logo" />
							{/* <div className="font-bold text-fsDarkGray">PBG</div> */}
						</div>
					</div>
					<div className="flex h-full items-center">
						{/* <SearchMenuButton /> */}
						<UserNavigation />
					</div>
				</>
			) : (
				<>
					{/* dont delete this div */}
					<div />
					<UserNavigation />
				</>
			)}
		</div>
	);
};

export default Header;
