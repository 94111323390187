import { atom, selector } from "recoil";
import { FilterOperators, GetUsersFilter } from "../../../generated/graphql";

export const usersTableReloaderAtom = atom<boolean>({
	key: "usersTableReloader",
	default: false,
});

export interface UsersTableFilter {
	username?: string;
	name?: string;
	isVerifier?: boolean;
	deletedBy?: number;
}

export const usersTableFilterAtom = atom<UsersTableFilter>({
	key: "usersTableFilter",
	default: {},
});

export const getUsersFilterSelector = selector<GetUsersFilter>({
	key: "getUsersFilter",
	get: ({ get }) => {
		const usersTableFilter = get(usersTableFilterAtom);

		const getUsersFilter: GetUsersFilter = {
			and: [],
		};

		if (usersTableFilter.username !== undefined) {
			getUsersFilter.and!.push({
				fields: {
					username: {
						val: `%${usersTableFilter.username}%`,
						op: FilterOperators.ILike,
					},
				},
			});
		}

		if (usersTableFilter.name !== undefined) {
			getUsersFilter.and!.push({
				or: [
					{
						fields: {
							firstName: {
								val: `%${usersTableFilter.name.split(" ")[0]}%`,
								op: FilterOperators.ILike,
							},
						},
					},
					{
						fields: {
							lastName: {
								val: `%${usersTableFilter.name.split(" ")[1]}%`,
								op: FilterOperators.ILike,
							},
						},
					},
				],
			});
		}

		if (usersTableFilter.isVerifier !== undefined) {
			getUsersFilter.and!.push({
				fields: {
					isVerifier: {
						val: usersTableFilter.isVerifier,
						op: FilterOperators.Equal,
					},
				},
			});
		}

		if (usersTableFilter.deletedBy === undefined) {
			getUsersFilter.and!.push({
				fields: {
					deletedBy: {
						val: null,
						op: FilterOperators.Equal,
					},
				},
			});
		}

		return getUsersFilter;
	},
});
