import { useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useInitApiVersions } from "../state/apiVersions/apiVersions.recoil";

const initialPathAtom = atom<string | undefined>({
	key: "initialPath",
	default: undefined,
});

export const useMain = () => {
	const setInitialPath = useSetRecoilState(initialPathAtom);

	useEffect(() => {
		setInitialPath(window.location.pathname);
	}, [setInitialPath]);

	useInitApiVersions();
};

export const useGetInitialPath = () => {
	const initialPath = useRecoilValue(initialPathAtom);

	return { initialPath };
};
