import React, { useRef } from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import Button from "../../../../g3-components/button/Button";
import Input from "../../../../g3-components/input/Input";
import { stationsTableReloaderAtom } from "../stations.service";
import {
	useGetStationForm,
	usePartialSetStation,
	useSaveStation,
	useStationForm,
} from "./stationForm.service";
import "./stationForms.scss";

interface Props {
	stationId?: number;
	onCancel: () => void;
	onSaveSuccess: () => void;
}

export const StationForm: React.FC<Props> = ({ stationId, onCancel, onSaveSuccess }) => {
	// state hooks
	const formRef = useRef<HTMLFormElement | null>(null);

	// recoils
	useStationForm(formRef, stationId);
	const {
		stationForm: { station },
	} = useGetStationForm();
	const { saveStation, addStationsRes, editStationsRes } = useSaveStation();
	const reloadStationsTable = useSetRecoilState(stationsTableReloaderAtom);
	const { partialSetStation } = usePartialSetStation();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables
	const isLoading = addStationsRes.loading || editStationsRes.loading;

	return (
		<form className="modal-form station-form">
			<div className="h-[90vh] overflow-y-auto">
				<div>
					<label htmlFor="firstName">Station Name</label>
					<Input
						id="firstName"
						value={station.name ?? ""}
						onChange={(e) => {
							partialSetStation({
								name: e.target.value,
							});
						}}
					/>
				</div>

				{/* <UserRoles /> */}
			</div>
			<div className="form-sticky-btn flex justify-end gap-2">
				<Button
					className="outline-btn"
					type="button"
					onClick={() => {
						onCancel();
					}}
				>
					Cancel
				</Button>
				<Button
					className="default-btn"
					type="button"
					disabled={isLoading}
					onClick={() => {
						saveStation()
							.then(() => {
								onSaveSuccess();
								reloadStationsTable((v) => !v);
								toast.success("Saved Successfully!");
							})
							.catch(() => {
								toast.error("Save Error!");
							});
					}}
				>
					{station.id !== undefined ? "Update" : "Add"}
				</Button>
			</div>
		</form>
	);
};
