import React from "react";
import "./loading.scss";
// const { ReactComponent: FiveStarLogo } = require("../../assets/five-star-last.svg");

const Loading: React.FC = () => {
	return (
		<div className="loading">
			<div className="star-container">
				<div className="star-wrapper">
					{/* <FiveStarLogo /> */}
					<img src="\img\logo-circle.png" />
				</div>
				<div className="star-shadow"></div>
			</div>
		</div>
	);
};

export default Loading;
