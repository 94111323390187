import React from "react";
import "./stations.scss";
import { StationsActions } from "./stationsActions/StationsActions";
import { StationsFilters } from "./stationsFilters/StationsFilters";
import { StationsTable } from "./stationsTable/StationsTable";

export const Stations: React.FC = () => {
	return (
		<div className="stations">
			<div className="stations-filter-actions">
				<StationsFilters />
				<StationsActions />
			</div>

			<StationsTable />
		</div>
	);
};
