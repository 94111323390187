import { HiChevronDown, HiChevronRight } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import { NavigationItem } from "./Navigation";
import { NavLink } from "react-router-dom";

interface Props {
	defaultIsOpen?: boolean;
	item: NavigationItem;
	onItemClick?: () => void;
	isHoverMenu?: boolean;
}

// @TODO move this to utils and use it everywhere
function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const NavItem: React.FC<Props> = ({ defaultIsOpen, item, onItemClick, isHoverMenu }) => {
	// state hooks
	const [isOpen, setIsOpen] = useState(!!defaultIsOpen);

	// recoils

	// custom hooks

	// local variables

	// functions
	const getButtonContent = () => {
		return (
			<>
				{item.icon && <item.icon className="nav-item-icon" aria-hidden="true" />}
				{!isHoverMenu && (
					<>
						<span className="flex-1">{item.name}</span>
						{item.children && (
							<>
								{isOpen ? (
									<HiChevronDown className="h-5 w-5" />
								) : (
									<HiChevronRight className="h-5 w-5" />
								)}
							</>
						)}
					</>
				)}
			</>
		);
	};

	// React hooks
	useEffect(() => {
		if (isHoverMenu) {
			setIsOpen(false);
		}
	}, [isHoverMenu]);

	// jsx variables

	return (
		<div
			className={classNames("nav-item", isHoverMenu ? "hover-menu-item" : "")}
			onMouseEnter={() => {
				isHoverMenu && setIsOpen(true);
			}}
			onMouseLeave={() => {
				isHoverMenu && setIsOpen(false);
			}}
		>
			{item.children && !isHoverMenu ? (
				<div
					className={classNames("menu-item", "parent", item.isCurrent ? "current" : "")}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				>
					{getButtonContent()}
				</div>
			) : (
				<NavLink
					className={classNames("menu-item", item.isCurrent ? "current" : "")}
					to={item.href}
					onClick={() => onItemClick && onItemClick()}
				>
					{getButtonContent()}
				</NavLink>
			)}

			{isOpen && (
				<div className={classNames("sub-nav-cont", isHoverMenu ? "is-hover-menu" : "")}>
					{isHoverMenu && (
						<NavLink
							className={classNames(
								"menu-item border-b border-fsBorderGray",
								item.isCurrent ? "current" : "",
							)}
							to={item.href}
							onClick={() => {
								onItemClick && onItemClick();
								setIsOpen(false);
							}}
						>
							<span className="flex-1  text-end">{item.name}</span>
						</NavLink>
					)}
					{item.children?.map((subItem, index) => (
						<NavLink
							key={index}
							to={subItem.href || ""}
							className={classNames("menu-item", "child", subItem.isCurrent ? "current" : "")}
							onClick={() => {
								onItemClick && onItemClick();
								setIsOpen(false);
							}}
						>
							{subItem.name}
						</NavLink>
					))}
				</div>
			)}
		</div>
	);
};

export default NavItem;
