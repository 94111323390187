import React, { useCallback, useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp, FiTrash2 } from "react-icons/fi";
import { MdOutlineEditNote } from "react-icons/md";
import { useRecoilValue } from "recoil";
import {
	GetUsersSortBy,
	GetUsersSortByNames,
	useGetUsersLazyQuery,
} from "../../../../generated/graphql";
import { useModals } from "../../../../g3-components/modal/modals.recoil";
import Table from "../../../../g3-components/table/Table";
import { getUsersFilterSelector, usersTableReloaderAtom } from "../users.service";
import { pushToSortBys } from "../../../../utils/GraphQLUtils";
import UserForm from "../userForm/UserForm";
import DeleteUserButton from "./deleteUserButton/DeleteUserButton";
import OpenResetUserPasswordFormButton from "./openResetUserPasswordFormButton/OpenResetUserPasswordFormButton";

const DEFAULT_SORT_BY: GetUsersSortBy = {
	name: GetUsersSortByNames.Username,
};
const PAGE_ITEM_COUNT = 50;
type State = {
	currentPage: number;
	sortBys: GetUsersSortBy[];
};

const UsersTable: React.FC = () => {
	const [{ currentPage, sortBys }, setState] = useState<State>({
		currentPage: 1,
		sortBys: [DEFAULT_SORT_BY],
	});

	// recoils
	const { pushModal, popModal } = useModals();
	const usersTableReloader = useRecoilValue(usersTableReloaderAtom);
	const getUsersFilter = useRecoilValue(getUsersFilterSelector);

	// custom hooks
	const [getUsers, getUsersRes] = useGetUsersLazyQuery();

	// local variables

	// functions
	const _getUsersByPage = useCallback(
		(page: number) => {
			void getUsers({
				variables: {
					input: {
						filter: getUsersFilter,
						sortBys,
						pagination: {
							offset: (page - 1) * PAGE_ITEM_COUNT,
							limit: PAGE_ITEM_COUNT,
						},
					},
				},
			});
		},
		[getUsers, getUsersFilter, sortBys],
	);

	// React hooks
	useEffect(() => {
		_getUsersByPage(currentPage);
	}, [currentPage, _getUsersByPage]);

	useEffect(
		function resetUsersTable() {
			if (currentPage === 1) {
				_getUsersByPage(1);
			} else {
				setState((state) => {
					return {
						...state,
						currentPage: 1,
					};
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[usersTableReloader, getUsersFilter],
	);

	// jsx variables
	const users = getUsersRes.data?.getUsers.rows || [];

	const pages =
		(getUsersRes.data !== undefined ? getUsersRes.data.getUsers.count : 0) / PAGE_ITEM_COUNT;

	return (
		<Table
			columns={[
				{
					name: "ID",
					field: "id",
					isSortable: true,
					sortField: GetUsersSortByNames.Id,
					formattedField: (row) => {
						return <>{row.data.id}</>;
					},
				},
				{
					name: "USERNAME",
					field: "username",
					isSortable: true,
					sortField: GetUsersSortByNames.Username,
				},
				{
					name: "NAME",
					isSortable: true,
					sortField: GetUsersSortByNames.FirstName,
					formattedField: (row) => {
						return (
							<div>
								{row.data.firstName || row.data.lastName
									? (row.data.firstName ? row.data.firstName + " " : "") +
									  (row.data.lastName ? row.data.lastName : "")
									: ""}
							</div>
						);
					},
				},
				{
					name: "IS VERIFIER",
					isSortable: true,
					sortField: GetUsersSortByNames.FirstName,
					formattedField: (row) => {
						return <div>{row.data.isVerifier ? "Yes" : "No"}</div>;
					},
				},
				{
					name: <div className="w-full text-center">RESET PASSWORD</div>,
					formattedField: (row) => {
						return (
							<OpenResetUserPasswordFormButton user={row.data}>
								<MdOutlineEditNote className="h-6 w-6 text-fsOrange" aria-hidden="true" />
							</OpenResetUserPasswordFormButton>
						);
					},
				},
				{
					name: <div className="w-full text-center">DELETE</div>,
					formattedField: (row) => {
						return (
							<DeleteUserButton user={row.data}>
								<FiTrash2 className="h-6 w-6 text-fsOrange" aria-hidden="true" />
							</DeleteUserButton>
						);
					},
				},
			]}
			rows={users.map((data) => {
				return {
					data,
					className: "clickable-row",
				};
			})}
			onRowClick={(row) => {
				pushModal({
					render: (
						<UserForm
							userId={row.data.id}
							onCancel={() => {
								popModal();
							}}
							onSaveSuccess={() => {
								popModal();
							}}
						/>
					),
					title: "Update User",
				});
			}}
			className="settings-global-general-users-table"
			pagination={{
				pages,
				currentPage,
				onPageClick: (page: number) => {
					setState((state) => {
						return {
							...state,
							currentPage: page,
						};
					});
				},
			}}
			onSort={(sortField, isDesc) => {
				setState((state) => {
					return {
						...state,
						sortBys: pushToSortBys(state.sortBys, sortField as GetUsersSortByNames, isDesc),
					};
				});
			}}
			defaultSortField={DEFAULT_SORT_BY.name}
			defaultIsSortDesc={DEFAULT_SORT_BY.isDesc ?? false}
			notSortedIcon={
				<div className="flex flex-col">
					<FiChevronUp className="-mb-2 h-4 w-4 text-slate-400" />
					<FiChevronDown className="h-4 w-4 text-slate-400" />
				</div>
			}
			ascIcon={
				<div className="flex flex-col items-center">
					<FiChevronUp className="h-4 w-4 text-fsOrangeDark" />
				</div>
			}
			descIcon={
				<div className="flex flex-col items-center">
					<FiChevronDown className="h-4 w-4 text-fsOrangeDark" />
				</div>
			}
		/>
	);
};

export default UsersTable;
