import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { LoginInput } from "../../generated/graphql";
import { useLogin } from "../../state/user/currentUser.recoil";
import { useGetInitialPath } from "../../data/Main.recoil";
import { PathName } from "../../constants/PathName";
import Loading from "../../components/loading/Loading";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import "./login.scss";
import env, { EnvTypes } from "../../env";

type LoginFormState = Partial<LoginInput>;

const Login: React.FC = () => {
	const [{ username, password }, setLoginFormState] = useState<LoginFormState>({});

	const { login, loginWithToken, loginRequestState } = useLogin();

	const { initialPath } = useGetInitialPath();
	const _submit = useCallback(
		(e: FormEvent) => {
			e.preventDefault();

			login(
				{
					username: username!,
					password: password!,
					isClear: env.NODE_ENV === EnvTypes.Development,
				},
				{
					redirectTo: initialPath !== PathName.LOGIN ? initialPath : undefined,
				},
			).catch(() => {
				console.info("Login failed");
			});
		},
		[login, username, password, initialPath],
	);

	useEffect(() => {
		let token: string | null;
		if ((token = localStorage.getItem(LocalStorageKeys.TOKEN)) !== null) {
			loginWithToken(token).catch(() => {
				console.info("Login failed");
			});
		}
	}, [loginWithToken]);

	const isLoading = loginRequestState.isWithToken && loginRequestState.isLoading;

	return (
		<div className="login">
			{isLoading && <Loading />}
			<div className="flex w-full max-w-md flex-col space-y-8">
				<div>
					<img className="mx-auto" src="/img/logo.png" alt="fivestar" />
					<h2 className="mt-6 text-center text-3xl font-extrabold text-fsOrange">
						Sign in to your account
					</h2>
				</div>
				<form className="mt-8 space-y-6" onSubmit={(e) => _submit(e)}>
					<div className="flex flex-col gap-2 -space-y-px rounded-md shadow-sm">
						<div>
							<label className="sr-only">Username</label>
							<input
								type="text"
								required
								className="login-input"
								placeholder="Username"
								disabled={loginRequestState.isLoading}
								value={username ?? ""}
								onChange={(e) =>
									setLoginFormState((state) => {
										return {
											...state,
											username: e.target.value,
										};
									})
								}
							/>
						</div>
						<div>
							<label htmlFor="password" className="sr-only">
								Password
							</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								className="login-input"
								placeholder="Password"
								disabled={loginRequestState.isLoading}
								value={password ?? ""}
								onChange={(e) =>
									setLoginFormState((state) => {
										return {
											...state,
											password: e.target.value,
										};
									})
								}
							/>
						</div>
					</div>

					<div>
						<button
							type="submit"
							className="group relative flex w-full justify-center rounded-md border border-transparent bg-fsOrange py-2 px-4 text-sm font-medium text-white hover:bg-fsOrangeDark focus:outline-none focus:ring-2 focus:ring-fsRed-light focus:ring-offset-2"
							disabled={loginRequestState.isLoading}
						>
							Sign in
						</button>
						{!loginRequestState.isWithToken &&
						loginRequestState.error !== undefined &&
						loginRequestState.error.message !== "No token"
							? "Wrong username or password"
							: ""}
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
