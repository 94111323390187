import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  Json: object;
  NullableBoolean: boolean | null;
  NullableDate: Date | null;
  NullableFloat: number | null;
  NullableInt: number | null;
  NullableJson: object | null;
  NullableString: string | null;
  UnknownTypeStringified: string | null;
};

export type AddCustomerIntakeInput = {
  primaryFields: AddCustomerIntakePrimaryFields;
};

export type AddCustomerIntakeLogInput = {
  primaryFields: AddCustomerIntakeLogPrimaryFields;
};

export type AddCustomerIntakeLogPrimaryFields = {
  customerIntakeId: Scalars['Int'];
  message?: InputMaybe<Scalars['NullableString']>;
};

export type AddCustomerIntakeLogsInput = {
  inputs: Array<AddCustomerIntakeLogInput>;
};

export type AddCustomerIntakePrimaryFields = {
  address?: InputMaybe<Scalars['NullableString']>;
  announcements?: InputMaybe<Scalars['NullableInt']>;
  authorizer?: InputMaybe<Scalars['NullableString']>;
  businessName?: InputMaybe<Scalars['NullableString']>;
  campaignRepresentative?: InputMaybe<Scalars['NullableString']>;
  city?: InputMaybe<Scalars['NullableString']>;
  communityCampaign?: InputMaybe<Scalars['NullableString']>;
  csNumber?: InputMaybe<Scalars['NullableString']>;
  customerNumber?: InputMaybe<Scalars['NullableString']>;
  date?: InputMaybe<Scalars['NullableString']>;
  email?: InputMaybe<Scalars['NullableString']>;
  fax?: InputMaybe<Scalars['NullableString']>;
  invoiceNumber?: InputMaybe<Scalars['NullableString']>;
  lastPurchaseDate?: InputMaybe<Scalars['NullableString']>;
  numberOfPayments?: InputMaybe<Scalars['NullableInt']>;
  phone?: InputMaybe<Scalars['NullableString']>;
  phone2?: InputMaybe<Scalars['NullableString']>;
  price?: InputMaybe<Scalars['NullableString']>;
  pricePerSpot?: InputMaybe<Scalars['NullableString']>;
  repCodeUserId?: InputMaybe<Scalars['NullableInt']>;
  stateId?: InputMaybe<Scalars['NullableInt']>;
  stationId?: InputMaybe<Scalars['NullableInt']>;
  time?: InputMaybe<Scalars['NullableString']>;
  title?: InputMaybe<Scalars['NullableString']>;
  verifiedByUserId?: InputMaybe<Scalars['NullableInt']>;
  web?: InputMaybe<Scalars['NullableString']>;
  zip?: InputMaybe<Scalars['NullableString']>;
};

export type AddCustomerIntakesInput = {
  inputs: Array<AddCustomerIntakeInput>;
};

export type AddRoleAssociatedFields = {
  rolePermissions: Array<AddRoleWithRolePermissionsInput>;
};

export type AddRoleInput = {
  associatedFields: AddRoleAssociatedFields;
  primaryFields: AddRolePrimaryFields;
};

export type AddRolePrimaryFields = {
  name: Scalars['String'];
};

export type AddRoleWithRolePermissionsInput = {
  primaryFields: AddRoleWithRolePermissionsPrimaryFields;
};

export type AddRoleWithRolePermissionsPrimaryFields = {
  permissionId: Scalars['Int'];
};

export type AddRolesInput = {
  inputs: Array<AddRoleInput>;
};

export type AddRolesOutput = {
  __typename?: 'AddRolesOutput';
  rows: Array<AddedRole>;
};

export type AddStationInput = {
  primaryFields: AddStationPrimaryFields;
};

export type AddStationPrimaryFields = {
  name: Scalars['String'];
};

export type AddStationsInput = {
  inputs: Array<AddStationInput>;
};

export type AddStationsOutput = {
  __typename?: 'AddStationsOutput';
  rows: Array<AddedStation>;
};

export type AddUserAssociatedFields = {
  userUserRoles: Array<AddUserWithUserUserRolesInput>;
};

export type AddUserInput = {
  associatedFields: AddUserAssociatedFields;
  primaryFields: AddUserPrimaryFields;
};

export type AddUserPrimaryFields = {
  firstName?: InputMaybe<Scalars['NullableString']>;
  id?: InputMaybe<Scalars['Int']>;
  isVerifier: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['NullableString']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AddUserWithUserUserRolesInput = {
  primaryFields: AddUserWithUserUserRolesPrimaryFields;
};

export type AddUserWithUserUserRolesPrimaryFields = {
  roleId: Scalars['Int'];
};

export type AddUsersInput = {
  inputs: Array<AddUserInput>;
};

export type AddUsersOutput = {
  __typename?: 'AddUsersOutput';
  rows: Array<AddedUser>;
};

export type AddedRole = {
  __typename?: 'AddedRole';
  id: Scalars['Int'];
};

export type AddedStation = {
  __typename?: 'AddedStation';
  id: Scalars['Int'];
};

export type AddedUser = {
  __typename?: 'AddedUser';
  id: Scalars['Int'];
};

export type BooleanFilter = {
  op: FilterOperators;
  val: Scalars['Boolean'];
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  firstName?: Maybe<Scalars['NullableString']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['NullableString']>;
  userUserRoles: Array<UserRole>;
  username: Scalars['String'];
};

export type CustomerIntake = {
  __typename?: 'CustomerIntake';
  address?: Maybe<Scalars['NullableString']>;
  announcements?: Maybe<Scalars['NullableInt']>;
  authorizer?: Maybe<Scalars['NullableString']>;
  businessName?: Maybe<Scalars['NullableString']>;
  campaignRepresentative?: Maybe<Scalars['NullableString']>;
  city?: Maybe<Scalars['NullableString']>;
  communityCampaign?: Maybe<Scalars['NullableString']>;
  createdAt: Scalars['Date'];
  csNumber?: Maybe<Scalars['NullableString']>;
  customerNumber?: Maybe<Scalars['NullableString']>;
  date?: Maybe<Scalars['NullableString']>;
  email?: Maybe<Scalars['NullableString']>;
  fax?: Maybe<Scalars['NullableString']>;
  id: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['NullableString']>;
  lastPurchaseDate?: Maybe<Scalars['NullableString']>;
  numberOfPayments?: Maybe<Scalars['NullableInt']>;
  phone?: Maybe<Scalars['NullableString']>;
  phone2?: Maybe<Scalars['NullableString']>;
  price?: Maybe<Scalars['NullableString']>;
  pricePerSpot?: Maybe<Scalars['NullableString']>;
  repCodeUser?: Maybe<User>;
  state?: Maybe<State>;
  station?: Maybe<Station>;
  time?: Maybe<Scalars['NullableString']>;
  title?: Maybe<Scalars['NullableString']>;
  updatedAt?: Maybe<Scalars['NullableDate']>;
  verifiedByUser?: Maybe<User>;
  verifiedByUserId?: Maybe<Scalars['NullableInt']>;
  web?: Maybe<Scalars['NullableString']>;
  zip?: Maybe<Scalars['NullableString']>;
};

export type CustomerIntakeLog = {
  __typename?: 'CustomerIntakeLog';
  createdAt: Scalars['Date'];
  createdByUser?: Maybe<User>;
  id: Scalars['Int'];
  message?: Maybe<Scalars['NullableString']>;
};

export type DateFilter = {
  op: FilterOperators;
  val: Scalars['Date'];
};

export type EditCustomerIntakeInput = {
  primaryFields?: InputMaybe<EditCustomerIntakePrimaryFields>;
  primaryKeys: EditCustomerIntakePrimaryKeys;
};

export type EditCustomerIntakePrimaryFields = {
  address?: InputMaybe<Scalars['NullableString']>;
  announcements?: InputMaybe<Scalars['NullableInt']>;
  authorizer?: InputMaybe<Scalars['NullableString']>;
  businessName?: InputMaybe<Scalars['NullableString']>;
  campaignRepresentative?: InputMaybe<Scalars['NullableString']>;
  city?: InputMaybe<Scalars['NullableString']>;
  communityCampaign?: InputMaybe<Scalars['NullableString']>;
  csNumber?: InputMaybe<Scalars['NullableString']>;
  customerNumber?: InputMaybe<Scalars['NullableString']>;
  date?: InputMaybe<Scalars['NullableString']>;
  email?: InputMaybe<Scalars['NullableString']>;
  fax?: InputMaybe<Scalars['NullableString']>;
  invoiceNumber?: InputMaybe<Scalars['NullableString']>;
  lastPurchaseDate?: InputMaybe<Scalars['NullableString']>;
  numberOfPayments?: InputMaybe<Scalars['NullableInt']>;
  phone?: InputMaybe<Scalars['NullableString']>;
  phone2?: InputMaybe<Scalars['NullableString']>;
  price?: InputMaybe<Scalars['NullableString']>;
  pricePerSpot?: InputMaybe<Scalars['NullableString']>;
  repCodeUserId?: InputMaybe<Scalars['NullableInt']>;
  stateId?: InputMaybe<Scalars['NullableInt']>;
  stationId?: InputMaybe<Scalars['NullableInt']>;
  time?: InputMaybe<Scalars['NullableString']>;
  title?: InputMaybe<Scalars['NullableString']>;
  verifiedByUserId?: InputMaybe<Scalars['NullableInt']>;
  web?: InputMaybe<Scalars['NullableString']>;
  zip?: InputMaybe<Scalars['NullableString']>;
};

export type EditCustomerIntakePrimaryKeys = {
  id: Scalars['Int'];
};

export type EditCustomerIntakesInput = {
  inputs: Array<EditCustomerIntakeInput>;
};

export type EditRoleAssociatedFields = {
  rolePermissions: Array<EditRoleWithRolePermissionsInput>;
};

export type EditRoleInput = {
  associatedFields: EditRoleAssociatedFields;
  primaryFields: EditRolePrimaryFields;
  primaryKeys: EditRolePrimaryKeys;
};

export type EditRolePrimaryFields = {
  name: Scalars['String'];
};

export type EditRolePrimaryKeys = {
  id: Scalars['Int'];
};

export type EditRoleWithRolePermissionsInput = {
  primaryFields: EditRoleWithRolePermissionsPrimaryFields;
  primaryKeys?: InputMaybe<EditRoleWithRolePermissionsPrimaryKeys>;
};

export type EditRoleWithRolePermissionsPrimaryFields = {
  permissionId: Scalars['Int'];
};

export type EditRoleWithRolePermissionsPrimaryKeys = {
  id: Scalars['Int'];
};

export type EditRolesInput = {
  inputs: Array<EditRoleInput>;
};

export type EditStationInput = {
  primaryFields: EditStationPrimaryFields;
  primaryKeys: EditStationPrimaryKeys;
};

export type EditStationPrimaryFields = {
  name: Scalars['String'];
};

export type EditStationPrimaryKeys = {
  id: Scalars['Int'];
};

export type EditStationsInput = {
  inputs: Array<EditStationInput>;
};

export type EditUserAssociatedFields = {
  userUserRoles: Array<EditUserWithUserUserRolesInput>;
};

export type EditUserInput = {
  associatedFields: EditUserAssociatedFields;
  primaryFields: EditUserPrimaryFields;
  primaryKeys: EditUserPrimaryKeys;
};

export type EditUserPrimaryFields = {
  deletedAt?: InputMaybe<Scalars['NullableDate']>;
  deletedBy?: InputMaybe<Scalars['NullableInt']>;
  firstName?: InputMaybe<Scalars['NullableString']>;
  isVerifier: Scalars['Boolean'];
  lastName?: InputMaybe<Scalars['NullableString']>;
  username: Scalars['String'];
};

export type EditUserPrimaryKeys = {
  id: Scalars['Int'];
};

export type EditUserWithUserUserRolesInput = {
  primaryFields: EditUserWithUserUserRolesPrimaryFields;
  primaryKeys?: InputMaybe<EditUserWithUserUserRolesPrimaryKeys>;
};

export type EditUserWithUserUserRolesPrimaryFields = {
  roleId: Scalars['Int'];
};

export type EditUserWithUserUserRolesPrimaryKeys = {
  id: Scalars['Int'];
};

export type EditUsersInput = {
  inputs: Array<EditUserInput>;
};

export enum FilterOperators {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  ILike = 'I_LIKE',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  Like = 'LIKE',
  NotEqual = 'NOT_EQUAL'
}

export type FloatFilter = {
  op: FilterOperators;
  val: Scalars['Float'];
};

export type GetCurrentUsersOutput = {
  __typename?: 'GetCurrentUsersOutput';
  count: Scalars['Int'];
  rows: Array<CurrentUser>;
};

export type GetCustomerIntakeLogsFilter = {
  and?: InputMaybe<Array<GetCustomerIntakeLogsFilter>>;
  fields?: InputMaybe<GetCustomerIntakeLogsFilterFields>;
  or?: InputMaybe<Array<GetCustomerIntakeLogsFilter>>;
};

export type GetCustomerIntakeLogsFilterFields = {
  customerIntakeId?: InputMaybe<IntFilter>;
};

export type GetCustomerIntakeLogsInput = {
  filter?: InputMaybe<GetCustomerIntakeLogsFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetCustomerIntakeLogsSortBy>>;
};

export type GetCustomerIntakeLogsOutput = {
  __typename?: 'GetCustomerIntakeLogsOutput';
  count: Scalars['Int'];
  rows: Array<CustomerIntakeLog>;
};

export type GetCustomerIntakeLogsSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetCustomerIntakeLogsSortByNames;
};

export enum GetCustomerIntakeLogsSortByNames {
  CreatedAt = 'createdAt'
}

export type GetCustomerIntakesCsvBase64Input = {
  businessName?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  maxDate?: InputMaybe<Scalars['String']>;
  maxTime?: InputMaybe<Scalars['String']>;
  minDate?: InputMaybe<Scalars['String']>;
  minTime?: InputMaybe<Scalars['String']>;
  repCodeUserId?: InputMaybe<Scalars['Int']>;
  stationId?: InputMaybe<Scalars['Int']>;
  verifiedByUserId?: InputMaybe<Scalars['Int']>;
};

export type GetCustomerIntakesFilter = {
  and?: InputMaybe<Array<GetCustomerIntakesFilter>>;
  fields?: InputMaybe<GetCustomerIntakesFilterFields>;
  or?: InputMaybe<Array<GetCustomerIntakesFilter>>;
};

export type GetCustomerIntakesFilterFields = {
  businessName?: InputMaybe<NullableStringFilter>;
  customerNumber?: InputMaybe<NullableStringFilter>;
  date?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<IntFilter>;
  repCodeUserId?: InputMaybe<NullableIntFilter>;
  stationId?: InputMaybe<NullableIntFilter>;
  time?: InputMaybe<NullableStringFilter>;
  verifiedByUserId?: InputMaybe<NullableIntFilter>;
};

export type GetCustomerIntakesInput = {
  filter?: InputMaybe<GetCustomerIntakesFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetCustomerIntakesSortBy>>;
};

export type GetCustomerIntakesOutput = {
  __typename?: 'GetCustomerIntakesOutput';
  count: Scalars['Int'];
  rows: Array<CustomerIntake>;
};

export type GetCustomerIntakesSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetCustomerIntakesSortByNames;
};

export enum GetCustomerIntakesSortByNames {
  BusinessName = 'businessName',
  CustomerNumber = 'customerNumber',
  Date = 'date',
  RepCodeUserFirstName = 'repCodeUser_firstName',
  RepCodeUserLastName = 'repCodeUser_lastName',
  StationName = 'station_name',
  Time = 'time',
  VerifiedByUserId = 'verifiedByUserId'
}

export type GetPermissionsFilter = {
  and?: InputMaybe<Array<GetPermissionsFilter>>;
  fields?: InputMaybe<GetPermissionsFilterFields>;
  or?: InputMaybe<Array<GetPermissionsFilter>>;
};

export type GetPermissionsFilterFields = {
  name?: InputMaybe<StringFilter>;
};

export type GetPermissionsInput = {
  filter?: InputMaybe<GetPermissionsFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetPermissionsSortBy>>;
};

export type GetPermissionsOutput = {
  __typename?: 'GetPermissionsOutput';
  count: Scalars['Int'];
  rows: Array<Permission>;
};

export type GetPermissionsSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetPermissionsSortByNames;
};

export enum GetPermissionsSortByNames {
  Name = 'name'
}

export type GetRolePermissionsFilter = {
  and?: InputMaybe<Array<GetRolePermissionsFilter>>;
  fields?: InputMaybe<GetRolePermissionsFilterFields>;
  or?: InputMaybe<Array<GetRolePermissionsFilter>>;
};

export type GetRolePermissionsFilterFields = {
  roleId?: InputMaybe<IntFilter>;
};

export type GetRolePermissionsInput = {
  filter?: InputMaybe<GetRolePermissionsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type GetRolePermissionsOutput = {
  __typename?: 'GetRolePermissionsOutput';
  count: Scalars['Int'];
  rows: Array<RolePermission>;
};

export type GetRolesFilter = {
  and?: InputMaybe<Array<GetRolesFilter>>;
  fields?: InputMaybe<GetRolesFilterFields>;
  or?: InputMaybe<Array<GetRolesFilter>>;
};

export type GetRolesFilterFields = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type GetRolesInput = {
  filter?: InputMaybe<GetRolesFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetRolesSortBy>>;
};

export type GetRolesOutput = {
  __typename?: 'GetRolesOutput';
  count: Scalars['Int'];
  rows: Array<Role>;
};

export type GetRolesSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetRolesSortByNames;
};

export enum GetRolesSortByNames {
  Id = 'id',
  Name = 'name'
}

export type GetStatesFilter = {
  and?: InputMaybe<Array<GetStatesFilter>>;
  fields?: InputMaybe<GetStatesFilterFields>;
  or?: InputMaybe<Array<GetStatesFilter>>;
};

export type GetStatesFilterFields = {
  name?: InputMaybe<StringFilter>;
};

export type GetStatesInput = {
  filter?: InputMaybe<GetStatesFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetStatesSortBy>>;
};

export type GetStatesOutput = {
  __typename?: 'GetStatesOutput';
  count: Scalars['Int'];
  rows: Array<State>;
};

export type GetStatesSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetStatesSortByNames;
};

export enum GetStatesSortByNames {
  Name = 'name'
}

export type GetStationsFilter = {
  and?: InputMaybe<Array<GetStationsFilter>>;
  fields?: InputMaybe<GetStationsFilterFields>;
  or?: InputMaybe<Array<GetStationsFilter>>;
};

export type GetStationsFilterFields = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type GetStationsInput = {
  filter?: InputMaybe<GetStationsFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetStationsSortBy>>;
};

export type GetStationsOutput = {
  __typename?: 'GetStationsOutput';
  count: Scalars['Int'];
  rows: Array<Station>;
};

export type GetStationsSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetStationsSortByNames;
};

export enum GetStationsSortByNames {
  Id = 'id',
  Name = 'name'
}

export type GetUserRolesOutput = {
  __typename?: 'GetUserRolesOutput';
  count: Scalars['Int'];
  rows: Array<UserRole>;
};

export type GetUsersByUserUserRolesByRoleByRolePermissionsByPermissionFilterFields = {
  id?: InputMaybe<IntFilter>;
};

export type GetUsersByUserUserRolesByRoleByRolePermissionsFilterFields = {
  permission?: InputMaybe<GetUsersByUserUserRolesByRoleByRolePermissionsByPermissionFilterFields>;
};

export type GetUsersByUserUserRolesByRoleFilterFields = {
  rolePermissions?: InputMaybe<GetUsersByUserUserRolesByRoleByRolePermissionsFilterFields>;
};

export type GetUsersByUserUserRolesFilterFields = {
  role?: InputMaybe<GetUsersByUserUserRolesByRoleFilterFields>;
};

export type GetUsersFilter = {
  and?: InputMaybe<Array<GetUsersFilter>>;
  fields?: InputMaybe<GetUsersFilterFields>;
  or?: InputMaybe<Array<GetUsersFilter>>;
};

export type GetUsersFilterFields = {
  deletedAt?: InputMaybe<NullableDateFilter>;
  deletedBy?: InputMaybe<NullableIntFilter>;
  firstName?: InputMaybe<NullableStringFilter>;
  id?: InputMaybe<IntFilter>;
  isVerifier?: InputMaybe<BooleanFilter>;
  lastName?: InputMaybe<NullableStringFilter>;
  userUserRoles?: InputMaybe<GetUsersByUserUserRolesFilterFields>;
  username?: InputMaybe<StringFilter>;
};

export type GetUsersInput = {
  filter?: InputMaybe<GetUsersFilter>;
  pagination?: InputMaybe<Pagination>;
  sortBys?: InputMaybe<Array<GetUsersSortBy>>;
};

export type GetUsersOutput = {
  __typename?: 'GetUsersOutput';
  count: Scalars['Int'];
  rows: Array<User>;
};

export type GetUsersSortBy = {
  isDesc?: InputMaybe<Scalars['Boolean']>;
  name: GetUsersSortByNames;
};

export enum GetUsersSortByNames {
  FirstName = 'firstName',
  Id = 'id',
  IsVerifier = 'isVerifier',
  LastName = 'lastName',
  Username = 'username'
}

export type IntFilter = {
  op: FilterOperators;
  val: Scalars['Int'];
};

export type JsonFieldSortBy = {
  index: Scalars['Int'];
  isDesc?: InputMaybe<Scalars['Boolean']>;
  jsonFieldName: Scalars['String'];
  sortByName: Scalars['String'];
};

export type JsonFilter = {
  name: Scalars['String'];
  op: FilterOperators;
  val: Scalars['String'];
};

export type LoginInput = {
  isClear: Scalars['Boolean'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  token: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCustomerIntakeLogs: Scalars['Int'];
  addCustomerIntakes: Scalars['Int'];
  addRoles: AddRolesOutput;
  addStations: AddStationsOutput;
  addUsers: AddUsersOutput;
  editCustomerIntakes: Scalars['Int'];
  editRoles: Scalars['Int'];
  editStations: Scalars['Int'];
  editUsers: Scalars['Int'];
  login: LoginOutput;
  removeCustomerIntakes: Scalars['Int'];
  removeRoles: Scalars['Int'];
  removeStations: Scalars['Int'];
  resetUserPassword: Scalars['Int'];
};


export type MutationAddCustomerIntakeLogsArgs = {
  input: AddCustomerIntakeLogsInput;
};


export type MutationAddCustomerIntakesArgs = {
  input: AddCustomerIntakesInput;
};


export type MutationAddRolesArgs = {
  input: AddRolesInput;
};


export type MutationAddStationsArgs = {
  input: AddStationsInput;
};


export type MutationAddUsersArgs = {
  input: AddUsersInput;
};


export type MutationEditCustomerIntakesArgs = {
  input: EditCustomerIntakesInput;
};


export type MutationEditRolesArgs = {
  input: EditRolesInput;
};


export type MutationEditStationsArgs = {
  input: EditStationsInput;
};


export type MutationEditUsersArgs = {
  input: EditUsersInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRemoveCustomerIntakesArgs = {
  input: RemoveCustomerIntakesInput;
};


export type MutationRemoveRolesArgs = {
  input: RemoveRolesInput;
};


export type MutationRemoveStationsArgs = {
  input: RemoveStationsInput;
};


export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type NullableBooleanFilter = {
  op: FilterOperators;
  val?: InputMaybe<Scalars['NullableBoolean']>;
};

export type NullableDateFilter = {
  op: FilterOperators;
  val?: InputMaybe<Scalars['NullableDate']>;
};

export type NullableFloatFilter = {
  op: FilterOperators;
  val?: InputMaybe<Scalars['NullableFloat']>;
};

export type NullableIntFilter = {
  op: FilterOperators;
  val?: InputMaybe<Scalars['NullableInt']>;
};

export type NullableStringFilter = {
  op: FilterOperators;
  val?: InputMaybe<Scalars['NullableString']>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Permission = {
  __typename?: 'Permission';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum PermissionCodes {
  Settings = 'settings'
}

export type Query = {
  __typename?: 'Query';
  getCurrentUsers: GetCurrentUsersOutput;
  getCustomerIntakeLogs: GetCustomerIntakeLogsOutput;
  getCustomerIntakes: GetCustomerIntakesOutput;
  getCustomerIntakesCsvBase64: Scalars['String'];
  getPermissions: GetPermissionsOutput;
  getRolePermissions: GetRolePermissionsOutput;
  getRoles: GetRolesOutput;
  getStates: GetStatesOutput;
  getStations: GetStationsOutput;
  getUsers: GetUsersOutput;
};


export type QueryGetCustomerIntakeLogsArgs = {
  input?: InputMaybe<GetCustomerIntakeLogsInput>;
};


export type QueryGetCustomerIntakesArgs = {
  input?: InputMaybe<GetCustomerIntakesInput>;
};


export type QueryGetCustomerIntakesCsvBase64Args = {
  input?: InputMaybe<GetCustomerIntakesCsvBase64Input>;
};


export type QueryGetPermissionsArgs = {
  input?: InputMaybe<GetPermissionsInput>;
};


export type QueryGetRolePermissionsArgs = {
  input?: InputMaybe<GetRolePermissionsInput>;
};


export type QueryGetRolesArgs = {
  input?: InputMaybe<GetRolesInput>;
};


export type QueryGetStatesArgs = {
  input?: InputMaybe<GetStatesInput>;
};


export type QueryGetStationsArgs = {
  input?: InputMaybe<GetStationsInput>;
};


export type QueryGetUsersArgs = {
  input?: InputMaybe<GetUsersInput>;
};

export type RemoveCustomerIntakesInput = {
  primaryKeysList: Array<RemoveCustomerIntakesPrimaryKeys>;
};

export type RemoveCustomerIntakesPrimaryKeys = {
  id: Scalars['Int'];
};

export type RemoveRolesInput = {
  primaryKeysList: Array<RemoveRolesPrimaryKeys>;
};

export type RemoveRolesPrimaryKeys = {
  id: Scalars['Int'];
};

export type RemoveStationsInput = {
  primaryKeysList: Array<RemoveStationsPrimaryKeys>;
};

export type RemoveStationsPrimaryKeys = {
  id: Scalars['Int'];
};

export type ResetUserPasswordInput = {
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
  userId: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  rolePermissions: Array<RolePermission>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id: Scalars['Int'];
  permission: Permission;
  role: Role;
};

export type State = {
  __typename?: 'State';
  abbreviation: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Station = {
  __typename?: 'Station';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type StringFilter = {
  op: FilterOperators;
  val: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  firstName?: Maybe<Scalars['NullableString']>;
  id: Scalars['Int'];
  isVerifier: Scalars['Boolean'];
  lastName?: Maybe<Scalars['NullableString']>;
  userUserRoles: Array<UserRole>;
  username: Scalars['String'];
};

export type UserRole = {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  role: Role;
};

export type CurrentUserFragment = { __typename?: 'CurrentUser', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, userUserRoles: Array<{ __typename?: 'UserRole', id: number, role: { __typename?: 'Role', id: number, code: string, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', code: string } }> } }> };

export type UserFragment = { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, isVerifier: boolean, userUserRoles: Array<{ __typename?: 'UserRole', id: number, role: { __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> } }> };

export type UserListItemFragment = { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, isVerifier: boolean };

export type PermissionFragment = { __typename?: 'Permission', id: number, name: string };

export type PermissionListItemFragment = { __typename?: 'Permission', id: number, name: string };

export type RoleFragment = { __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> };

export type RoleListItemFragment = { __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> };

export type UserRoleListItemFragment = { __typename?: 'UserRole', id: number, role: { __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> } };

export type RolePermissionPermissionFragment = { __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } };

export type StateListItemFragment = { __typename?: 'State', id: number, name: string };

export type StationFragment = { __typename?: 'Station', id: number, name: string };

export type StationListItemFragment = { __typename?: 'Station', id: number, name: string };

export type CustomerIntakeFragment = { __typename?: 'CustomerIntake', id: number, invoiceNumber?: string | null | null, csNumber?: string | null | null, customerNumber?: string | null | null, businessName?: string | null | null, address?: string | null | null, city?: string | null | null, zip?: string | null | null, authorizer?: string | null | null, title?: string | null | null, phone?: string | null | null, phone2?: string | null | null, fax?: string | null | null, web?: string | null | null, campaignRepresentative?: string | null | null, communityCampaign?: string | null | null, announcements?: number | null | null, numberOfPayments?: number | null | null, time?: string | null | null, date?: string | null | null, email?: string | null | null, price?: string | null | null, pricePerSpot?: string | null | null, lastPurchaseDate?: string | null | null, station?: { __typename?: 'Station', id: number, name: string } | null, state?: { __typename?: 'State', id: number, name: string } | null, repCodeUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, verifiedByUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null };

export type CustomerIntakeListItemFragment = { __typename?: 'CustomerIntake', id: number, invoiceNumber?: string | null | null, csNumber?: string | null | null, customerNumber?: string | null | null, businessName?: string | null | null, time?: string | null | null, date?: string | null | null, email?: string | null | null, price?: string | null | null, pricePerSpot?: string | null | null, lastPurchaseDate?: string | null | null, repCodeUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, verifiedByUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, station?: { __typename?: 'Station', id: number, name: string } | null };

export type CsvCustomerIntakeFragment = { __typename?: 'CustomerIntake', id: number, customerNumber?: string | null | null, businessName?: string | null | null, address?: string | null | null, city?: string | null | null, zip?: string | null | null, phone?: string | null | null, phone2?: string | null | null, fax?: string | null | null, web?: string | null | null, campaignRepresentative?: string | null | null, email?: string | null | null, price?: string | null | null, pricePerSpot?: string | null | null, lastPurchaseDate?: string | null | null, station?: { __typename?: 'Station', id: number, name: string } | null, state?: { __typename?: 'State', id: number, name: string, abbreviation: string } | null, repCodeUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null };

export type CustomerIntakeLogFragment = { __typename?: 'CustomerIntakeLog', id: number, message?: string | null | null };

export type CustomerIntakeLogListItemFragment = { __typename?: 'CustomerIntakeLog', id: number, createdAt: Date, message?: string | null | null, createdByUser?: { __typename?: 'User', id: number, firstName?: string | null | null, lastName?: string | null | null } | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginOutput', token: string } };

export type AddUsersMutationVariables = Exact<{
  input: AddUsersInput;
}>;


export type AddUsersMutation = { __typename?: 'Mutation', addUsers: { __typename?: 'AddUsersOutput', rows: Array<{ __typename?: 'AddedUser', id: number }> } };

export type EditUsersMutationVariables = Exact<{
  input: EditUsersInput;
}>;


export type EditUsersMutation = { __typename?: 'Mutation', editUsers: number };

export type ResetUserPasswordMutationVariables = Exact<{
  input: ResetUserPasswordInput;
}>;


export type ResetUserPasswordMutation = { __typename?: 'Mutation', resetUserPassword: number };

export type AddRolesMutationVariables = Exact<{
  input: AddRolesInput;
}>;


export type AddRolesMutation = { __typename?: 'Mutation', addRoles: { __typename?: 'AddRolesOutput', rows: Array<{ __typename?: 'AddedRole', id: number }> } };

export type EditRolesMutationVariables = Exact<{
  input: EditRolesInput;
}>;


export type EditRolesMutation = { __typename?: 'Mutation', editRoles: number };

export type RemoveRolesMutationVariables = Exact<{
  input: RemoveRolesInput;
}>;


export type RemoveRolesMutation = { __typename?: 'Mutation', removeRoles: number };

export type AddStationsMutationVariables = Exact<{
  input: AddStationsInput;
}>;


export type AddStationsMutation = { __typename?: 'Mutation', addStations: { __typename?: 'AddStationsOutput', rows: Array<{ __typename?: 'AddedStation', id: number }> } };

export type EditStationsMutationVariables = Exact<{
  input: EditStationsInput;
}>;


export type EditStationsMutation = { __typename?: 'Mutation', editStations: number };

export type RemoveStationsMutationVariables = Exact<{
  input: RemoveStationsInput;
}>;


export type RemoveStationsMutation = { __typename?: 'Mutation', removeStations: number };

export type AddCustomerIntakesMutationVariables = Exact<{
  input: AddCustomerIntakesInput;
}>;


export type AddCustomerIntakesMutation = { __typename?: 'Mutation', addCustomerIntakes: number };

export type EditCustomerIntakesMutationVariables = Exact<{
  input: EditCustomerIntakesInput;
}>;


export type EditCustomerIntakesMutation = { __typename?: 'Mutation', editCustomerIntakes: number };

export type RemoveCustomerIntakesMutationVariables = Exact<{
  input: RemoveCustomerIntakesInput;
}>;


export type RemoveCustomerIntakesMutation = { __typename?: 'Mutation', removeCustomerIntakes: number };

export type AddCustomerIntakeLogsMutationVariables = Exact<{
  input: AddCustomerIntakeLogsInput;
}>;


export type AddCustomerIntakeLogsMutation = { __typename?: 'Mutation', addCustomerIntakeLogs: number };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUsers: { __typename?: 'GetCurrentUsersOutput', rows: Array<{ __typename?: 'CurrentUser', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, userUserRoles: Array<{ __typename?: 'UserRole', id: number, role: { __typename?: 'Role', id: number, code: string, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', code: string } }> } }> }> } };

export type GetUsersQueryVariables = Exact<{
  input?: InputMaybe<GetUsersInput>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'GetUsersOutput', count: number, rows: Array<{ __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, isVerifier: boolean }> } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUsers: { __typename?: 'GetUsersOutput', count: number, rows: Array<{ __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null, isVerifier: boolean, userUserRoles: Array<{ __typename?: 'UserRole', id: number, role: { __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> } }> }> } };

export type GetPermissionsQueryVariables = Exact<{
  input?: InputMaybe<GetPermissionsInput>;
}>;


export type GetPermissionsQuery = { __typename?: 'Query', getPermissions: { __typename?: 'GetPermissionsOutput', count: number, rows: Array<{ __typename?: 'Permission', id: number, name: string }> } };

export type GetRolesQueryVariables = Exact<{
  input?: InputMaybe<GetRolesInput>;
}>;


export type GetRolesQuery = { __typename?: 'Query', getRoles: { __typename?: 'GetRolesOutput', count: number, rows: Array<{ __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> }> } };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRoleQuery = { __typename?: 'Query', getRoles: { __typename?: 'GetRolesOutput', count: number, rows: Array<{ __typename?: 'Role', id: number, name: string, rolePermissions: Array<{ __typename?: 'RolePermission', id: number, permission: { __typename?: 'Permission', id: number, name: string } }> }> } };

export type GetStatesQueryVariables = Exact<{
  input?: InputMaybe<GetStatesInput>;
}>;


export type GetStatesQuery = { __typename?: 'Query', getStates: { __typename?: 'GetStatesOutput', count: number, rows: Array<{ __typename?: 'State', id: number, name: string }> } };

export type GetStationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetStationQuery = { __typename?: 'Query', getStations: { __typename?: 'GetStationsOutput', count: number, rows: Array<{ __typename?: 'Station', id: number, name: string }> } };

export type GetStationsQueryVariables = Exact<{
  input?: InputMaybe<GetStationsInput>;
}>;


export type GetStationsQuery = { __typename?: 'Query', getStations: { __typename?: 'GetStationsOutput', count: number, rows: Array<{ __typename?: 'Station', id: number, name: string }> } };

export type GetCustomerIntakesQueryVariables = Exact<{
  input?: InputMaybe<GetCustomerIntakesInput>;
}>;


export type GetCustomerIntakesQuery = { __typename?: 'Query', getCustomerIntakes: { __typename?: 'GetCustomerIntakesOutput', count: number, rows: Array<{ __typename?: 'CustomerIntake', id: number, invoiceNumber?: string | null | null, csNumber?: string | null | null, customerNumber?: string | null | null, businessName?: string | null | null, time?: string | null | null, date?: string | null | null, email?: string | null | null, price?: string | null | null, pricePerSpot?: string | null | null, lastPurchaseDate?: string | null | null, repCodeUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, verifiedByUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, station?: { __typename?: 'Station', id: number, name: string } | null }> } };

export type GetCustomerIntakeQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetCustomerIntakeQuery = { __typename?: 'Query', getCustomerIntakes: { __typename?: 'GetCustomerIntakesOutput', count: number, rows: Array<{ __typename?: 'CustomerIntake', id: number, invoiceNumber?: string | null | null, csNumber?: string | null | null, customerNumber?: string | null | null, businessName?: string | null | null, address?: string | null | null, city?: string | null | null, zip?: string | null | null, authorizer?: string | null | null, title?: string | null | null, phone?: string | null | null, phone2?: string | null | null, fax?: string | null | null, web?: string | null | null, campaignRepresentative?: string | null | null, communityCampaign?: string | null | null, announcements?: number | null | null, numberOfPayments?: number | null | null, time?: string | null | null, date?: string | null | null, email?: string | null | null, price?: string | null | null, pricePerSpot?: string | null | null, lastPurchaseDate?: string | null | null, station?: { __typename?: 'Station', id: number, name: string } | null, state?: { __typename?: 'State', id: number, name: string } | null, repCodeUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null, verifiedByUser?: { __typename?: 'User', id: number, username: string, firstName?: string | null | null, lastName?: string | null | null } | null }> } };

export type GetCustomerIntakesCsvBase64QueryVariables = Exact<{
  input?: InputMaybe<GetCustomerIntakesCsvBase64Input>;
}>;


export type GetCustomerIntakesCsvBase64Query = { __typename?: 'Query', getCustomerIntakesCsvBase64: string };

export type GetCustomerIntakeLogsQueryVariables = Exact<{
  input?: InputMaybe<GetCustomerIntakeLogsInput>;
}>;


export type GetCustomerIntakeLogsQuery = { __typename?: 'Query', getCustomerIntakeLogs: { __typename?: 'GetCustomerIntakeLogsOutput', count: number, rows: Array<{ __typename?: 'CustomerIntakeLog', id: number, createdAt: Date, message?: string | null | null, createdByUser?: { __typename?: 'User', id: number, firstName?: string | null | null, lastName?: string | null | null } | null }> } };

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on CurrentUser {
  id
  username
  firstName
  lastName
  userUserRoles {
    id
    role {
      id
      code
      name
      rolePermissions {
        id
        permission {
          code
        }
      }
    }
  }
}
    `;
export const PermissionFragmentDoc = gql`
    fragment Permission on Permission {
  id
  name
}
    `;
export const RolePermissionPermissionFragmentDoc = gql`
    fragment RolePermissionPermission on RolePermission {
  id
  permission {
    ...Permission
  }
}
    ${PermissionFragmentDoc}`;
export const RoleListItemFragmentDoc = gql`
    fragment RoleListItem on Role {
  id
  name
  rolePermissions {
    ...RolePermissionPermission
  }
}
    ${RolePermissionPermissionFragmentDoc}`;
export const UserRoleListItemFragmentDoc = gql`
    fragment UserRoleListItem on UserRole {
  id
  role {
    ...RoleListItem
  }
}
    ${RoleListItemFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  username
  firstName
  lastName
  isVerifier
  userUserRoles {
    ...UserRoleListItem
  }
}
    ${UserRoleListItemFragmentDoc}`;
export const UserListItemFragmentDoc = gql`
    fragment UserListItem on User {
  id
  username
  firstName
  lastName
  isVerifier
}
    `;
export const PermissionListItemFragmentDoc = gql`
    fragment PermissionListItem on Permission {
  id
  name
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  name
  rolePermissions {
    ...RolePermissionPermission
  }
}
    ${RolePermissionPermissionFragmentDoc}`;
export const StateListItemFragmentDoc = gql`
    fragment StateListItem on State {
  id
  name
}
    `;
export const StationFragmentDoc = gql`
    fragment Station on Station {
  id
  name
}
    `;
export const StationListItemFragmentDoc = gql`
    fragment StationListItem on Station {
  id
  name
}
    `;
export const CustomerIntakeFragmentDoc = gql`
    fragment CustomerIntake on CustomerIntake {
  id
  invoiceNumber
  csNumber
  customerNumber
  businessName
  address
  city
  zip
  authorizer
  title
  phone
  phone2
  fax
  web
  campaignRepresentative
  communityCampaign
  announcements
  numberOfPayments
  time
  date
  email
  price
  pricePerSpot
  lastPurchaseDate
  station {
    id
    name
  }
  state {
    id
    name
  }
  repCodeUser {
    id
    username
    firstName
    lastName
  }
  verifiedByUser {
    id
    username
    firstName
    lastName
  }
}
    `;
export const CustomerIntakeListItemFragmentDoc = gql`
    fragment CustomerIntakeListItem on CustomerIntake {
  id
  invoiceNumber
  csNumber
  customerNumber
  businessName
  time
  date
  email
  price
  pricePerSpot
  lastPurchaseDate
  repCodeUser {
    id
    username
    firstName
    lastName
  }
  verifiedByUser {
    id
    username
    firstName
    lastName
  }
  station {
    id
    name
  }
}
    `;
export const CsvCustomerIntakeFragmentDoc = gql`
    fragment CsvCustomerIntake on CustomerIntake {
  id
  customerNumber
  businessName
  address
  city
  zip
  phone
  phone2
  fax
  web
  campaignRepresentative
  email
  price
  pricePerSpot
  lastPurchaseDate
  station {
    id
    name
  }
  state {
    id
    name
    abbreviation
  }
  repCodeUser {
    id
    username
    firstName
    lastName
  }
}
    `;
export const CustomerIntakeLogFragmentDoc = gql`
    fragment CustomerIntakeLog on CustomerIntakeLog {
  id
  message
}
    `;
export const CustomerIntakeLogListItemFragmentDoc = gql`
    fragment CustomerIntakeLogListItem on CustomerIntakeLog {
  id
  createdAt
  message
  createdByUser {
    id
    firstName
    lastName
  }
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AddUsersDocument = gql`
    mutation addUsers($input: AddUsersInput!) {
  addUsers(input: $input) {
    rows {
      id
    }
  }
}
    `;
export type AddUsersMutationFn = Apollo.MutationFunction<AddUsersMutation, AddUsersMutationVariables>;

/**
 * __useAddUsersMutation__
 *
 * To run a mutation, you first call `useAddUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersMutation, { data, loading, error }] = useAddUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUsersMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersMutation, AddUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersMutation, AddUsersMutationVariables>(AddUsersDocument, options);
      }
export type AddUsersMutationHookResult = ReturnType<typeof useAddUsersMutation>;
export type AddUsersMutationResult = Apollo.MutationResult<AddUsersMutation>;
export type AddUsersMutationOptions = Apollo.BaseMutationOptions<AddUsersMutation, AddUsersMutationVariables>;
export const EditUsersDocument = gql`
    mutation editUsers($input: EditUsersInput!) {
  editUsers(input: $input)
}
    `;
export type EditUsersMutationFn = Apollo.MutationFunction<EditUsersMutation, EditUsersMutationVariables>;

/**
 * __useEditUsersMutation__
 *
 * To run a mutation, you first call `useEditUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUsersMutation, { data, loading, error }] = useEditUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUsersMutation(baseOptions?: Apollo.MutationHookOptions<EditUsersMutation, EditUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUsersMutation, EditUsersMutationVariables>(EditUsersDocument, options);
      }
export type EditUsersMutationHookResult = ReturnType<typeof useEditUsersMutation>;
export type EditUsersMutationResult = Apollo.MutationResult<EditUsersMutation>;
export type EditUsersMutationOptions = Apollo.BaseMutationOptions<EditUsersMutation, EditUsersMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation resetUserPassword($input: ResetUserPasswordInput!) {
  resetUserPassword(input: $input)
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, options);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const AddRolesDocument = gql`
    mutation addRoles($input: AddRolesInput!) {
  addRoles(input: $input) {
    rows {
      id
    }
  }
}
    `;
export type AddRolesMutationFn = Apollo.MutationFunction<AddRolesMutation, AddRolesMutationVariables>;

/**
 * __useAddRolesMutation__
 *
 * To run a mutation, you first call `useAddRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRolesMutation, { data, loading, error }] = useAddRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRolesMutation(baseOptions?: Apollo.MutationHookOptions<AddRolesMutation, AddRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRolesMutation, AddRolesMutationVariables>(AddRolesDocument, options);
      }
export type AddRolesMutationHookResult = ReturnType<typeof useAddRolesMutation>;
export type AddRolesMutationResult = Apollo.MutationResult<AddRolesMutation>;
export type AddRolesMutationOptions = Apollo.BaseMutationOptions<AddRolesMutation, AddRolesMutationVariables>;
export const EditRolesDocument = gql`
    mutation editRoles($input: EditRolesInput!) {
  editRoles(input: $input)
}
    `;
export type EditRolesMutationFn = Apollo.MutationFunction<EditRolesMutation, EditRolesMutationVariables>;

/**
 * __useEditRolesMutation__
 *
 * To run a mutation, you first call `useEditRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRolesMutation, { data, loading, error }] = useEditRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRolesMutation(baseOptions?: Apollo.MutationHookOptions<EditRolesMutation, EditRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRolesMutation, EditRolesMutationVariables>(EditRolesDocument, options);
      }
export type EditRolesMutationHookResult = ReturnType<typeof useEditRolesMutation>;
export type EditRolesMutationResult = Apollo.MutationResult<EditRolesMutation>;
export type EditRolesMutationOptions = Apollo.BaseMutationOptions<EditRolesMutation, EditRolesMutationVariables>;
export const RemoveRolesDocument = gql`
    mutation removeRoles($input: RemoveRolesInput!) {
  removeRoles(input: $input)
}
    `;
export type RemoveRolesMutationFn = Apollo.MutationFunction<RemoveRolesMutation, RemoveRolesMutationVariables>;

/**
 * __useRemoveRolesMutation__
 *
 * To run a mutation, you first call `useRemoveRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRolesMutation, { data, loading, error }] = useRemoveRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRolesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRolesMutation, RemoveRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRolesMutation, RemoveRolesMutationVariables>(RemoveRolesDocument, options);
      }
export type RemoveRolesMutationHookResult = ReturnType<typeof useRemoveRolesMutation>;
export type RemoveRolesMutationResult = Apollo.MutationResult<RemoveRolesMutation>;
export type RemoveRolesMutationOptions = Apollo.BaseMutationOptions<RemoveRolesMutation, RemoveRolesMutationVariables>;
export const AddStationsDocument = gql`
    mutation addStations($input: AddStationsInput!) {
  addStations(input: $input) {
    rows {
      id
    }
  }
}
    `;
export type AddStationsMutationFn = Apollo.MutationFunction<AddStationsMutation, AddStationsMutationVariables>;

/**
 * __useAddStationsMutation__
 *
 * To run a mutation, you first call `useAddStationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStationsMutation, { data, loading, error }] = useAddStationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStationsMutation(baseOptions?: Apollo.MutationHookOptions<AddStationsMutation, AddStationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStationsMutation, AddStationsMutationVariables>(AddStationsDocument, options);
      }
export type AddStationsMutationHookResult = ReturnType<typeof useAddStationsMutation>;
export type AddStationsMutationResult = Apollo.MutationResult<AddStationsMutation>;
export type AddStationsMutationOptions = Apollo.BaseMutationOptions<AddStationsMutation, AddStationsMutationVariables>;
export const EditStationsDocument = gql`
    mutation editStations($input: EditStationsInput!) {
  editStations(input: $input)
}
    `;
export type EditStationsMutationFn = Apollo.MutationFunction<EditStationsMutation, EditStationsMutationVariables>;

/**
 * __useEditStationsMutation__
 *
 * To run a mutation, you first call `useEditStationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStationsMutation, { data, loading, error }] = useEditStationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditStationsMutation(baseOptions?: Apollo.MutationHookOptions<EditStationsMutation, EditStationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStationsMutation, EditStationsMutationVariables>(EditStationsDocument, options);
      }
export type EditStationsMutationHookResult = ReturnType<typeof useEditStationsMutation>;
export type EditStationsMutationResult = Apollo.MutationResult<EditStationsMutation>;
export type EditStationsMutationOptions = Apollo.BaseMutationOptions<EditStationsMutation, EditStationsMutationVariables>;
export const RemoveStationsDocument = gql`
    mutation removeStations($input: RemoveStationsInput!) {
  removeStations(input: $input)
}
    `;
export type RemoveStationsMutationFn = Apollo.MutationFunction<RemoveStationsMutation, RemoveStationsMutationVariables>;

/**
 * __useRemoveStationsMutation__
 *
 * To run a mutation, you first call `useRemoveStationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStationsMutation, { data, loading, error }] = useRemoveStationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStationsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStationsMutation, RemoveStationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStationsMutation, RemoveStationsMutationVariables>(RemoveStationsDocument, options);
      }
export type RemoveStationsMutationHookResult = ReturnType<typeof useRemoveStationsMutation>;
export type RemoveStationsMutationResult = Apollo.MutationResult<RemoveStationsMutation>;
export type RemoveStationsMutationOptions = Apollo.BaseMutationOptions<RemoveStationsMutation, RemoveStationsMutationVariables>;
export const AddCustomerIntakesDocument = gql`
    mutation addCustomerIntakes($input: AddCustomerIntakesInput!) {
  addCustomerIntakes(input: $input)
}
    `;
export type AddCustomerIntakesMutationFn = Apollo.MutationFunction<AddCustomerIntakesMutation, AddCustomerIntakesMutationVariables>;

/**
 * __useAddCustomerIntakesMutation__
 *
 * To run a mutation, you first call `useAddCustomerIntakesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerIntakesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerIntakesMutation, { data, loading, error }] = useAddCustomerIntakesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerIntakesMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerIntakesMutation, AddCustomerIntakesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerIntakesMutation, AddCustomerIntakesMutationVariables>(AddCustomerIntakesDocument, options);
      }
export type AddCustomerIntakesMutationHookResult = ReturnType<typeof useAddCustomerIntakesMutation>;
export type AddCustomerIntakesMutationResult = Apollo.MutationResult<AddCustomerIntakesMutation>;
export type AddCustomerIntakesMutationOptions = Apollo.BaseMutationOptions<AddCustomerIntakesMutation, AddCustomerIntakesMutationVariables>;
export const EditCustomerIntakesDocument = gql`
    mutation editCustomerIntakes($input: EditCustomerIntakesInput!) {
  editCustomerIntakes(input: $input)
}
    `;
export type EditCustomerIntakesMutationFn = Apollo.MutationFunction<EditCustomerIntakesMutation, EditCustomerIntakesMutationVariables>;

/**
 * __useEditCustomerIntakesMutation__
 *
 * To run a mutation, you first call `useEditCustomerIntakesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerIntakesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerIntakesMutation, { data, loading, error }] = useEditCustomerIntakesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomerIntakesMutation(baseOptions?: Apollo.MutationHookOptions<EditCustomerIntakesMutation, EditCustomerIntakesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCustomerIntakesMutation, EditCustomerIntakesMutationVariables>(EditCustomerIntakesDocument, options);
      }
export type EditCustomerIntakesMutationHookResult = ReturnType<typeof useEditCustomerIntakesMutation>;
export type EditCustomerIntakesMutationResult = Apollo.MutationResult<EditCustomerIntakesMutation>;
export type EditCustomerIntakesMutationOptions = Apollo.BaseMutationOptions<EditCustomerIntakesMutation, EditCustomerIntakesMutationVariables>;
export const RemoveCustomerIntakesDocument = gql`
    mutation removeCustomerIntakes($input: RemoveCustomerIntakesInput!) {
  removeCustomerIntakes(input: $input)
}
    `;
export type RemoveCustomerIntakesMutationFn = Apollo.MutationFunction<RemoveCustomerIntakesMutation, RemoveCustomerIntakesMutationVariables>;

/**
 * __useRemoveCustomerIntakesMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerIntakesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerIntakesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerIntakesMutation, { data, loading, error }] = useRemoveCustomerIntakesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCustomerIntakesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomerIntakesMutation, RemoveCustomerIntakesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomerIntakesMutation, RemoveCustomerIntakesMutationVariables>(RemoveCustomerIntakesDocument, options);
      }
export type RemoveCustomerIntakesMutationHookResult = ReturnType<typeof useRemoveCustomerIntakesMutation>;
export type RemoveCustomerIntakesMutationResult = Apollo.MutationResult<RemoveCustomerIntakesMutation>;
export type RemoveCustomerIntakesMutationOptions = Apollo.BaseMutationOptions<RemoveCustomerIntakesMutation, RemoveCustomerIntakesMutationVariables>;
export const AddCustomerIntakeLogsDocument = gql`
    mutation addCustomerIntakeLogs($input: AddCustomerIntakeLogsInput!) {
  addCustomerIntakeLogs(input: $input)
}
    `;
export type AddCustomerIntakeLogsMutationFn = Apollo.MutationFunction<AddCustomerIntakeLogsMutation, AddCustomerIntakeLogsMutationVariables>;

/**
 * __useAddCustomerIntakeLogsMutation__
 *
 * To run a mutation, you first call `useAddCustomerIntakeLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerIntakeLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerIntakeLogsMutation, { data, loading, error }] = useAddCustomerIntakeLogsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerIntakeLogsMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerIntakeLogsMutation, AddCustomerIntakeLogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerIntakeLogsMutation, AddCustomerIntakeLogsMutationVariables>(AddCustomerIntakeLogsDocument, options);
      }
export type AddCustomerIntakeLogsMutationHookResult = ReturnType<typeof useAddCustomerIntakeLogsMutation>;
export type AddCustomerIntakeLogsMutationResult = Apollo.MutationResult<AddCustomerIntakeLogsMutation>;
export type AddCustomerIntakeLogsMutationOptions = Apollo.BaseMutationOptions<AddCustomerIntakeLogsMutation, AddCustomerIntakeLogsMutationVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  getCurrentUsers {
    rows {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($input: GetUsersInput) {
  getUsers(input: $input) {
    count
    rows {
      ...UserListItem
    }
  }
}
    ${UserListItemFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: Int!) {
  getUsers(input: {filter: {fields: {id: {val: $id, op: EQUAL}}}}) {
    count
    rows {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetPermissionsDocument = gql`
    query getPermissions($input: GetPermissionsInput) {
  getPermissions(input: $input) {
    count
    rows {
      ...PermissionListItem
    }
  }
}
    ${PermissionListItemFragmentDoc}`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles($input: GetRolesInput) {
  getRoles(input: $input) {
    count
    rows {
      ...RoleListItem
    }
  }
}
    ${RoleListItemFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRoleDocument = gql`
    query getRole($id: Int!) {
  getRoles(input: {filter: {fields: {id: {val: $id, op: EQUAL}}}}) {
    count
    rows {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetStatesDocument = gql`
    query getStates($input: GetStatesInput) {
  getStates(input: $input) {
    count
    rows {
      ...StateListItem
    }
  }
}
    ${StateListItemFragmentDoc}`;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const GetStationDocument = gql`
    query getStation($id: Int!) {
  getStations(input: {filter: {fields: {id: {val: $id, op: EQUAL}}}}) {
    count
    rows {
      ...Station
    }
  }
}
    ${StationFragmentDoc}`;

/**
 * __useGetStationQuery__
 *
 * To run a query within a React component, call `useGetStationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStationQuery(baseOptions: Apollo.QueryHookOptions<GetStationQuery, GetStationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStationQuery, GetStationQueryVariables>(GetStationDocument, options);
      }
export function useGetStationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStationQuery, GetStationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStationQuery, GetStationQueryVariables>(GetStationDocument, options);
        }
export type GetStationQueryHookResult = ReturnType<typeof useGetStationQuery>;
export type GetStationLazyQueryHookResult = ReturnType<typeof useGetStationLazyQuery>;
export type GetStationQueryResult = Apollo.QueryResult<GetStationQuery, GetStationQueryVariables>;
export const GetStationsDocument = gql`
    query getStations($input: GetStationsInput) {
  getStations(input: $input) {
    count
    rows {
      ...StationListItem
    }
  }
}
    ${StationListItemFragmentDoc}`;

/**
 * __useGetStationsQuery__
 *
 * To run a query within a React component, call `useGetStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStationsQuery(baseOptions?: Apollo.QueryHookOptions<GetStationsQuery, GetStationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStationsQuery, GetStationsQueryVariables>(GetStationsDocument, options);
      }
export function useGetStationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStationsQuery, GetStationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStationsQuery, GetStationsQueryVariables>(GetStationsDocument, options);
        }
export type GetStationsQueryHookResult = ReturnType<typeof useGetStationsQuery>;
export type GetStationsLazyQueryHookResult = ReturnType<typeof useGetStationsLazyQuery>;
export type GetStationsQueryResult = Apollo.QueryResult<GetStationsQuery, GetStationsQueryVariables>;
export const GetCustomerIntakesDocument = gql`
    query getCustomerIntakes($input: GetCustomerIntakesInput) {
  getCustomerIntakes(input: $input) {
    count
    rows {
      ...CustomerIntakeListItem
    }
  }
}
    ${CustomerIntakeListItemFragmentDoc}`;

/**
 * __useGetCustomerIntakesQuery__
 *
 * To run a query within a React component, call `useGetCustomerIntakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIntakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIntakesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerIntakesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerIntakesQuery, GetCustomerIntakesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIntakesQuery, GetCustomerIntakesQueryVariables>(GetCustomerIntakesDocument, options);
      }
export function useGetCustomerIntakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIntakesQuery, GetCustomerIntakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIntakesQuery, GetCustomerIntakesQueryVariables>(GetCustomerIntakesDocument, options);
        }
export type GetCustomerIntakesQueryHookResult = ReturnType<typeof useGetCustomerIntakesQuery>;
export type GetCustomerIntakesLazyQueryHookResult = ReturnType<typeof useGetCustomerIntakesLazyQuery>;
export type GetCustomerIntakesQueryResult = Apollo.QueryResult<GetCustomerIntakesQuery, GetCustomerIntakesQueryVariables>;
export const GetCustomerIntakeDocument = gql`
    query getCustomerIntake($id: Int!) {
  getCustomerIntakes(input: {filter: {fields: {id: {val: $id, op: EQUAL}}}}) {
    count
    rows {
      ...CustomerIntake
    }
  }
}
    ${CustomerIntakeFragmentDoc}`;

/**
 * __useGetCustomerIntakeQuery__
 *
 * To run a query within a React component, call `useGetCustomerIntakeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIntakeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIntakeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerIntakeQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerIntakeQuery, GetCustomerIntakeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIntakeQuery, GetCustomerIntakeQueryVariables>(GetCustomerIntakeDocument, options);
      }
export function useGetCustomerIntakeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIntakeQuery, GetCustomerIntakeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIntakeQuery, GetCustomerIntakeQueryVariables>(GetCustomerIntakeDocument, options);
        }
export type GetCustomerIntakeQueryHookResult = ReturnType<typeof useGetCustomerIntakeQuery>;
export type GetCustomerIntakeLazyQueryHookResult = ReturnType<typeof useGetCustomerIntakeLazyQuery>;
export type GetCustomerIntakeQueryResult = Apollo.QueryResult<GetCustomerIntakeQuery, GetCustomerIntakeQueryVariables>;
export const GetCustomerIntakesCsvBase64Document = gql`
    query getCustomerIntakesCsvBase64($input: GetCustomerIntakesCsvBase64Input) {
  getCustomerIntakesCsvBase64(input: $input)
}
    `;

/**
 * __useGetCustomerIntakesCsvBase64Query__
 *
 * To run a query within a React component, call `useGetCustomerIntakesCsvBase64Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIntakesCsvBase64Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIntakesCsvBase64Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerIntakesCsvBase64Query(baseOptions?: Apollo.QueryHookOptions<GetCustomerIntakesCsvBase64Query, GetCustomerIntakesCsvBase64QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIntakesCsvBase64Query, GetCustomerIntakesCsvBase64QueryVariables>(GetCustomerIntakesCsvBase64Document, options);
      }
export function useGetCustomerIntakesCsvBase64LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIntakesCsvBase64Query, GetCustomerIntakesCsvBase64QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIntakesCsvBase64Query, GetCustomerIntakesCsvBase64QueryVariables>(GetCustomerIntakesCsvBase64Document, options);
        }
export type GetCustomerIntakesCsvBase64QueryHookResult = ReturnType<typeof useGetCustomerIntakesCsvBase64Query>;
export type GetCustomerIntakesCsvBase64LazyQueryHookResult = ReturnType<typeof useGetCustomerIntakesCsvBase64LazyQuery>;
export type GetCustomerIntakesCsvBase64QueryResult = Apollo.QueryResult<GetCustomerIntakesCsvBase64Query, GetCustomerIntakesCsvBase64QueryVariables>;
export const GetCustomerIntakeLogsDocument = gql`
    query getCustomerIntakeLogs($input: GetCustomerIntakeLogsInput) {
  getCustomerIntakeLogs(input: $input) {
    count
    rows {
      ...CustomerIntakeLogListItem
    }
  }
}
    ${CustomerIntakeLogListItemFragmentDoc}`;

/**
 * __useGetCustomerIntakeLogsQuery__
 *
 * To run a query within a React component, call `useGetCustomerIntakeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIntakeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIntakeLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerIntakeLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerIntakeLogsQuery, GetCustomerIntakeLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIntakeLogsQuery, GetCustomerIntakeLogsQueryVariables>(GetCustomerIntakeLogsDocument, options);
      }
export function useGetCustomerIntakeLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIntakeLogsQuery, GetCustomerIntakeLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIntakeLogsQuery, GetCustomerIntakeLogsQueryVariables>(GetCustomerIntakeLogsDocument, options);
        }
export type GetCustomerIntakeLogsQueryHookResult = ReturnType<typeof useGetCustomerIntakeLogsQuery>;
export type GetCustomerIntakeLogsLazyQueryHookResult = ReturnType<typeof useGetCustomerIntakeLogsLazyQuery>;
export type GetCustomerIntakeLogsQueryResult = Apollo.QueryResult<GetCustomerIntakeLogsQuery, GetCustomerIntakeLogsQueryVariables>;

// FormData

export type CurrentUserFragmentFormData = Partial<Omit<CurrentUserFragment, "userUserRoles">> & { userUserRoles?: { __typename?: 'UserRole', id?: Scalars['Int'], role?: { __typename?: 'Role', id?: Scalars['Int'], code?: Scalars['String'], name?: Scalars['String'], rolePermissions?: { __typename?: 'RolePermission', id?: Scalars['Int'], permission?: { __typename?: 'Permission', code?: Scalars['String'] } }[] } }[] };

export type UserFragmentFormData = Partial<Omit<UserFragment, "userUserRoles">> & { userUserRoles?: UserRoleListItemFragmentFormData[] };

export type UserListItemFragmentFormData = Partial<UserListItemFragment>;

export type PermissionFragmentFormData = Partial<PermissionFragment>;

export type PermissionListItemFragmentFormData = Partial<PermissionListItemFragment>;

export type RoleFragmentFormData = Partial<Omit<RoleFragment, "rolePermissions">> & { rolePermissions?: RolePermissionPermissionFragmentFormData[] };

export type RoleListItemFragmentFormData = Partial<Omit<RoleListItemFragment, "rolePermissions">> & { rolePermissions?: RolePermissionPermissionFragmentFormData[] };

export type UserRoleListItemFragmentFormData = Partial<Omit<UserRoleListItemFragment, "role">> & { role?: RoleListItemFragmentFormData };

export type RolePermissionPermissionFragmentFormData = Partial<Omit<RolePermissionPermissionFragment, "permission">> & { permission?: PermissionFragmentFormData };

export type StateListItemFragmentFormData = Partial<StateListItemFragment>;

export type StationFragmentFormData = Partial<StationFragment>;

export type StationListItemFragmentFormData = Partial<StationListItemFragment>;

export type CustomerIntakeFragmentFormData = Partial<Omit<Omit<Omit<Omit<CustomerIntakeFragment, "station">, "state">, "repCodeUser">, "verifiedByUser">> & { station?: { __typename?: 'Station', id?: Scalars['Int'], name?: Scalars['String'] }, state?: { __typename?: 'State', id?: Scalars['Int'], name?: Scalars['String'] }, repCodeUser?: { __typename?: 'User', id?: Scalars['Int'], username?: Scalars['String'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> }, verifiedByUser?: { __typename?: 'User', id?: Scalars['Int'], username?: Scalars['String'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> } };

export type CustomerIntakeListItemFragmentFormData = Partial<Omit<Omit<Omit<CustomerIntakeListItemFragment, "repCodeUser">, "verifiedByUser">, "station">> & { repCodeUser?: { __typename?: 'User', id?: Scalars['Int'], username?: Scalars['String'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> }, verifiedByUser?: { __typename?: 'User', id?: Scalars['Int'], username?: Scalars['String'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> }, station?: { __typename?: 'Station', id?: Scalars['Int'], name?: Scalars['String'] } };

export type CsvCustomerIntakeFragmentFormData = Partial<Omit<Omit<Omit<CsvCustomerIntakeFragment, "station">, "state">, "repCodeUser">> & { station?: { __typename?: 'Station', id?: Scalars['Int'], name?: Scalars['String'] }, state?: { __typename?: 'State', id?: Scalars['Int'], name?: Scalars['String'], abbreviation?: Scalars['String'] }, repCodeUser?: { __typename?: 'User', id?: Scalars['Int'], username?: Scalars['String'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> } };

export type CustomerIntakeLogFragmentFormData = Partial<CustomerIntakeLogFragment>;

export type CustomerIntakeLogListItemFragmentFormData = Partial<Omit<CustomerIntakeLogListItemFragment, "createdByUser">> & { createdByUser?: { __typename?: 'User', id?: Scalars['Int'], firstName?: Maybe<Scalars['NullableString']>, lastName?: Maybe<Scalars['NullableString']> } };

