import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
	CustomerIntakeFragment,
	useAddCustomerIntakesMutation,
	useAddStationsMutation,
	useEditCustomerIntakesMutation,
	useRemoveCustomerIntakesMutation,
} from "../../../../generated/graphql";
import { PartialDeep } from "type-fest";
import { useCallback, useEffect } from "react";
import { useGetCustomerIntake } from "../customerIntake.service";
import produce from "immer";

export type CustomerIntakeFormData = PartialDeep<
	CustomerIntakeFragment,
	{ recurseIntoArrays: true }
>;

export interface CustomerIntakeForm {
	form: HTMLFormElement | null;
	isDirty: boolean;
	customerIntake: CustomerIntakeFormData;
}

const _customerIntakeFormAtom = atom<CustomerIntakeForm>({
	key: "customerIntakeForm",
	default: {
		form: null,
		isDirty: false,
		customerIntake: {},
	},
});

const _customerIntakeFormFilterAtom = atom<CustomerIntakeForm>({
	key: "customerIntakeFilterForm",
	default: {
		form: null,
		isDirty: false,
		customerIntake: {},
	},
});

export const useCustomerIntakeFormService = () => {
	const { customerIntake } = useGetCustomerIntake();

	const setCustomerIntakeForm = useSetRecoilState(_customerIntakeFormAtom);

	const resetCustomerIntakeForm = useResetRecoilState(_customerIntakeFormAtom);

	useEffect(() => {
		if (!customerIntake) {
			return;
		}

		setCustomerIntakeForm((prev) => {
			return {
				...prev,
				customerIntake,
			};
		});

		return () => {
			resetCustomerIntakeForm();
		};
	}, [customerIntake, resetCustomerIntakeForm, setCustomerIntakeForm]);
};

export const useGetCustomerIntakeFormData = () => {
	const { customerIntake } = useRecoilValue(_customerIntakeFormAtom);

	return {
		customerIntake,
	};
};

export const usePartialSetCustomerIntake = () => {
	const setCustomerIntakeForm = useSetRecoilState(_customerIntakeFormAtom);

	const partialSetCustomerIntake = useCallback(
		(input: CustomerIntakeFormData) => {
			setCustomerIntakeForm((prev) => {
				return produce(prev, (draft) => {
					draft.isDirty = true;
					draft.customerIntake = {
						...draft.customerIntake,
						...input,
					};
				});
			});
		},
		[setCustomerIntakeForm],
	);

	return {
		partialSetCustomerIntake,
	};
};

export const useGetCustomerIntakeFilterData = () => {
	const { customerIntake } = useRecoilValue(_customerIntakeFormFilterAtom);

	return {
		customerIntake,
	};
};

export const usePartialSetCustomerIntakeFilter = () => {
	const setCustomerIntakeForm = useSetRecoilState(_customerIntakeFormFilterAtom);

	const partialSetCustomerIntake = useCallback(
		(input: CustomerIntakeFormData) => {
			setCustomerIntakeForm((prev) => {
				return produce(prev, (draft) => {
					draft.isDirty = true;
					draft.customerIntake = {
						...draft.customerIntake,
						...input,
					};
				});
			});
		},
		[setCustomerIntakeForm],
	);

	return {
		partialSetCustomerIntake,
	};
};

export const useSaveCustomerIntake = () => {
	const { customerIntake } = useGetCustomerIntakeFormData();

	const [addStations, addStationsRes] = useAddStationsMutation();
	const [addCustomerIntakes, addCustomerIntakesRes] = useAddCustomerIntakesMutation();
	const [editCustomerIntakes, editCustomerIntakesRes] = useEditCustomerIntakesMutation();
	const [removeCustomerIntakes, removeCustomerIntakesRes] = useRemoveCustomerIntakesMutation();

	const saveCustomerIntake = useCallback(async () => {
		let customerIntakeTemp = customerIntake;

		// add new station that doesn't exist
		if (customerIntakeTemp.station && customerIntakeTemp.station.id === undefined) {
			const _addStationsRes = await addStations({
				variables: {
					input: {
						inputs: [
							{
								primaryFields: {
									name: customerIntakeTemp.station.name!,
								},
							},
						],
					},
				},
			});

			customerIntakeTemp = produce(customerIntakeTemp, (draft) => {
				draft.station!.id = _addStationsRes.data!.addStations.rows[0].id;
			});
		}

		if (customerIntakeTemp.id === undefined) {
			return addCustomerIntakes({
				variables: {
					input: {
						inputs: [
							{
								primaryFields: {
									address: customerIntakeTemp.address ?? null,
									announcements: customerIntakeTemp.announcements ?? null,
									authorizer: customerIntakeTemp.authorizer ?? null,
									businessName: customerIntakeTemp.businessName ?? null,
									campaignRepresentative: customerIntakeTemp.campaignRepresentative ?? null,
									city: customerIntakeTemp.city ?? null,
									communityCampaign: customerIntakeTemp.communityCampaign ?? null,
									csNumber: customerIntakeTemp.csNumber ?? null,
									customerNumber: customerIntakeTemp.customerNumber ?? null,
									date: customerIntakeTemp.date ?? null,
									fax: customerIntakeTemp.fax ?? null,
									invoiceNumber: customerIntakeTemp.invoiceNumber ?? null,
									numberOfPayments: customerIntakeTemp.numberOfPayments ?? null,
									phone: customerIntakeTemp.phone ?? null,
									phone2: customerIntakeTemp.phone2 ?? null,
									repCodeUserId: customerIntakeTemp.repCodeUser?.id ?? null,
									stateId: customerIntakeTemp.state?.id ?? null,
									stationId: customerIntakeTemp.station?.id ?? null,
									time: customerIntakeTemp.time ?? null,
									title: customerIntakeTemp.title ?? null,
									verifiedByUserId: customerIntakeTemp.verifiedByUser?.id ?? null,
									web: customerIntakeTemp.web ?? null,
									zip: customerIntakeTemp.zip ?? null,
									email: customerIntakeTemp.email ?? null,
									price: customerIntakeTemp.price ?? null,
									pricePerSpot: customerIntakeTemp.pricePerSpot ?? null,
									lastPurchaseDate: customerIntakeTemp.lastPurchaseDate ?? null,
								},
							},
						],
					},
				},
			});
		} else {
			return editCustomerIntakes({
				variables: {
					input: {
						inputs: [
							{
								primaryKeys: {
									id: customerIntakeTemp.id,
								},
								primaryFields: {
									address: customerIntakeTemp.address ?? null,
									announcements: customerIntakeTemp.announcements ?? null,
									authorizer: customerIntakeTemp.authorizer ?? null,
									businessName: customerIntakeTemp.businessName ?? null,
									campaignRepresentative: customerIntakeTemp.campaignRepresentative ?? null,
									city: customerIntakeTemp.city ?? null,
									communityCampaign: customerIntakeTemp.communityCampaign ?? null,
									csNumber: customerIntakeTemp.csNumber ?? null,
									customerNumber: customerIntakeTemp.customerNumber ?? null,
									date: customerIntakeTemp.date ?? null,
									fax: customerIntakeTemp.fax ?? null,
									invoiceNumber: customerIntakeTemp.invoiceNumber ?? null,
									numberOfPayments: customerIntakeTemp.numberOfPayments ?? null,
									phone: customerIntakeTemp.phone ?? null,
									phone2: customerIntakeTemp.phone2 ?? null,
									repCodeUserId: customerIntakeTemp.repCodeUser?.id ?? null,
									stateId: customerIntakeTemp.state?.id ?? null,
									stationId: customerIntakeTemp.station?.id ?? null,
									time: customerIntakeTemp.time ?? null,
									title: customerIntakeTemp.title ?? null,
									verifiedByUserId: customerIntakeTemp.verifiedByUser?.id ?? null,
									web: customerIntakeTemp.web ?? null,
									zip: customerIntakeTemp.zip ?? null,
									email: customerIntakeTemp.email ?? null,
									price: customerIntakeTemp.price ?? null,
									pricePerSpot: customerIntakeTemp.pricePerSpot ?? null,
									lastPurchaseDate: customerIntakeTemp.lastPurchaseDate ?? null,
								},
							},
						],
					},
				},
			});
		}
	}, [addCustomerIntakes, addStations, customerIntake, editCustomerIntakes]);

	return {
		saveCustomerIntake,
		addStationsRes,
		addCustomerIntakesRes,
		editCustomerIntakesRes,
		removeCustomerIntakes,
		removeCustomerIntakesRes,
	};
};
