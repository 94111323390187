import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import "./table.scss";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import Input from "../input/Input";
import Button from "../button/Button";
import { HiArrowRight } from "react-icons/hi";

export interface Row<T extends { [key: string]: any }> {
	className?: string;
	data: T;
}

export interface Column<T extends { [key: string]: any }> {
	name: string | JSX.Element;
	field?: keyof T;
	formattedField?: (row: Row<T>, index: number) => JSX.Element | undefined;
	isSortable?: boolean;
	sortField?: string; // uses field if undefined
	columnWidth?: string;
}

export interface Pagination {
	pages: number;
	currentPage: number;
	onPageClick: (page: number) => void;
	hasJumpToPage?: boolean;
}

interface Props<T extends { [key: string]: any }> {
	className?: string;
	columns: Array<Column<T> | undefined>;
	rows: Row<T>[];
	onSort?: (field: string, isDesc: boolean) => void;
	defaultSortField?: string;
	defaultIsSortDesc?: boolean;
	extraRow?: JSX.Element;
	pagination?: Pagination;
	notSortedIcon?: JSX.Element;
	ascIcon?: JSX.Element;
	descIcon?: JSX.Element;
	render?: () => JSX.Element; // render this instead of tbody, good for when loading
	isPivoted?: boolean;
	onRowClick?: (row: Row<T>) => void;
}

const Table = <T extends { [key: string]: any }>({
	className,
	columns,
	rows,
	onSort,
	defaultSortField,
	defaultIsSortDesc,
	extraRow,
	pagination,
	notSortedIcon,
	ascIcon,
	descIcon,
	render,
	isPivoted,
	onRowClick,
}: Props<T>): React.FunctionComponentElement<Props<T>> => {
	const [sortField, setSortField] = useState<string | undefined>(defaultSortField);
	const [isDesc, setIsDesc] = useState(defaultIsSortDesc || false);
	const [paginationItems, setPaginationItems] = useState<string[]>([]);
	const [gotoNumber, setGotoNumber] = useState<string>();

	const canSort = useRef(false);

	const onColumnHeaderClick = (field: string | keyof T | undefined) => {
		setSortField(field as string);
		if (field !== sortField) {
			setIsDesc(defaultIsSortDesc || false);
		} else {
			setIsDesc(!isDesc);
		}
	};

	useEffect(() => {
		if (canSort.current) {
			if (onSort && sortField !== undefined) {
				onSort(sortField, isDesc);
			}
		} else {
			canSort.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortField, isDesc]);

	useEffect(() => {
		if (pagination && pagination.pages > 0) {
			const pItems: string[] = [];
			const currentPage = pagination.currentPage;
			const pages = Math.ceil(pagination.pages);
			const pageRange = 20;

			pItems.push("prev");

			let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
			let endPage = Math.min(pages, startPage + pageRange - 1);

			if (currentPage < pageRange) {
				endPage = Math.min(pages, pageRange);
				startPage = 1;
			}
			if (pages - currentPage < pageRange) {
				startPage = Math.max(1, pages - pageRange + 1);
			}

			if (startPage > 1) {
				pItems.push("1");
				pItems.push("...");
			}

			for (let i = startPage; i <= endPage; i++) {
				pItems.push(String(i));
			}

			if (endPage < pages) {
				pItems.push("...");
				pItems.push(String(pages));
			}

			pItems.push("next");
			setPaginationItems(pItems);
		} else {
			setPaginationItems([]);
		}
	}, [pagination, setPaginationItems]);
	return (
		<>
			<table className={className}>
				<thead>
					<tr>
						{columns.map((column, i) => {
							if (!column) {
								return undefined;
							}

							return (
								<th
									key={i}
									onClick={() =>
										column.isSortable
											? onColumnHeaderClick(
													column.sortField !== undefined ? column.sortField : column.field,
											  )
											: null
									}
									style={{ width: column.columnWidth !== undefined ? column.columnWidth : "auto" }}
									className={classNames(column.isSortable ? "sortable" : "")}
								>
									<div
										className={
											"sorting-icons " +
											(sortField !== undefined &&
											sortField ===
												(column.sortField !== undefined ? column.sortField : column.field)
												? "sorted-column"
												: "")
										}
										style={{ display: "inline-flex", width: "100%" }}
									>
										{column.name}
										{sortField !== undefined &&
										sortField === (column.sortField !== undefined ? column.sortField : column.field)
											? isDesc
												? descIcon
												: ascIcon
											: column.sortField === undefined
											? ""
											: notSortedIcon}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				{!render && (
					<tbody>
						{rows.map((row, i) => {
							return (
								<tr key={i} className={row.className} onClick={() => onRowClick?.(row)}>
									{columns.map((column, j) => {
										if (!column) {
											return undefined;
										}

										let value;
										if (column.formattedField) {
											value = column.formattedField(row, i);
										} else {
											value = row.data[column.field!];
										}
										return isPivoted ? (
											<td key={i + "-" + j} col-name={column.name}>
												{value}
											</td>
										) : (
											<td key={i + "-" + j}>{value}</td>
										);
									})}
								</tr>
							);
						})}
						{extraRow}
					</tbody>
				)}
			</table>
			{render && render()}
			{!render && (
				<>
					{pagination && paginationItems.length > 0 && (
						<div className="pagination flex flex-col gap-4 py-4">
							<div className="flex flex-wrap items-center gap-4">
								<div className="flex flex-wrap text-fsDarkGray">
									{paginationItems.map((v, i) => {
										const pages = Math.ceil(pagination.pages);
										return (
											<React.Fragment key={"pag-" + i}>
												{v === "prev" && (
													<div
														className={
															"pagination-item " + (pagination.currentPage === 1 ? "disabled" : "")
														}
														onClick={() => pagination.onPageClick(pagination.currentPage - 1)}
													>
														<AiOutlineDoubleLeft />
													</div>
												)}
												{!(v === "next" || v === "prev" || v === "...") && (
													<div
														className={
															"pagination-item " +
															(pagination.currentPage === Number(v) ? "active" : "")
														}
														onClick={() => pagination.onPageClick(Number(v))}
													>
														{Number(v)}
													</div>
												)}
												{v === "next" && (
													<div
														className={
															"pagination-item " +
															(pagination.currentPage === pages ? "disabled" : "")
														}
														onClick={() => pagination.onPageClick(pagination.currentPage + 1)}
													>
														<AiOutlineDoubleRight />
													</div>
												)}
												{v === "..." && <div className="pagination-item disabled">...</div>}
											</React.Fragment>
										);
									})}
								</div>
								{pagination.hasJumpToPage && (
									<div className=" flex items-center gap-2 text-fsDarkGray">
										Jump to Page
										<Input
											className="!w-16"
											type="number"
											value={gotoNumber}
											onChange={(e) => {
												setGotoNumber(e.target.value);
											}}
										/>
										<Button
											className="default-btn"
											onClick={() => {
												const tempPage = Number(gotoNumber) || 1;
												if (tempPage <= pagination.pages) {
													pagination.onPageClick(tempPage);
												} else {
													pagination.onPageClick(Math.ceil(pagination.pages));
												}
												setGotoNumber("");
											}}
										>
											<HiArrowRight className="h-5 w-5" />
										</Button>
									</div>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Table;
