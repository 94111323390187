import { loadEnvs } from "load-envs";

export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

export interface Env {
	NODE_ENV: EnvTypes;
	REACT_APP_API_BASE_URL: string;
}

let env: Env;

const init = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (env !== undefined) return;

	// load envs here
	env = loadEnvs<Env>([
		{
			envKey: "NODE_ENV",
			options: {
				validValues: Object.values(EnvTypes),
			},
		},
		{
			envKey: "REACT_APP_API_BASE_URL",
		},
	]);
};

init();

export default env!;
