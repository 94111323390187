import { toast } from "react-toastify";
import Button from "../../../../../g3-components/button/Button";
import { useResetUserPassword } from "../resetUserPasswordForm.service";

export interface Props {
	onSuccess: () => void;
}

export const ResetUserPasswordResetButton: React.FC<Props> = ({ onSuccess }) => {
	const { resetUserPassword, resetUserPasswordRes } = useResetUserPassword();

	const isLoading = resetUserPasswordRes.loading;

	return (
		<Button
			className="default-btn"
			type="button"
			disabled={isLoading}
			onClick={() => {
				resetUserPassword()
					.then(() => {
						onSuccess();
						toast.success("Password Reset Successfully!");
					})
					.catch(() => {
						toast.error("Password Reset Error!");
					});
			}}
		>
			Reset
		</Button>
	);
};
