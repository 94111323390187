import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import "./main-layout.scss";
import { useRecoilValue } from "recoil";
import { isNavigationCollapsedAtom } from "./MainLayout.recoil";
import classNames from "classnames";

const MainLayout: React.FC = () => {
	// incoming values

	// state hooks

	// recoils
	const isNavigationCollapsed = useRecoilValue(isNavigationCollapsedAtom);

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className={classNames("main-layout", isNavigationCollapsed ? "collapsed" : "expanded")}>
			<Header />
			<Navigation />
			<div className="content">
				<Outlet />
			</div>
		</div>
	);
};

export default MainLayout;
