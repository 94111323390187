import React from "react";
import "./customerIntakeLogListTable.scss";
import { useGetCustomerIntakeLogs } from "../customerIntakeLogList.service";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Table from "../../../../../../../g3-components/table/Table";
import { GetCustomerIntakeLogsSortByNames } from "../../../../../../../generated/graphql";
import { format } from "date-fns";

export const CustomerIntakeLogListTable: React.FC = () => {
	const { customerIntakeLogs } = useGetCustomerIntakeLogs();

	return (
		<div className="customerIntakeLogListTable">
			<Table
				columns={[
					{
						name: "DATE TIME",
						isSortable: true,
						sortField: GetCustomerIntakeLogsSortByNames.CreatedAt,
						formattedField: (row) => {
							return <>{format(row.data.createdAt, "MM-dd-yyyy HH:mm:ss")}</>;
						},
					},
					{
						name: "COMMENT",
						isSortable: true,
						sortField: GetCustomerIntakeLogsSortByNames.CreatedAt, // @TODO
						formattedField: (row) => {
							return <>{row.data.message}</>;
						},
					},
					{
						name: "COMMENTER",
						isSortable: true,
						sortField: GetCustomerIntakeLogsSortByNames.CreatedAt, // @TODO
						formattedField: (row) => {
							return (
								<>{row.data.createdByUser?.firstName + " " + row.data.createdByUser?.lastName}</>
							);
						},
					},
				]}
				rows={customerIntakeLogs.map((data) => {
					return {
						data,
					};
				})}
				className="settings-global-general-users-table"
				notSortedIcon={
					<div className="flex flex-col">
						<FiChevronUp className="-mb-2 h-4 w-4 text-slate-400" />
						<FiChevronDown className="h-4 w-4 text-slate-400" />
					</div>
				}
				ascIcon={
					<div className="flex flex-col items-center">
						<FiChevronUp className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
				descIcon={
					<div className="flex flex-col items-center">
						<FiChevronDown className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
			/>
		</div>
	);
};
