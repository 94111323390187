import React from "react";
import { MdClose, MdMenu } from "react-icons/md";
import { useRecoilState } from "recoil";
import { headerStateAtom, HeaderStates } from "../MainLayout.recoil";

const NavigationMenuButton: React.FC = () => {
	// incoming values

	// state hooks

	// recoils
	const [headerState, setHeaderState] = useRecoilState(headerStateAtom);
	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div
			className="flex h-full w-14 items-center justify-center"
			onClick={() =>
				headerState === HeaderStates.NavigationMenu
					? setHeaderState(HeaderStates.Default)
					: setHeaderState(HeaderStates.NavigationMenu)
			}
		>
			{headerState === HeaderStates.NavigationMenu ? (
				<MdClose className="h-6 w-6 text-fsDarkGray" />
			) : (
				<MdMenu className="h-6 w-6 text-fsDarkGray" />
			)}
		</div>
	);
};

export default NavigationMenuButton;
