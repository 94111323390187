import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { PartialDeep } from "type-fest";
import {
	CustomerIntakeLogFragment,
	useAddCustomerIntakeLogsMutation,
} from "../../../../../../../generated/graphql";
import { useGetCustomerIntakeFormData } from "../../../customerIntakeForm.service";
import { useCallback, useEffect } from "react";
import produce from "immer";

export type CustomerIntakeLogFormData = PartialDeep<
	CustomerIntakeLogFragment,
	{ recurseIntoArrays: true }
>;

export interface CustomerIntakeLogForm {
	form: HTMLFormElement | null;
	isDirty: boolean;
	customerIntakeLog: CustomerIntakeLogFormData;
}

const _customerIntakeLogFormAtom = atom<CustomerIntakeLogForm>({
	key: "customerIntakeLogForm",
	default: {
		form: null,
		isDirty: false,
		customerIntakeLog: {},
	},
});

export const useCustomerIntakeLogFormService = () => {
	const resetCustomerIntakeLogForm = useResetRecoilState(_customerIntakeLogFormAtom);

	useEffect(() => {
		return () => {
			resetCustomerIntakeLogForm();
		};
	}, [resetCustomerIntakeLogForm]);
};

export const useGetCustomerIntakeLogFormData = () => {
	const { customerIntakeLog } = useRecoilValue(_customerIntakeLogFormAtom);

	return {
		customerIntakeLog,
	};
};

export const usePartialSetCustomerIntakeLog = () => {
	const setCustomerIntakeLogForm = useSetRecoilState(_customerIntakeLogFormAtom);

	const partialSetCustomerIntakeLog = useCallback(
		(input: CustomerIntakeLogFormData) => {
			setCustomerIntakeLogForm((prev) => {
				return produce(prev, (draft) => {
					draft.isDirty = true;
					draft.customerIntakeLog = {
						...draft.customerIntakeLog,
						...input,
					};
				});
			});
		},
		[setCustomerIntakeLogForm],
	);

	return {
		partialSetCustomerIntakeLog,
	};
};

export const useSaveCustomerIntakeLog = () => {
	const { customerIntake } = useGetCustomerIntakeFormData();
	const { customerIntakeLog } = useGetCustomerIntakeLogFormData();
	const resetCustomerIntakeLogForm = useResetRecoilState(_customerIntakeLogFormAtom);

	const [addCustomerIntakeLogs, addCustomerIntakeLogsRes] = useAddCustomerIntakeLogsMutation();

	const saveCustomerIntakeLog = useCallback(async () => {
		if (customerIntake.id === undefined) {
			throw new Error("Customer intake id is undefined");
		}

		return addCustomerIntakeLogs({
			variables: {
				input: {
					inputs: [
						{
							primaryFields: {
								customerIntakeId: customerIntake.id,
								message: customerIntakeLog.message ?? null,
							},
						},
					],
				},
			},
		}).then(() => {
			resetCustomerIntakeLogForm();
		});
	}, [
		addCustomerIntakeLogs,
		customerIntake.id,
		customerIntakeLog.message,
		resetCustomerIntakeLogForm,
	]);

	return {
		saveCustomerIntakeLog,
		addCustomerIntakeLogsRes,
	};
};
