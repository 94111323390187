import { useCustomerIntakeListService } from "./customerIntakeList.service";
import { CustomerIntakeListActions } from "./customerIntakeListActions/CustomerIntakeListActions";
import { CustomerIntakeListFilter } from "./customerIntakeListFilter/CustomerIntakeListFilter";
import { CustomerIntakeListTable } from "./customerIntakeListTable/CustomerIntakeListTable";
import "./customerIntakeList.scss";

export const CustomerIntakeList: React.FC = () => {
	useCustomerIntakeListService();

	return (
		<div className="customerIntakeList">
			<div className="customer-filter-actions">
				<CustomerIntakeListFilter />
				<CustomerIntakeListActions />
			</div>

			<CustomerIntakeListTable />
		</div>
	);
};
