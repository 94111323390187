import { useCallback, useEffect } from "react";
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import produce, { Draft } from "immer";
import { PartialDeep } from "type-fest";
import {
	StationFragment,
	useAddStationsMutation,
	useEditStationsMutation,
	useGetStationLazyQuery,
} from "../../../../generated/graphql";

export type StationFragmentFormData = PartialDeep<StationFragment, { recurseIntoArrays: true }> & {
	name?: string;
};

export interface StationForm {
	form: HTMLFormElement | null;
	isDirty: boolean;
	station: StationFragmentFormData;
}

const _stationFormAtom = atom<StationForm>({
	key: "stationForm",
	default: {
		form: null,
		isDirty: false,
		station: {
			name: "",
		},
	},
});

export const useGetStationForm = () => {
	const stationForm = useRecoilValue(_stationFormAtom);

	return {
		stationForm,
	};
};

export const useStationForm = (
	formRef: React.RefObject<HTMLFormElement | null>,
	stationId?: number,
) => {
	const setStationForm = useSetRecoilState(_stationFormAtom);
	const resetStationForm = useResetRecoilState(_stationFormAtom);

	const [getStation, getStationRes] = useGetStationLazyQuery();

	useEffect(() => {
		return () => {
			resetStationForm();
		};
	}, [resetStationForm]);

	useEffect(() => {
		setStationForm((state) => {
			return {
				...state,
				form: formRef.current,
			};
		});
	}, [formRef, setStationForm]);

	useEffect(() => {
		if (stationId !== undefined) {
			void getStation({
				variables: {
					id: stationId,
				},
			});
		}
	}, [getStation, stationId]);

	useEffect(() => {
		if (getStationRes.called && !getStationRes.loading) {
			if (getStationRes.data && getStationRes.data.getStations.rows.length !== 0) {
				const station = getStationRes.data.getStations.rows[0];
				setStationForm((state) => {
					return {
						...state,
						station,
					};
				});
			}
		}
	}, [getStationRes.called, getStationRes.data, getStationRes.loading, setStationForm]);
};

export const useSetStationForm = () => {
	const _setStationForm = useSetRecoilState(_stationFormAtom);

	const setStationForm = useCallback(
		(callback: (draft: Draft<StationForm>) => void) => {
			_setStationForm((state) => {
				return produce(state, (draft) => {
					callback(draft);
				});
			});
		},
		[_setStationForm],
	);

	return {
		setStationForm,
	};
};

export const usePartialSetStation = () => {
	const { setStationForm } = useSetStationForm();

	const partialSetStation = useCallback(
		(input: StationFragmentFormData) => {
			setStationForm((draft) => {
				draft.isDirty = true;
				draft.station = {
					...draft.station,
					...input,
				};
			});
		},
		[setStationForm],
	);

	return {
		partialSetStation,
	};
};

export const useSaveStation = () => {
	const { station } = useRecoilValue(_stationFormAtom);

	const [addStations, addStationsRes] = useAddStationsMutation();
	const [editStations, editStationsRes] = useEditStationsMutation();

	const saveStation = useCallback(async () => {
		// @TODO here verify required fields from form
		if (station.name !== undefined) {
			if (station.id === undefined) {
				await addStations({
					variables: {
						input: {
							inputs: [
								{
									primaryFields: {
										name: station.name,
									},
								},
							],
						},
					},
				});
			} else {
				await editStations({
					variables: {
						input: {
							inputs: [
								{
									primaryKeys: {
										id: station.id!,
									},
									primaryFields: {
										name: station.name,
									},
								},
							],
						},
					},
				});
			}
		}
	}, [station.id, station.name, addStations, editStations]);

	return {
		saveStation,
		addStationsRes,
		editStationsRes,
	};
};
