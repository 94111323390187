import React from "react";
import "./customerIntake.scss";
import { CustomerIntakeForm } from "./customerIntakeForm/CustomerIntakeForm";
import { CustomerIntakeSaveButton } from "./customerIntakeForm/customerIntakeSaveButton/CustomerIntakeSaveButton";
import { useCustomerIntakeService, useGetCustomerIntake } from "./customerIntake.service";
import { CustomerIntakeLogs } from "./customerIntakeForm/customerIntakeLogs/CustomerIntakeLogs";
import { CustomerIntakeDeleteButton } from "./customerIntakeForm/customerIntakeDeleteButton/CustomerIntakeDeleteButton";

export const CustomerIntake: React.FC = () => {
	useCustomerIntakeService();

	const { customerIntake } = useGetCustomerIntake();

	return (
		<div className="customerIntake">
			<div className="customerIntakeButtons">
				{customerIntake !== undefined && (
					<CustomerIntakeDeleteButton customerIntake={customerIntake} />
				)}
				<CustomerIntakeSaveButton />
			</div>

			<CustomerIntakeForm />
			{customerIntake !== undefined && <CustomerIntakeLogs />}
		</div>
	);
};
