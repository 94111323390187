import React, { ButtonHTMLAttributes } from "react";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<Props> = ({ children, className, ...props }) => {
	return (
		// @TODO what should i do css of different type, even i use all line css still need differentiate
		<button {...props} className={"button " + (className !== undefined && className)}>
			{children}
		</button>
	);
};

export default Button;
