import { atom, selector } from "recoil";
import { FilterOperators, GetStationsFilter } from "../../../generated/graphql";

export const stationsTableReloaderAtom = atom<boolean>({
	key: "stationsTableReloader",
	default: false,
});

export interface StationsTableFilter {
	name?: string;
}

export const stationsTableFilterAtom = atom<StationsTableFilter>({
	key: "stationsTableFilter",
	default: {},
});

export const getStationsFilterSelector = selector<GetStationsFilter>({
	key: "getStationsFilter",
	get: ({ get }) => {
		const stationsTableFilter = get(stationsTableFilterAtom);

		const getStationsFilter: GetStationsFilter = {
			and: [],
		};

		if (stationsTableFilter.name !== undefined) {
			getStationsFilter.and!.push({
				fields: {
					name: {
						val: `%${stationsTableFilter.name}%`,
						op: FilterOperators.ILike,
					},
				},
			});
		}

		return getStationsFilter;
	},
});
