import React from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { useModals } from "../../../../../g3-components/modal/modals.recoil";
import { usersTableReloaderAtom } from "../../users.service";
import Button from "../../../../../g3-components/button/Button";
import { UserListItemFragment, useEditUsersMutation } from "../../../../../generated/graphql";
import { useGetCurrentUser } from "../../../../../state/user/currentUser.recoil";

interface Props {
	children?: React.ReactNode;
	user: UserListItemFragment;
}

const DeleteUserButton: React.FC<Props> = ({ children, user }) => {
	// state hooks

	// recoils
	const { pushModal, popModal } = useModals();
	const { currentUser } = useGetCurrentUser();
	const reloadUsersTable = useSetRecoilState(usersTableReloaderAtom);

	// // custom hooks
	const [editUsers] = useEditUsersMutation();

	// local variables

	// functions
	const _deleteUser = () => {
		editUsers({
			variables: {
				input: {
					inputs: [
						{
							primaryKeys: {
								id: user.id,
							},
							primaryFields: {
								deletedBy: currentUser?.id,
								deletedAt: new Date(),
								isVerifier: user.isVerifier,
								username: user.username,
							},
							associatedFields: {
								userUserRoles: [],
							},
						},
					],
				},
			},
		})
			.then(() => {
				toast.success("Deleted Successfully!");
				reloadUsersTable((v) => !v);
			})
			.catch(() => {
				toast.error("Delete Error!");
			})
			.finally(() => {
				popModal();
			});
	};

	const _deleteUserPrompt = () => {
		return (
			<div className="alarm-model">
				{`Delete user ${user.username}?`}

				<div className="row mt-8 flex justify-center gap-3">
					<Button
						className="default-btn"
						type="button"
						onClick={() => {
							_deleteUser();
						}}
					>
						Yes
					</Button>
					<Button
						className="outline-btn"
						type="button"
						onClick={() => {
							popModal();
						}}
					>
						No
					</Button>
				</div>
			</div>
		);
	};

	// React hooks

	// jsx variables

	return (
		<Button
			className="flex w-full justify-center"
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				pushModal({
					render: _deleteUserPrompt(),
				});
			}}
		>
			{children}
		</Button>
	);
};

export default DeleteUserButton;
