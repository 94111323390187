import React, { useState } from "react";

import {
	useGetCustomerIntakeFormData,
	usePartialSetCustomerIntake,
} from "../customerIntakeForm.service";
import ApolloInputSearch from "../../../../../components/customInputSearch/ApolloInputSearch";
import {
	FilterOperators,
	GetStatesDocument,
	GetStatesQueryVariables,
	GetStatesSortByNames,
	StateListItemFragment,
} from "../../../../../generated/graphql";

export const StateInputSearch: React.FC = () => {
	// state hooks
	const [searchStr, setSearchStr] = useState("");

	// recoils

	const { customerIntake } = useGetCustomerIntakeFormData();
	const { partialSetCustomerIntake } = usePartialSetCustomerIntake();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<ApolloInputSearch<StateListItemFragment, GetStatesQueryVariables>
			query={GetStatesDocument}
			searchParams={{
				shouldSearch: true,
				queryVariables: {
					input: {
						filter: {
							fields: {
								name: {
									val: "%" + searchStr + "%",
									op: FilterOperators.ILike,
								},
							},
						},
						sortBys: [
							{
								name: GetStatesSortByNames.Name,
								isDesc: false,
							},
						],
						pagination: {
							limit: 10,
						},
					},
				},
			}}
			onGetDisplayLabels={(value) => {
				return {
					resultLabel: value.name,
					selectedLabel: value.name,
				};
			}}
			placeholder="Search State"
			value={customerIntake.state ? customerIntake.state.name : searchStr}
			onSelectSearchResult={(searchResult) => {
				setSearchStr(searchResult.selectedLabel);
				partialSetCustomerIntake({
					state: {
						id: searchResult.value.id,
						name: searchResult.value.name,
					},
				});
			}}
			onChange={(e) => {
				setSearchStr(e.target.value);
				partialSetCustomerIntake({
					state: undefined,
				});
			}}
			onBlur={() => {
				if (customerIntake.state === undefined) {
					setSearchStr("");
				}
			}}
		/>
	);
};
