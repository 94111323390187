import React from "react";
import "./customerIntakeLogList.scss";
import { useCustomerIntakeLogListService } from "./customerIntakeLogList.service";
import { CustomerIntakeLogListTable } from "./customerIntakeLogListTable/CustomerIntakeLogListTable";

export const CustomerIntakeLogList: React.FC = () => {
	useCustomerIntakeLogListService();

	return (
		<div className="customerIntakeLogList">
			<CustomerIntakeLogListTable />
		</div>
	);
};
