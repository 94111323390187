import React, { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import { useCurrentUserGetter } from "../../state/user/currentUser.recoil";
import Loading from "../loading/Loading";

export type Props = {
	children?: React.ReactNode;
};

/**
 * Must be logged in to go to this component, otherwise redirect to PathName.LOGIN
 */
const PrivateComponent: React.FC<Props> = ({ children }) => {
	const {
		getCurrentUser,
		getCurrentUserRequestState: { data: currentUser, ...getCurrentUserRequestState },
	} = useCurrentUserGetter();

	useEffect(() => {
		if (!currentUser) {
			getCurrentUser().catch(() => {
				// do nothing
			});
		}
	}, [currentUser, getCurrentUser]);

	const element = useMemo(() => {
		if (!getCurrentUserRequestState.wasCalled || getCurrentUserRequestState.isLoading) {
			return <Loading />;
		} else if (getCurrentUserRequestState.error !== undefined) {
			return <Navigate to={PathName.LOGIN} />;
		} else if (currentUser) {
			return children;
		} else {
			return <Navigate to={PathName.UNKNOWN_ERROR} />;
		}
	}, [
		children,
		currentUser,
		getCurrentUserRequestState.error,
		getCurrentUserRequestState.isLoading,
		getCurrentUserRequestState.wasCalled,
	]);

	return <>{element}</>;
};

export default PrivateComponent;
