export class PathName {
	public static get NOT_FOUND() {
		return "/404-page-not-found";
	}
	public static get UNKNOWN_ERROR() {
		return "/unknown-error";
	}
	public static get DEFAULT() {
		return PathName.LOGIN;
	}
	public static get LOGIN() {
		return "/login";
	}
	public static get DEFAULT_LOGIN_REDIRECT() {
		return PathName.CUSTOMER_INTAKES();
	}
	public static CUSTOMER_INTAKES(customerIntakeId?: string) {
		return `/customer-intakes${customerIntakeId !== undefined ? `/${customerIntakeId}` : ""}`;
	}
	public static get SETTINGS() {
		return "/settings";
	}
}
