import React from "react";
import { FiX } from "react-icons/fi";
import "./modal.scss";

export interface ModalProps {
	render: JSX.Element;
	title?: JSX.Element | string;
	onCloseClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({ render, onCloseClick, title }) => {
	return (
		<div className="modal">
			<div
				className="card animate__animated animate__fadeInUp animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="card-header flex">
					<div className="modal-title">{title}</div>
					{onCloseClick && (
						<FiX
							className="m-4 h-5 w-5 cursor-pointer self-end rounded-full hover:bg-gray-300"
							onClick={() => onCloseClick()}
						/>
					)}
				</div>
				<div className="modal-content">{render}</div>
			</div>
		</div>
	);
};

export default Modal;
