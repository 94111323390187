import React from "react";
import { useModals } from "../../../../../g3-components/modal/modals.recoil";
import Button from "../../../../../g3-components/button/Button";
import { UserListItemFragment } from "../../../../../generated/graphql";
import { ResetUserPasswordForm } from "../../resetUserPasswordForm/ResetUserPasswordForm";

interface Props {
	children?: React.ReactNode;
	user: UserListItemFragment;
}

const OpenResetUserPasswordFormButton: React.FC<Props> = ({ children, user }) => {
	// state hooks

	// recoils
	const { pushModal, popModal } = useModals();

	// // custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<Button
			className="flex w-full justify-center"
			type="button"
			onClick={(e) => {
				e.stopPropagation();
				pushModal({
					render: (
						<ResetUserPasswordForm
							user={user}
							onCancel={() => {
								popModal();
							}}
							onSuccess={() => {
								popModal();
							}}
						/>
					),
				});
			}}
		>
			{children}
		</Button>
	);
};

export default OpenResetUserPasswordFormButton;
