import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import Button from "../../../../g3-components/button/Button";
import Input from "../../../../g3-components/input/Input";
import { StationsTableFilter, stationsTableFilterAtom } from "../stations.service";
import "./stationsFilters.scss";

export const StationsFilters: React.FC = () => {
	const [localStationsFilter, setLocalStationsFilter] = useState<StationsTableFilter>({});

	const [searchStr, setSearchStr] = useState("");

	// recoils
	const setStationsFilter = useSetRecoilState(stationsTableFilterAtom);

	// functions
	const applyFilter = () => {
		setStationsFilter({
			...localStationsFilter,
		});
	};

	const clearFilter = useCallback(() => {
		setLocalStationsFilter({});
		setStationsFilter({});
	}, [setStationsFilter]);

	// React hooks
	useEffect(() => {
		return () => {
			clearFilter();
		};
	}, [clearFilter]);
	return (
		<div className="stations-filters">
			<div className="stations-filters-inputs">
				<div>
					<div className="font-medium text-gray-500">Station:</div>
					<Input
						value={localStationsFilter.name !== undefined ? localStationsFilter.name : ""}
						onChange={(e) =>
							setLocalStationsFilter((state) => {
								return {
									...state,
									name: e.target.value,
								};
							})
						}
					/>
				</div>

				<div></div>
			</div>
			<div className="stations-filters-buttons">
				<Button className="outline-btn" onClick={() => clearFilter()}>
					Clear
				</Button>
				<Button className="default-btn" onClick={() => applyFilter()}>
					Apply
				</Button>
			</div>
		</div>
	);
};
