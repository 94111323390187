import React from "react";
import "./customerIntakeLog.scss";
import { CustomerIntakeLogForm } from "./customerIntakeLogForm/CustomerIntakeLogForm";

export const CustomerIntakeLog: React.FC = () => {
	return (
		<div className="customerIntakeLog">
			<CustomerIntakeLogForm />
		</div>
	);
};
