import React from "react";
import { useLocation } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import NavItem from "./NavItem";
import "./navigation.scss";
import { useRecoilState } from "recoil";
import { headerStateAtom, HeaderStates } from "../MainLayout.recoil";
import classNames from "classnames";
import { useHasPermission } from "../../../state/user/currentUser.recoil";
import { PermissionCodes } from "../../../generated/graphql";

export interface NavigationItem {
	name: string;
	href: string;
	icon?: (props: React.ComponentProps<"svg">) => JSX.Element;
	isCurrent?: boolean;
	children?: NavigationItem[];
}

const Navigation: React.FC = () => {
	// incoming values

	// state hooks
	const [headerState, setHeaderState] = useRecoilState(headerStateAtom);
	const location = useLocation();

	// recoils
	const { hasPermission } = useHasPermission();

	// custom hooks

	// local variables

	// functions
	const getRawNavigation = (): NavigationItem[] => {
		const navigation: NavigationItem[] = [];
		navigation.push({
			name: "Customer Intakes",
			href: PathName.CUSTOMER_INTAKES(),
		});

		if (
			hasPermission({
				value: PermissionCodes.Settings,
			})
		) {
			navigation.push({
				name: "Settings",
				href: PathName.SETTINGS,
			});
		}

		return navigation;
	};

	const processNavigation = (navigation: NavigationItem[]) => {
		navigation.forEach((item) => {
			item.isCurrent = new RegExp("^" + item.href).test(location.pathname);

			if (item.children) {
				processNavigation(item.children);
			}
		});

		return navigation;
	};

	// React hooks

	// jsx variables
	const navigation = processNavigation(getRawNavigation());

	return (
		<div
			className={classNames(
				"navigation ",
				headerState === HeaderStates.NavigationMenu ? "mobile-open" : "",
			)}
		>
			<div className="nav-top">
				<div className="image-container">
					<img src="\img\logo.png" alt="" />
				</div>
				<nav className="navigation-menu">
					{navigation.map((item, index) => (
						<NavItem
							key={index}
							item={item}
							defaultIsOpen={item.isCurrent}
							onItemClick={() => setHeaderState(HeaderStates.Default)}
						/>
					))}
				</nav>
			</div>
		</div>
	);
};

export default Navigation;
