import React from "react";
import Button from "../../../../g3-components/button/Button";
import { useModals } from "../../../../g3-components/modal/modals.recoil";
import UserForm from "../userForm/UserForm";
import "./userActions.scss";
const UsersActions: React.FC = () => {
	// incoming values

	// state hooks

	// recoils
	const { pushModal, popModal } = useModals();

	// custom hooks

	// local variables

	// functions

	// React hooks

	// jsx variables

	return (
		<div className="userActions">
			<div className="flex flex-1 gap-4">
				<Button
					type="button"
					className="default-btn ml-auto w-full justify-center tablet:w-max"
					onClick={() => {
						pushModal({
							render: (
								<UserForm
									onCancel={() => {
										popModal();
									}}
									onSaveSuccess={() => {
										popModal();
									}}
								/>
							),
							title: "Add User",
						});
					}}
				>
					+ Add User
				</Button>
			</div>
		</div>
	);
};

export default UsersActions;
