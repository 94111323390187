import React from "react";
import Button from "../../../../g3-components/button/Button";
import { useModals } from "../../../../g3-components/modal/modals.recoil";
import { StationForm } from "../stationForm/StationForm";
import "./stationsActions.scss";

export const StationsActions: React.FC = () => {
	const { pushModal, popModal } = useModals();

	return (
		<div className="stations-actions">
			<div className="flex flex-1 gap-4">
				<Button
					type="button"
					className="default-btn ml-auto w-full justify-center tablet:w-max"
					onClick={() => {
						pushModal({
							render: (
								<StationForm
									onCancel={() => {
										popModal();
									}}
									onSaveSuccess={() => {
										popModal();
									}}
								/>
							),
							title: "Add Station",
						});
					}}
				>
					+ Add Station
				</Button>
			</div>
		</div>
	);
};
