import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { CustomerIntakeFragment, useGetCustomerIntakeLazyQuery } from "../../../generated/graphql";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PathName } from "../../../constants/PathName";
import { strToNumber } from "gate3-utils";

const _customerIntakeAtom = atom<CustomerIntakeFragment | undefined>({
	key: "customerIntake",
	default: undefined,
});

const _useSetCustomerIntakeFromParams = () => {
	const navigate = useNavigate();

	const params = useParams<{
		customerIntakeId: string;
	}>();

	const [getCustomerIntake, getCustomerIntakeRes] = useGetCustomerIntakeLazyQuery();

	const setCustomerIntake = useSetRecoilState(_customerIntakeAtom);

	useEffect(() => {
		if (params.customerIntakeId === "add") {
			return;
		}

		const customerIntakeIdParam = strToNumber(params.customerIntakeId);

		if (customerIntakeIdParam === undefined) {
			navigate(PathName.NOT_FOUND);
			return;
		}

		void getCustomerIntake({
			variables: {
				id: customerIntakeIdParam,
			},
		});
	}, [getCustomerIntake, navigate, params.customerIntakeId]);

	useEffect(() => {
		if (getCustomerIntakeRes.called && !getCustomerIntakeRes.loading) {
			if (getCustomerIntakeRes.error || !getCustomerIntakeRes.data) {
				navigate(PathName.UNKNOWN_ERROR);
				return;
			}

			if (getCustomerIntakeRes.data.getCustomerIntakes.rows.length === 0) {
				navigate(PathName.NOT_FOUND);
				return;
			}

			setCustomerIntake(getCustomerIntakeRes.data.getCustomerIntakes.rows[0]);
		}

		return () => {
			setCustomerIntake(undefined);
		};
	}, [
		getCustomerIntakeRes.called,
		getCustomerIntakeRes.data,
		getCustomerIntakeRes.error,
		getCustomerIntakeRes.loading,
		navigate,
		setCustomerIntake,
	]);
};

export const useCustomerIntakeService = () => {
	_useSetCustomerIntakeFromParams();
};

export const useGetCustomerIntake = () => {
	const customerIntake = useRecoilValue(_customerIntakeAtom);

	return {
		customerIntake,
	};
};
