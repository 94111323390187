import React, { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Table from "../../../../g3-components/table/Table";
import "./customerIntakeListTable.scss";
import {
	DEFAULT_SORT_BY_CUSTOMER_INTAKE,
	useGetCustomerIntakeListCall,
} from "../customerIntakeList.service";
import { useNavigate } from "react-router-dom";
import { PathName } from "../../../../constants/PathName";
import {
	GetCustomerIntakesSortBy,
	GetCustomerIntakesSortByNames,
} from "../../../../generated/graphql";
import { format, parse } from "date-fns";
import { format as formatTz } from "date-fns-tz";
import { useIsAdmin } from "../../../../state/user/currentUser.recoil";
import { pushToSortBys } from "../../../../utils/GraphQLUtils";

export const CustomerIntakeListTable: React.FC = () => {
	const navigate = useNavigate();

	const { customerIntakes, totalPages, currentPage, setCurrentPage, setSortByState } =
		useGetCustomerIntakeListCall();

	const { isAdmin } = useIsAdmin();

	const onPageChange = (page: number) => {
		setCurrentPage(page);
	};

	return (
		<div className="intakeTable">
			<Table
				columns={[
					{
						name: "CUSTOMER #",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.CustomerNumber,
						formattedField: (row) => {
							return <>{row.data.customerNumber}</>;
						},
					},
					{
						name: "BUSINESS NAME",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.BusinessName,
						formattedField: (row) => {
							return <>{row.data.businessName}</>;
						},
					},
					isAdmin
						? {
								name: "REP CODE",
								isSortable: true,
								sortField: GetCustomerIntakesSortByNames.RepCodeUserFirstName,
								formattedField: (row) => {
									return (
										<>
											{row.data.repCodeUser
												? row.data.repCodeUser.firstName || row.data.repCodeUser.lastName
													? [row.data.repCodeUser.firstName, row.data.repCodeUser.lastName]
															.filter(Boolean)
															.join(" ")
													: row.data.repCodeUser.username
												: ""}
										</>
									);
								},
						  }
						: undefined,
					{
						name: "STATION",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.StationName,
						formattedField: (row) => {
							return <>{row.data.station?.name}</>;
						},
					},
					{
						name: "VERIFIED BY",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.VerifiedByUserId, // @TODO
						formattedField: (row) => {
							if (row.data.verifiedByUser == null) {
								return <></>;
							}

							return (
								<>{row.data.verifiedByUser.firstName + " " + row.data.verifiedByUser.lastName}</>
							);
						},
					},
					{
						name: "TIME",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.Time,
						formattedField: (row) => {
							if (row.data.time == null) {
								return <></>;
							}

							const time = parse(row.data.time, "HH:mm:ss", new Date());
							return <>{format(time, "hh:mm a")}</>;
						},
					},
					{
						name: "DATE",
						isSortable: true,
						sortField: GetCustomerIntakesSortByNames.Date,
						formattedField: (row) => {
							if (row.data.date == null) {
								return <></>;
							}
							const date = parse(row.data.date, "yyyy-MM-dd", new Date());
							return <>{formatTz(date, "MM-dd-yyyy")}</>;
						},
					},
				]}
				rows={customerIntakes.map((data) => {
					return {
						data,
						className: "clickable-row",
					};
				})}
				onRowClick={(row) => {
					navigate(PathName.CUSTOMER_INTAKES(row.data.id + ""));
				}}
				className="settings-global-general-users-table"
				pagination={{
					pages: totalPages,
					currentPage,
					onPageClick: onPageChange,
				}}
				onSort={(sortField, isDesc) => {
					setSortByState((state) => {
						return {
							...state,
							sortBys: pushToSortBys(
								state.sortBys,
								sortField as GetCustomerIntakesSortByNames,
								isDesc,
							),
						};
					});
				}}
				defaultSortField={DEFAULT_SORT_BY_CUSTOMER_INTAKE.name}
				defaultIsSortDesc={DEFAULT_SORT_BY_CUSTOMER_INTAKE.isDesc ?? false}
				notSortedIcon={
					<div className="flex flex-col">
						<FiChevronUp className="-mb-2 h-4 w-4 text-slate-400" />
						<FiChevronDown className="h-4 w-4 text-slate-400" />
					</div>
				}
				ascIcon={
					<div className="flex flex-col items-center">
						<FiChevronUp className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
				descIcon={
					<div className="flex flex-col items-center">
						<FiChevronDown className="h-4 w-4 text-fsOrangeDark" />
					</div>
				}
			/>
		</div>
	);
};
