import { atom } from "recoil";

export enum HeaderStates {
	Default,
	NavigationMenu,
	SearchMenu,
	UserMenu,
}

export const isNavigationCollapsedAtom = atom<boolean>({
	key: "isNavigationCollapsed",
	default: false,
});

// export const isMobileNavigationOpenAtom = atom<boolean>({
// 	key: "isMobileNavigationOpen",
// 	default: false,
// });

// export const isMobileSearchOpenAtom = atom<boolean>({
// 	key: "isMobileSearchOpen",
// 	default: false,
// });

export const headerStateAtom = atom<HeaderStates>({
	key: "headerState",
	default: HeaderStates.Default,
});
