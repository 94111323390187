import React from "react";
import "./customerIntakeLogForm.scss";
import {
	useCustomerIntakeLogFormService,
	useGetCustomerIntakeLogFormData,
	usePartialSetCustomerIntakeLog,
} from "./customerIntakeLogForm.service";
import { CustomerIntakeLogSaveButton } from "./customerIntakeLogSaveButton/CustomerIntakeLogSaveButton";

export const CustomerIntakeLogForm: React.FC = () => {
	useCustomerIntakeLogFormService();

	const { customerIntakeLog } = useGetCustomerIntakeLogFormData();
	const { partialSetCustomerIntakeLog } = usePartialSetCustomerIntakeLog();

	return (
		<div className="customerIntakeLogForm">
			<label htmlFor="announcements">Comment:</label>
			<textarea
				value={customerIntakeLog.message ?? ""}
				onChange={(e) => {
					partialSetCustomerIntakeLog({
						message: e.target.value,
					});
				}}
			/>
			<CustomerIntakeLogSaveButton />
		</div>
	);
};
